// See: https://github.com/iamdustan/smoothscroll
// We override the smoothscroll for _all_ browsers explicitly because chrome's implementation of smooth scroll
// is too fast/jumpy and feels a lot worse than the polyfilled version.
// In the future, we might want to build our own smooth scrolling & have even more control, but this is a good stand-in.

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.__forceSmoothScrollPolyfill__ = true;

// eslint-disable-next-line import/first
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();
