import { useCallback, useMemo } from 'react';

import type { FilteredView } from '../../types';
import { todaysDailyDigestId } from '../../utils/filteredViews';
// eslint-disable-next-line import/no-cycle
import { globalState } from '../models';
import useGlobalStateWithFallback from '../utils/useGlobalStateWithFallback';

export function useTodaysDailyDigestFilteredView() {
  return useFilteredView(todaysDailyDigestId);
}

export function useFilteredView(id: FilteredView['id']) {
  return globalState(useCallback((state) => state.persistent.filteredViews[id], [id]));
}

export function useFilteredViews() {
  return useGlobalStateWithFallback(
    {},
    useCallback((state) => state.persistent.filteredViews, []),
  );
}

export function useFilteredViewsArray() {
  const views = useFilteredViews();
  return useMemo(() => Object.values(views || {}), [views]);
}
