import type { PlaybackRate, TTSLanguage } from '../../types/tts';
import {
  TextToSpeechDisplayNameByVoice,
  TextToSpeechGender,
  TextToSpeechGenderByVoice,
  TextToSpeechVoice,
} from '../../types/tts';

const TextToSpeechDisplayNameMale = 'Finley';
const TextToSpeechDisplayNameFemale = 'Rey';
export function getTextToSpeechDisplayName(voice: TextToSpeechVoice): string {
  const voiceOverride = TextToSpeechDisplayNameByVoice[voice];
  if (voiceOverride !== undefined) {
    return voiceOverride;
  }
  const foundVoice = Object.keys(TextToSpeechVoice).find(
    (voiceValue) => TextToSpeechVoice[voiceValue] === voice,
  );
  if (foundVoice) {
    return foundVoice;
  }
  const isMale = TextToSpeechGenderByVoice[voice] === 'male';
  return isMale ? TextToSpeechDisplayNameMale : TextToSpeechDisplayNameFemale;
}

export const textToSpeechDefaultLanguage: TTSLanguage = 'en';
export const textToSpeechDefaultGender: TextToSpeechGender = 'female';
export const textToSpeechDefaultPlaybackRate: PlaybackRate = 1;
export const textToSpeechDefaultVolume = 1;
