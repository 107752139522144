// Safari <= 14.1 doesn't support dates with format YYYY-MM-DD, it needs to be YYYY/MM/DD.
// https://www.linkedin.com/pulse/fix-invalid-date-safari-ie-hatem-ahmad/

export default (date: string | number) => {
  let dateToReturn = new Date(date);

  // If the date is invalid, try to replace '-' with '/'.
  if (isNaN(dateToReturn.getTime()) && typeof date === 'string') {
    dateToReturn = new Date(date.replace(/-/g, '/'));
  }

  return dateToReturn;
};
