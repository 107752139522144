// WARNING: This function closely maps to a similar function find_all_elements_from_tag_list in reader/utils.py on the backend.
// Changing anything here necessitates a change in the python function too.
export function findAllElementsFromTagList(
  parentNode: ChildNode,
  result: ChildNode[],
  tags: Set<string>,
): void {
  for (const childNode of parentNode.childNodes) {
    if (tags.has(childNode.nodeName)) {
      if (childNode.childNodes.length > 0 && childNode.textContent !== '') {
        result.push(childNode);
      }
    } else {
      findAllElementsFromTagList(childNode, result, tags);
    }
  }
}
