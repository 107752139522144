// Get the rect without causing a layout reflow
export default async (element: Element): Promise<DOMRect> => {
  return new Promise((resolve) => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      observer.disconnect();
      resolve(entries[0].boundingClientRect);
    };
    const observer = new IntersectionObserver(callback, {
      threshold: 0,
    });
    observer.observe(element);
  });
};
