import uniq from 'lodash/uniq';

export const CommonNonSyncingProperties = [
  '_presentKeys',
  'contentParsingStatus',
  'contentRequestLoadingStatus',
  'lastSeenStatusUpdateAt',
  'rxdbOnly',
  'transientData',
];

export const NonSyncingParentDocumentProperties = [
  ...CommonNonSyncingProperties,
  'content',
  'links',
  'searchPreviewText',
  'tts',
  'ttsParsingStatus',
] as const;

export const NonSyncingChildDocumentProperties = [...CommonNonSyncingProperties, 'selected'] as const;

export const AllNonSyncingDocumentProperties = uniq([
  ...NonSyncingParentDocumentProperties,
  ...NonSyncingChildDocumentProperties,
]);
