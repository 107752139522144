import { MaybePromise } from '../utils/typescriptUtils';

export type KeyboardApiName = 'mediaSession' | 'programmatic' | 'react-hotkeys';

export type KeyboardShortcutEvent = {
  apiName: KeyboardApiName;
  nativeKeyboardEvent?: KeyboardEvent;
  preventDefault(): void;
  stopImmediatePropagation(): void;
  stopPropagation(): void;
  target: Element;
};

export type KeyboardShortcut = {
  callback: (event: KeyboardShortcutEvent) => MaybePromise<void>;
  customId?: string;
  description: string;
  keys: string;
  shouldShowInHelp: boolean;
};

export enum KeyboardLayout {
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums
  QwertyUS = 'QwertyUS',
  German = 'German',
}

export const KeyboardLayoutDisplayName = {
  [KeyboardLayout.QwertyUS]: 'U.S. QWERTY',
  [KeyboardLayout.German]: 'German',
};

export type ShortcutData = {
  id: ShortcutId;
  name: string;
  keys: string[];
};

export enum ShortcutId {
  GoToHome = 'GoToHome',
  GoToLibrary = 'GoToLibrary',
  Feed = 'Feed',
  Archive = 'Archive',
  Later = 'Later',
  New = 'New',
  Shortlist = 'Shortlist',
  Delete = 'Delete',
  Bump = 'Bump',
  Share = 'Share',
  ShareReaderLink = 'ShareReaderLink',
  HidePanels = 'HidePanels',
  HideLeftPanel = 'HideLeftPanel',
  HideRightPanel = 'HideRightPanel',
  Search = 'Search',
  OpenShortcutsMenu = 'OpenShortcutsMenu',
  ShowDocMetadata = 'ShowDocMetadata',
  GoToNextDocument = 'GoToNextDocument',
  GoToPreviousDocument = 'GoToPreviousDocument',
  Tags = 'Tags',
  DocumentReaderTag = 'DocumentReaderTag',
  GoToView = 'GoToView',
  ToggleDocAsOpened = 'ToggleDocAsOpened',
  ToggleZenMode = 'ToggleZenMode',
  ToggleDarkMode = 'ToggleDarkMode',
  FullScreen = 'FullScreen',
  CycleTypeFace = 'CycleTypeFace',
  IncreaseFontSize = 'IncreaseFontSize',
  DecreaseFontSize = 'DecreaseFontSize',
  WidenLineLength = 'WidenLineLength',
  NarrowLineLength = 'NarrowLineLength',
  IncreaseLineSpacing = 'IncreaseLineSpacing',
  DecreaseLineSpacing = 'DecreaseLineSpacing',
  PdfZoomIn = 'PdfZoomIn',
  PdfZoomOut = 'PdfZoomOut',
  PdfZoomReset = 'PdfZoomReset',
  PdfFind = 'PdfFind',
  PdfJumpToPage = 'PdfJumpToPage',
  PdfRotateLeft = 'PdfRotateLeft',
  PdfSnapshot = 'PdfSnapshot',
  ManageFeedSubscriptions = 'ManageFeedSubscriptions',
  OpenFiltersCmdPanel = 'OpenFiltersCmdPanel',
  OpenFilteredViews = 'OpenFilteredViews',
  OpenSplitBySubMenu = 'OpenSplitBySubMenu',
  ToggleRssOrEmailSubscription = 'ToggleRssOrEmailSubscription',
  OpenBulkActionsSubMenu = 'OpenBulkActionsSubMenu',
  OpenOriginalDoc = 'OpenOriginalDoc',
  ToggleNotebookView = 'ToggleNotebookView',
  ToggleDocMoreActions = 'ToggleDocMoreActions',
  Undo = 'Undo',
  Redo = 'Redo',
  Esc = 'Esc',
  AddToFavorites = 'AddToFavorites',
  Down = 'Down',
  Up = 'Up',
  Highlight = 'Highlight',
  Note = 'Note',
  Ghostreader = 'Ghostreader',
  DocGhostreader = 'DocGhostreader',
  Enter = 'Enter',
  OpenCmdPalette = 'OpenCmdPalette',
  MoveUpFocusIndicator = 'MoveUpFocusIndicator',
  MoveDownFocusIndicator = 'MoveDownFocusIndicator',
  ScrollToTop = 'ScrollToTop',
  ScrollToBottom = 'ScrollToBottom',
  PageUp = 'PageUp',
  PageDown = 'PageDown',
  Tab = 'Tab',
  PreviousTab = 'PreviousTab',
  PreviousMetadataItem = 'PreviousMetadataItem',
  NextMetadataItem = 'NextMetadataItem',
  EnableOrViewPublicLink = 'EnableOrViewPublicLink',
  ResetReadingProgress = 'ResetReadingProgress',
  ToggleAutoHighlighting = 'ToggleAutoHighlighting',
  CopyText = 'CopyText',
  OpenSaveDocFromUrlPalette = 'OpenSaveDocFromUrlPalette',
  UploadFile = 'UploadFile',
  CmdOrCtrlAndEnter = 'CmdOrCtrlAndEnter',
  SaveLinkToReader = 'SaveLinkToReader',
  AddDocNote = 'AddDocNote',
  MarkAllFeedAsSeen = 'MarkAllFeedAsSeen',
  Space = 'Space',
  Shift = 'Shift',
  Option = 'Option',
  Alt = 'Alt',
  PlayOrPause = 'PlayOrPause',
  Stop = 'Stop',
  ToggleVideoControls = 'ToggleVideoControls',
  ToggleYtAutoScroll = 'ToggleYtAutoScroll',
  SlowDownPlayBack = 'SlowDownPlayBack',
  SpeedUpPlayback = 'SpeedUpPlayback',
  SkipForward = 'SkipForward',
  SkipBackwards = 'SkipBackwards',
  DecreaseVolume = 'DecreaseVolume',
  IncreaseVolume = 'IncreaseVolume',
  MediaPlay = 'MediaPlay',
  MediaPause = 'MediaPause',
  MediaStop = 'MediaStop',
  MediaSeekBackward = 'MediaSeekBackward',
  MediaSeekForward = 'MediaSeekForward',
  MediaPreviousTrack = 'MediaPreviousTrack',
  MediaNextTrack = 'MediaNextTrack',
  ExportCopyToClipboard = 'ExportCopyToClipboard',
  ExportDownloadAnnotations = 'ExportDownloadAnnotations',
  Right = 'Right',
  Left = 'Left',
  NavigateViews = 'NavigateViews',
  NextSplit = 'NextSplit',
  PreviousSplit = 'PreviousSplit',
  Exit = 'Exit',
  DeleteDocument = 'DeleteDocument',
  CopyDocumentNote = 'CopyDocumentNote',
  DeleteDocumentNote = 'DeleteDocumentNote',
  CopyNotebookHighlightText = 'CopyNotebookHighlightText',
  CopyNotebookHighlightNote = 'CopyNotebookHighlightNote',
  DeleteHighlightInNotebook = 'DeleteHighlightInNotebook',
  CmdOrCtrlAndTab = 'CmdOrCtrlAndTab',
  CmdOrCtrl = 'CmdOrCtrl',
  GoToPinnedView1 = 'GoToPinnedView1',
  GoToPinnedView2 = 'GoToPinnedView2',
  GoToPinnedView3 = 'GoToPinnedView3',
  GoToPinnedView4 = 'GoToPinnedView4',
  GoToPinnedView5 = 'GoToPinnedView5',
  GoToPinnedView6 = 'GoToPinnedView6',
  SwitchRightSidebarTab = 'SwitchDocumentSidebarTab',
  SwitchRightSidebarTabBackward = 'SwitchDocumentSidebarTabBackward',
  OpenDocumentNoteActionsDropdown = 'OpenDocumentNoteActionsDropdown',
  OpenNotebookHighlightActionsDropdown = 'OpenNotebookHighlightActionsDropdown',
  EditHighlightTagsInNotebook = 'EditHighlightTagsInNotebook',
  EditHighlightNoteInNotebook = 'EditHighlightNoteInNotebook',
  ViewNotebookHighlightInDocument = 'ViewNotebookHighlightInDocument',
  ViewHighlightInNotebookView = 'ViewHighlightInNotebookView',
  PrintDocument = 'PrintDocument',
  DownloadDocument = 'DownloadDocument',
  ChangeSidebarContentType = 'ChangeSidebarContentType',
  Wildcard = 'Wildcard',
  ExpandOrCollapseAllNavItemsInSidebar = 'ExpandOrCollapseAllNavItemsInSidebar',
}
