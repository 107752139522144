import React from 'react';

import Icon from './Icon';

export default function SuccessIcon({ text = 'success' }: { text?: string } = {}): JSX.Element {
  return (
    <Icon text={text}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <g>
          <path
            fill="#fff"
            d="M16,1A15,15,0,1,0,31,16,15.01828,15.01828,0,0,0,16,1Zm7.71,10.74-9.99,9.98a.91648.91648,0,0,1-.54.28,1.10651,1.10651,0,0,1-.19.02.441.441,0,0,1-.16-.02.91678.91678,0,0,1-.54-.28l-4-3.99a.9959.9959,0,0,1,0-1.41,1.008,1.008,0,0,1,1.42,0l3.3,3.3,9.28-9.29a1.00808,1.00808,0,0,1,1.42,0A.996.996,0,0,1,23.71,11.74Z"
          />
        </g>
      </svg>
    </Icon>
  );
}
