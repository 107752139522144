import keyValuePairs from '../../database/internals/keyValuePairs';
import logger from '../../database/internals/logger';
import trimDocumentsSummary from '../../database/internals/trimDocumentsSummary';
import type { RxDBInstance } from '../../types/database';
import stringifySafe from '../../utils/stringifySafe';
import { Cache as LegacyCache } from '../cache.platform';

type Entry = ReturnType<typeof Object.entries>[0];

export default async function migrateStateSyncingFromLocalForage({
  rxDBInstance,
}: {
  rxDBInstance: RxDBInstance;
}) {
  const name = 'stateSyncing';
  const legacyCache = LegacyCache.createInstance({ name });
  const keys = await legacyCache.keys();

  if (!keys.length) {
    logger.debug('Skipping migration from localForage / legacy cache because no keys were found');
    return;
  }

  logger.debug('Starting migration from localForage / legacy cache', { keys, name });

  const data = await legacyCache.getItems(keys);
  const keyValuePairEntries: Entry[] = [];
  const updateEntries: Entry[] = [];

  for (const entry of Object.entries(data)) {
    if (entry[0].startsWith('update:')) {
      updateEntries.push(entry);
      continue;
    }
    if (entry[0] === 'persistentStateBlob') {
      const value = entry[1];
      trimDocumentsSummary(value.documents);
      keyValuePairEntries.push([entry[0], value]);
      continue;
    }
    keyValuePairEntries.push(entry);
  }

  await Promise.all([
    rxDBInstance.state_syncing_key_value_pairs.bulkUpsert(
      keyValuePairEntries.map((entry) => keyValuePairs.formatForWrite(...entry)),
    ),
    rxDBInstance.state_syncing_updates.bulkUpsert(
      updateEntries.map(([oldKey, value]) => {
        return {
          id: oldKey.replace(/^update:/, '').replace(/\(server=(false|true)\)$/, ''),
          sentToServer: oldKey.endsWith('(server=false)') ? 0 : 1,
          value: stringifySafe(value),
        };
      }),
    ),
  ]);

  await legacyCache.clear();
  logger.info('Finished migration from localForage / legacy cache', { name });
}
