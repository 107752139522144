import forOwn from 'lodash/forOwn';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isNaN from 'lodash/isNaN';
import isObject from 'lodash/isObject';

/*
  Example:

  ```
  const results = generateLeafNodePaths({
    a: 'hello',
    b: {
      'c.d': 'world',
      e: 'blah',
    },
    c: [{ d: 'world' }],
  });
  console.log(results);
  ```

  Output:
  ```
  [
    ['a'],
    ['b', 'c.d'],
    ['b', 'e'],
    ['c', 0, 'd'],
  ]
  ```

  Each child array is compatible with lodash.get/set
*/
export default function generateLeafNodePaths(
  input: object | object[],
  pathSegmentsSoFar: (string | number)[] = [],
): (string | number)[][] {
  const results: (string | number)[][] = [];

  forOwn(input, (value, key) => {
    const pathSegmentsIncludingCurrent = [...pathSegmentsSoFar, key];

    if (isArray(value)) {
      for (const [stringIndex, item] of Object.entries(value)) {
        const integerIndex = parseInt(stringIndex, 10);
        const index = isNaN(integerIndex) ? stringIndex : integerIndex;

        if (isObject(item)) {
          // Recurse into array of objects
          results.push(...generateLeafNodePaths(item, [...pathSegmentsIncludingCurrent, index]));
        } else {
          // Array of primitives; treat as a leaf node
          results.push([...pathSegmentsIncludingCurrent, index]);
        }
      }
    } else if (isObject(value) && !isFunction(value)) {
      forOwn(input, (value) => {
        // Recurse into object properties
        results.push(...generateLeafNodePaths(value, pathSegmentsIncludingCurrent));
      });
    } else {
      // It's a leaf node
      results.push(pathSegmentsIncludingCurrent);
    }
  });
  return results;
}
