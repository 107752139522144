export default function closestWith<T extends Node>(
  node: Node | null | undefined,
  matcher: (currentNode: Node) => boolean | undefined,
  containerNode: Node = document.documentElement,
): T | undefined {
  if (!node) {
    return;
  }

  if (matcher(node)) {
    return node as T;
  }

  if (node === containerNode || !node.parentNode) {
    return;
  }

  return closestWith(node.parentNode, matcher);
}
