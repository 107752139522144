import zip from 'lodash/zip';
import type { QueryExecResult } from 'sql.js';
import { ulid } from 'ulid';

import { DeferredPromise } from '../../utils/DeferredPromise';
import { isTest } from '../../utils/environment';
import exceptionHandler from '../../utils/exceptionHandler.platform';
import type { GenericSqliteRow, ISqliteDatabase, SqliteValue } from './sqliteDatabase';
import { loadSqliteFileFromBrowserCache, saveSqliteFileToBrowserCache } from './util';

type SqlJSBaseResponse = {
  id: string;
};

type SqlJSOpenRequest = {
  action: 'open';
  buffer: ArrayBuffer;
};

type SqlJSOpenResponse = SqlJSBaseResponse;

type SqlJSExecRequest = {
  action: 'exec';
  sql: string;
  params?: SqliteValue[];
};

type SqlJSExecResponse = SqlJSBaseResponse & {
  results: QueryExecResult[] | undefined;
};

type SqlJSExportRequest = {
  action: 'export';
};

type SqlJSExportResponse = SqlJSBaseResponse & {
  buffer: Uint8Array;
};

type SqlJSCloseRequest = {
  action: 'close';
};

type SqlJSCloseResponse = SqlJSBaseResponse;

type SqlJSRequest = SqlJSOpenRequest | SqlJSExecRequest | SqlJSExportRequest | SqlJSCloseRequest;
type SqlJSResponse = SqlJSOpenResponse | SqlJSExecResponse | SqlJSExportResponse | SqlJSCloseResponse;
type SqlJSErrorResponse = { id: string; error: string };

const WORKER_SCRIPT_URL = '/worker.sql-wasm.js';
const WORKER_SCRIPT_URL_IN_TEST = '/web/public/worker.sql-wasm.js';

export class BrowserSqliteDatabase implements ISqliteDatabase {
  private _worker: Worker | undefined;
  private _inflightRequest: {
    [id: string]: {
      promise: DeferredPromise<SqlJSResponse>;
      request: SqlJSRequest;
    };
  } = {};

  constructor(readonly filename: string) {}

  private get worker(): Worker {
    if (!this._worker) {
      throw new Error('sqlite worker uninitialized');
    }
    return this._worker;
  }

  async init(): Promise<void> {
    let scriptUrl = WORKER_SCRIPT_URL;
    if (isTest) {
      // eslint-disable-next-line no-restricted-globals
      const workerScriptUrlTest = await fetch(WORKER_SCRIPT_URL_IN_TEST, { method: 'head' });
      if (workerScriptUrlTest.ok) {
        // only in cypress integration tests do we need to use the alternative test script URL
        scriptUrl = WORKER_SCRIPT_URL_IN_TEST;
      }
    }
    this._worker = new Worker(scriptUrl, {
      name: `sqliteDatabase:${this.filename}`,
    });
    this._worker.onerror = (error) => {
      exceptionHandler.captureException('SQL WASM: worker onerror', {
        extra: {
          error,
        },
      });
    };
    this._worker.onmessage = this._handleWorkerResponse.bind(this);

    const dbDump = await loadSqliteFileFromBrowserCache(this.filename);
    if (dbDump) {
      await this._callWorkerMethod({
        action: 'open',
        buffer: dbDump,
      });
    }
  }

  async execute(sql: string, params?: SqliteValue[]): Promise<void> {
    await this._callWorkerMethod({
      action: 'exec',
      sql,
      params,
    });
  }

  async select<Row extends GenericSqliteRow = GenericSqliteRow>(
    sql: string,
    params?: SqliteValue[],
  ): Promise<Row[]> {
    const response = await this._callWorkerMethod<SqlJSExecResponse>({
      action: 'exec',
      sql,
      params,
    });
    const { results } = response;
    if (!results) {
      exceptionHandler.captureException('SQL WASM: Unknown error in select', {
        extra: {
          sql,
          params,
          response,
        },
      });
      return [];
    }
    if (results.length === 0) {
      return [];
    }
    const result = results[0];

    return result.values.map((rawRow) => Object.fromEntries(zip(result.columns, rawRow)));
  }

  async save(): Promise<void> {
    if (!this.filename) {
      throw new Error('DB not initialized');
    }
    const { buffer } = await this._callWorkerMethod<SqlJSExportResponse>({
      action: 'export',
    });
    await saveSqliteFileToBrowserCache(this.filename, buffer);
  }

  async close(): Promise<void> {
    // for some reason, SqlJs doesn't respond to the close request, so we can't await.
    this._callWorkerMethod<SqlJSCloseResponse>({
      action: 'close',
    });
    this.worker.terminate();
  }

  async delete(): Promise<void> {
    const cache = await caches.open(this.filename);
    await cache.delete('/data.json');
    // clear in-memory sqlite DB as well.
    await this._callWorkerMethod({
      action: 'open',
      buffer: new Uint8Array(),
    });
  }

  private async _callWorkerMethod<Response extends SqlJSResponse>(
    request: SqlJSRequest,
  ): Promise<Response> {
    const id = ulid();
    this._inflightRequest[id] = {
      request,
      promise: new DeferredPromise(),
    };
    this.worker.postMessage({ id, ...request });
    return (await this._inflightRequest[id].promise) as Response;
  }

  private _handleWorkerResponse(message: MessageEvent<SqlJSResponse | SqlJSErrorResponse>) {
    const data = message.data;
    const id = data.id;
    const inflightRequest = this._inflightRequest[id];
    if (!inflightRequest) {
      exceptionHandler.captureException('SQL WASM: no in flight request with ID', {
        extra: {
          data,
          id,
        },
      });
      return;
    }
    const error: string | undefined = (data as SqlJSErrorResponse).error;
    if (error) {
      exceptionHandler.captureException(`SQL WASM response: ${error}`, {
        extra: {
          request: inflightRequest.request,
          data,
        },
      });
      inflightRequest.promise.reject(new Error(error));
    } else {
      inflightRequest.promise.resolve(data);
    }
    delete this._inflightRequest[id];
  }
}
