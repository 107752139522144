import getServerBaseUrl from './utils/getServerBaseUrl.platform';

// This is here because mobile components can't import components which import CSS modules
export const contentFocusIndicatorFocusedTargetClass = 'js_contentFocusIndicator_focusedTarget';

export const ONBOARDING_DOC_URL = 'https://blog.readwise.io/p/bf87944f-b0fe-4f08-a461-f75ab8aded6a/';
export const FAQ_DOC_URL = 'https://blog.readwise.io/p/f8c0f71c-fe5f-4025-af57-f9f65c53fed7/';
export const ACCOUNT_SETTINGS_URL = `${getServerBaseUrl()}/preferences/account`;
export const ACCOUNT_PREFERENCES_URL = `${getServerBaseUrl()}/configure`;
export const PRICING_PAGE_URL = `${getServerBaseUrl()}/pricing/reader`;
export const ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=com.readermobile';
export const IOS_APP_URL = 'https://apps.apple.com/us/app/readwise-reader/id1567599761';
export const DEFAULT_MANGO_QUERY_LIMIT = 50; // Used across different queries with the objective of deduping results in query cache.
export const DEFAULT_LIMIT_BUFFER = 10;
