import { globalState, updateState } from '../../models';

export async function setDocumentSummaryController(
  controller: AbortController,
  userInteraction: string,
) {
  await updateState(
    (state) => {
      state.documentSummaryController = controller;
    },
    {
      eventName: 'document-summary-controller-set',
      userInteraction,
    },
  );
}

export async function resetDocumentSummaryGeneration(userInteraction: string) {
  if (!globalState.getState().isDocumentSummaryGenerating) {
    return;
  }
  await updateState(
    (state) => {
      state.isDocumentSummaryGenerating = false;
    },
    {
      eventName: 'document-summary-generation-reset',
      userInteraction,
    },
  );
}

export async function startDocumentSummaryGeneration(userInteraction: string) {
  await updateState(
    (state) => {
      state.isDocumentSummaryGenerating = true;
    },
    {
      eventName: 'document-summary-generation-started',
      userInteraction,
    },
  );
}

export async function stopDocumentSummaryGeneration(userInteraction: string) {
  await updateState(
    (state) => {
      // make sure to not override `undefined` if state was reset
      if (state.isDocumentSummaryGenerating) {
        state.isDocumentSummaryGenerating = false;
      }
    },
    {
      eventName: 'document-summary-generation-stopped',
      userInteraction,
    },
  );
}
