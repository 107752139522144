// Try not to use this. Use state.persistent.documentLocations instead
import { DocumentLocation } from '../types';

export const InboxDocumentLocation = Object.entries(DocumentLocation).reduce(
  (
    acc: {
      [key: string]: DocumentLocation;
    },
    [key, value],
  ) => {
    if (key === 'Feed') {
      return acc;
    }

    return {
      ...acc,
      [key]: value as DocumentLocation,
    };
  },
  {},
);
