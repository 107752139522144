import throttle from 'lodash/throttle';

import { type RSSSuggestion } from '../../../types';
// eslint-disable-next-line import/no-cycle
import { updateState } from '../../models';
import background from '../../portalGates/toBackground/singleProcess';

const domainsAlreadyFetched = new Set<string>();
export const fetchRelatedRSSUnthrottled = async (domains: string[]): Promise<void> => {
  // We don't try to fetch a domain more than once a session. This means technically that newly
  // discovered feeds (very rare) won't appear until the user refreshes or kills the app.
  // This feels like an acceptable tradeoff for the efficiency and simplicity we get.
  const newDomains = [];
  for (const domain of domains) {
    if (!domainsAlreadyFetched.has(domain)) {
      newDomains.push(domain);
      domainsAlreadyFetched.add(domain);
    }
  }
  if (!newDomains.length) {
    return;
  }
  try {
    await background.loadRelatedRSSFeedsForDomains(newDomains);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (err.message !== 'Not allowed yet. Will fetch once allowed.') {
      throw err;
    }
  }
};
export const fetchRelatedRSS = throttle(
  fetchRelatedRSSUnthrottled,
  250,
) as typeof fetchRelatedRSSUnthrottled;

export const onRSSFeedsLoaded = async (
  foundItems: RSSSuggestion[],
  missingDomains: string[],
): Promise<void> => {
  await updateState(
    (state) => {
      if (!state.persistent) {
        // This should never happen, as there will only ever be updates from the background after
        //  pollLatestState is called, which is called after initPersistentState
        throw new Error('onBackgroundStateUpdate called when state has not yet been initialized');
      }
      for (const item of foundItems) {
        state.possibleRssFeeds[item.domain] = item;
      }
      for (const missingDomain of missingDomains) {
        // Set the missing domains as null so that we don't look them up again this session.
        state.possibleRssFeeds[missingDomain] = null;
      }
    },
    { eventName: 'possible-rss-feeds-saved', userInteraction: null },
  );
};

export const setAreFeedsDocumentCountsIndexing = async (isIndexing: boolean): Promise<void> => {
  await updateState(
    (state) => {
      state.areFeedsDocumentCountsIndexing = isIndexing;
    },
    { userInteraction: 'click', isUndoable: false, eventName: 'set-feeds-count-indexing' },
  );
};
