import type { DocumentWithSummary, IdToDocumentMap } from '../../types';

// Modifies input
export default function trimDocumentsSummary(docs?: IdToDocumentMap | null) {
  if (!docs) {
    return;
  }

  for (const docId of Object.keys(docs)) {
    const currentSummary = (docs[docId] as DocumentWithSummary).summary;
    if (currentSummary) {
      (docs[docId] as DocumentWithSummary).summary = currentSummary.substring(0, 1024);
    }
  }
}
