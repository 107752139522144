/*
  This contains functions which update the state argument they're given.
  They are not actions which call updateState. Actions would call these functions.
  Convention: the exposed functions have `InState` / `FromState` / `ToState` in the name.
*/

import { ulid } from 'ulid';

import { removeSubQueryFromQuery } from '../filters-compiler/removeSubQueryFromQuery';
import {
  AnyDocument,
  DocumentLocation,
  EditableKeys,
  FilteredView,
  FilteredViewWithOptionalId,
  FirstClassDocument,
  FullZustandState,
  ReadingPosition,
  RssItem,
  SortRule,
  SplitByKey,
} from '../types';
import {
  isDocumentWithAuthor,
  isDocumentWithLanguage,
  isDocumentWithPublishedDate,
  isDocumentWithSummary,
  isDocumentWithTitle,
} from '../typeValidators';
import nowTimestamp from '../utils/dates/nowTimestamp';
import {
  getFeedItemQueryFromFeedId,
  getFeedTrueAndFeedItemQueryFromFeedId,
  isRssFolder,
  isRssPinnedItem,
} from '../utils/feed';
import { ArrayWithAtLeastOneItem } from '../utils/typescriptUtils';
// eslint-disable-next-line import/no-cycle
import ttsController from './actions/ttsController.platform';
// eslint-disable-next-line import/no-cycle
import { DEFAULT_DOCUMENT_SORT_RULES, globalState } from './models';
import { setCurrentlyReading } from './stateUpdaters/persistentStateUpdaters/other';

export const addDefaultPropertiesToFilteredView = ({
  view,
  pathname = '',
}: { view: Omit<FilteredViewWithOptionalId, 'created' | 'sortRules'>; pathname?: string }) => {
  const id = view.id ?? ulid().toLowerCase();

  let splitBy: SplitByKey | undefined;

  if (pathname.includes('/split/')) {
    splitBy = pathname.split('/split/')[1].split('/')[0] as SplitByKey;
  }

  const currentSortRule = DEFAULT_DOCUMENT_SORT_RULES.filterView[0];

  const viewWithDefaults = {
    ...view,
    id,
    created: nowTimestamp(),
    order: view.order ?? Infinity,
    sortRules: [
      {
        id: ulid().toLowerCase(),
        key: currentSortRule.key,
        order: currentSortRule.order,
      },
    ] as ArrayWithAtLeastOneItem<SortRule>,
  };

  if (splitBy && !view.splitBy) {
    viewWithDefaults.splitBy = splitBy;
  }

  return viewWithDefaults;
};

export const saveFilteredViewToState = (
  state: FullZustandState,
  filter: Omit<FilteredViewWithOptionalId, 'created' | 'sortRules'>,
  pathname = '',
): void => {
  const viewToSave = addDefaultPropertiesToFilteredView({ view: filter, pathname });
  state.persistent.filteredViews[viewToSave.id] = viewToSave;
};

export const removeFilteredViewFromState = (
  state: FullZustandState,
  viewId: FilteredView['id'],
): void => {
  if (state.persistent.home?.views) {
    const indexInHomeViews = state.persistent.home.views.indexOf(viewId);
    if (indexInHomeViews >= 0) {
      state.persistent.home.views.splice(indexInHomeViews, 1);
    }
  }

  // Make sure to remove the associated rss folder if it exists
  const filterViewRssFolderId = state.persistent.filteredViews[viewId].rssFolderId;
  if (filterViewRssFolderId) {
    state.persistent.rssFoldersAndItems = state.persistent.rssFoldersAndItems.filter(
      (rssFolderOrItem) => rssFolderOrItem.id !== filterViewRssFolderId,
    );
  }

  delete state.persistent.filteredViews[viewId];
};

export const updateFilteredViewInState = ({
  shouldErrorIfItDoesntExist = true,
  state,
  updates,
  viewId,
}: {
  shouldErrorIfItDoesntExist?: boolean;
  state: FullZustandState;
  updates: Partial<FilteredView>;
  viewId: FilteredView['id'];
}): void => {
  if (!state.persistent.filteredViews[viewId]) {
    if (shouldErrorIfItDoesntExist) {
      throw new Error('No view with that ID in state');
    }
    return;
  }

  state.persistent.filteredViews[viewId] = Object.assign(
    state.persistent.filteredViews[viewId],
    updates,
  );
};

export const updateDocumentLocationOfDoc = (
  document: FirstClassDocument,
  newDocumentLocation: DocumentLocation,
  shouldUpdateLastStatusUpdate = true,
): void => {
  const now = nowTimestamp();

  if (
    document.triage_status === DocumentLocation.Feed &&
    newDocumentLocation !== DocumentLocation.Feed
  ) {
    document.saved_from_feed_at = now;
  }

  document.triage_status = newDocumentLocation;
  if (shouldUpdateLastStatusUpdate) {
    document.last_status_update = now;
  }

  // Stop playing tts on that doc
  if (
    newDocumentLocation === DocumentLocation.Archive &&
    globalState.getState().tts?.playingDocId === document.id
  ) {
    setTimeout(() => {
      ttsController.stop();
      setCurrentlyReading(null, { userInteraction: null });
    }, 50);
  }
};

export const updateEmailOriginalViewStatus = (
  document: FirstClassDocument,
  originalEmailViewStatus: boolean | undefined,
): void => {
  if (document.source_specific_data?.email) {
    document.source_specific_data.email.originalEmailView = originalEmailViewStatus;
  }
};

export const resetKeyboardShortcutsFromState = (state: FullZustandState): void => {
  if (state.persistent.customKeyboardShortcuts) {
    state.persistent.customKeyboardShortcuts = {};
  }

  if (state.persistent.keyboardShortcutsBlackList) {
    state.persistent.keyboardShortcutsBlackList = [];
  }
};

export const updateFeedTitleFromState = ({
  state,
  feedId,
  title,
}: { state: FullZustandState; feedId: string; title: string }): void => {
  if (!state.persistent.rssFeeds) {
    throw new Error('Feeds do not exist');
  }

  const feed = state.persistent.rssFeeds[feedId];

  if (!feed) {
    throw new Error('Feed does not exist');
  }

  feed.name = title;
};

export const updateFeedDescriptionFromState = ({
  state,
  feedId,
  description,
}: { state: FullZustandState; feedId: string; description: string }): void => {
  if (!state.persistent.rssFeeds) {
    throw new Error('Feeds do not exist');
  }

  const feed = state.persistent.rssFeeds[feedId];

  if (!feed) {
    throw new Error('Feed does not exist');
  }

  feed.description = description;
};

export const overrideTitleInDocData = (doc: AnyDocument, title: EditableKeys['title']): void => {
  if (!isDocumentWithTitle(doc)) {
    throw new Error('Document is not a DocumentWithTitle');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.title = title;
};

export const overrideGeneratedSummaryInDocData = (
  doc: AnyDocument,
  generatedSummary: EditableKeys['generated_summary'],
): void => {
  if (!isDocumentWithSummary(doc)) {
    throw new Error('Document is not a DocumentWithSummary');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  if (generatedSummary === undefined) {
    delete doc.overrides.generated_summary;
  } else {
    doc.overrides.generated_summary = generatedSummary;
  }
};

export const overrideSummaryInDocData = (doc: AnyDocument, summary: EditableKeys['summary']): void => {
  if (!isDocumentWithSummary(doc)) {
    throw new Error('Document is not a DocumentWithSummary');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.summary = summary;
};

export const overrideCategoryInDocData = (
  doc: AnyDocument,
  category: EditableKeys['category'],
): void => {
  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.category = category;
};

export const overrideLanguageInDocData = (
  doc: AnyDocument,
  language: EditableKeys['language'],
): void => {
  if (!isDocumentWithLanguage(doc)) {
    throw new Error('Document is not a DocumentWithLanguage');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.language = language;
};

export const overrideAuthorInDocData = (doc: AnyDocument, author: EditableKeys['author']): void => {
  if (!isDocumentWithAuthor(doc)) {
    throw new Error('Document is not a DocumentWithAuthor');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.author = author;
};

export const overrideCoverImageInDocData = (
  doc: AnyDocument,
  coverImageUrl: EditableKeys['image_url'],
): void => {
  if (!isDocumentWithSummary(doc)) {
    throw new Error('Document is not a DocumentWithSummary');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.image_url = coverImageUrl;
};

export const overridePublishedDateInDocData = (
  doc: AnyDocument,
  publishedDate: EditableKeys['published_date'],
): void => {
  if (!isDocumentWithPublishedDate(doc)) {
    throw new Error('Document is not a DocumentWithPublishedDate');
  }

  if (!doc.overrides) {
    doc.overrides = {};
  }

  doc.overrides.published_date = publishedDate;
};

export const updateReadingPositionInDocData = (
  doc: AnyDocument,
  position: ReadingPosition,
  options: { force: boolean },
) => {
  if (doc.readingPosition) {
    if ((doc.readingPosition.scrollDepth ?? 0) < position.scrollDepth || options.force) {
      doc.readingPosition = position;
    }
  } else {
    doc.readingPosition = position;
  }
};

export const addRssItemToRssFolderFromState = ({
  state,
  rssFolderId,
  rssItem,
}: { state: FullZustandState; rssFolderId: string; rssItem: RssItem }) => {
  const rssFolder = state.persistent.rssFoldersAndItems.find(
    (rssFolderOrItem) => rssFolderOrItem.id === rssFolderId,
  );

  if (!rssFolder || !isRssFolder(rssFolder)) {
    return;
  }

  // Add item to folder
  rssFolder.childrenRssItems.push(rssItem);

  // Update folder query
  const folderFilteredView = state.persistent.filteredViews[rssFolder.filteredViewId];

  if (!folderFilteredView) {
    return;
  }

  const feedId = rssItem.rssSourceId;

  if (folderFilteredView.query) {
    folderFilteredView.query += ` OR ${getFeedItemQueryFromFeedId(feedId)}`;
  } else {
    folderFilteredView.query = getFeedTrueAndFeedItemQueryFromFeedId(feedId);
  }
};

export const removeFeedIdFromFolderFromState = ({
  state,
  rssFolderId,
  feedId,
}: { state: FullZustandState; rssFolderId: string; feedId: string }) => {
  const rssFolder = state.persistent.rssFoldersAndItems.find(
    (rssFolderOrItem) => rssFolderOrItem.id === rssFolderId,
  );

  if (!rssFolder || !isRssFolder(rssFolder)) {
    return;
  }

  if (rssFolder.childrenRssItems.some((rssItem) => rssItem.rssSourceId === feedId)) {
    rssFolder.childrenRssItems = rssFolder.childrenRssItems.filter(
      (rssItem) => rssItem.rssSourceId !== feedId,
    );

    // Remove feed id from folder filter query
    const folderFilteredView = state.persistent.filteredViews[rssFolder.filteredViewId];

    if (folderFilteredView) {
      folderFilteredView.query = removeSubQueryFromQuery({
        query: folderFilteredView.query,
        subQuery: getFeedItemQueryFromFeedId(feedId),
      });
    }
  }
};

export const removeFeedIdFromSidebarFromState = ({
  state,
  rssFolderId,
  feedId,
}: { state: FullZustandState; feedId: string; rssFolderId?: string }) => {
  for (const rssFolderOrItem of state.persistent.rssFoldersAndItems) {
    if (isRssFolder(rssFolderOrItem) && (!rssFolderId || rssFolderOrItem.id === rssFolderId)) {
      removeFeedIdFromFolderFromState({ state, rssFolderId: rssFolderOrItem.id, feedId });
    }

    if (!rssFolderId && isRssPinnedItem(rssFolderOrItem) && rssFolderOrItem.rssSourceId === feedId) {
      state.persistent.rssFoldersAndItems = state.persistent.rssFoldersAndItems.filter(
        (item) => item.id !== rssFolderOrItem.id,
      );
    }
  }
};

export const removeRssItemFromFolderFromState = ({
  state,
  rssFolderId,
  rssItemId,
}: { state: FullZustandState; rssFolderId: string; rssItemId: string }) => {
  const rssFolder = state.persistent.rssFoldersAndItems.find(
    (rssFolderOrItem) => rssFolderOrItem.id === rssFolderId,
  );

  if (!rssFolder || !isRssFolder(rssFolder)) {
    return;
  }

  // Remove rss children item from folder
  const rssItemIndex = rssFolder.childrenRssItems.findIndex((rssItem) => rssItem.id === rssItemId);
  const rssItem = rssFolder.childrenRssItems[rssItemIndex];
  rssFolder.childrenRssItems.splice(rssItemIndex, 1);

  // Remove feed id from folder filter query
  const folderFilteredView = state.persistent.filteredViews[rssFolder.filteredViewId];
  if (folderFilteredView) {
    folderFilteredView.query = removeSubQueryFromQuery({
      query: folderFilteredView.query,
      subQuery: getFeedItemQueryFromFeedId(rssItem.rssSourceId),
    });
  }
};
