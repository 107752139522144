import { type AnyDocument, type UserEvent } from '../../../../types';
import { isDocumentWithSummary } from '../../../../typeValidators';
import { overrideGeneratedSummaryInDocData } from '../../../stateUpdateHelpers';
import { createToast } from '../../../toasts.platform';
import type { StateUpdateResult } from '../../../types';
import { updateDocument } from './update';

const MAX_SUMMARY_LENGTH = 1000;

export async function updateGeneratedDocumentSummary(
  documentId: AnyDocument['id'],
  generatedSummary: string,
): StateUpdateResult {
  let summary = generatedSummary;
  const summaryIsTooLong = summary.length > MAX_SUMMARY_LENGTH;
  if (summaryIsTooLong) {
    summary = `${summary.slice(0, MAX_SUMMARY_LENGTH)}…`;
  }

  const result = await updateDocument(
    documentId,
    (document) => {
      if (!isDocumentWithSummary(document)) {
        throw new Error('Document type does not support summaries');
      }

      document.generated_summary = summary;

      // reset override for generated summary
      overrideGeneratedSummaryInDocData(document, undefined);
    },
    {
      eventName: 'generated-document-summary-updated',
      userInteraction: 'click',
    },
  );

  if (summaryIsTooLong) {
    createToast({
      content: 'Document summary generated but truncated (longer than 1000 characters)',
      category: 'success',
      undoableUserEventId: (result.userEvent as UserEvent).id,
    });
  } else {
    createToast({
      content: 'Document summary generated',
      category: 'success',
      undoableUserEventId: (result.userEvent as UserEvent).id,
    });
  }

  return { userEvent: result.userEvent };
}
