import { AST } from './types';
import { match } from './utils';

const isLogicalExpression = match({
  field: () => false,
  logical: () => true,
});

export const getQueryFromAst = (ast: AST): string =>
  match({
    field: ({ key, operator, value }) => `${key}${operator}${value}`,
    logical: ({ left, operator, right }) =>
      // If left is a logical expression, wrap it with parens
      isLogicalExpression(left)
        ? `(${getQueryFromAst(left)}) ${operator} ${getQueryFromAst(right)}`
        : `${getQueryFromAst(left)} ${operator} ${getQueryFromAst(right)}`,
  })(ast);
