import keyBy from 'lodash/keyBy';

import type Database from '../database/Database';
import type { DocumentId, PersistentState, PersistentStateWithDocuments } from '../types';
import cloneDeep from './cloneDeep';
import removeNonSyncingPropertiesFromDocument from './removeNonSyncingPropertiesFromDocument';

export default async function attachRxDBDocumentsToPersistentState(
  database: Database,
  persistentState: PersistentState,
  filterDocumentIds?: DocumentId[],
): Promise<PersistentStateWithDocuments> {
  // Clone the state, only taking the needed documents if filterDocumentIds is passed
  const stateWithDocuments = cloneDeep(persistentState) as PersistentStateWithDocuments;
  if (filterDocumentIds) {
    stateWithDocuments.documents = keyBy(
      await database.collections.documents.findByIds(filterDocumentIds),
      'id',
    );
  } else {
    // This is obviously very expensive. It should never happen right now.
    // TODO: remove
    stateWithDocuments.documents = keyBy(await database.collections.documents.findAll(), 'id');
  }

  // Remove any non-syncing properties on the Documents we are returning
  // TODO: update types to make this not an error (just make NonSyncingProperties optional?)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.keys(stateWithDocuments.documents ?? {}).forEach((id) => {
    if (stateWithDocuments.documents?.[id]) {
      removeNonSyncingPropertiesFromDocument(stateWithDocuments.documents[id]);
    }
  });
  return stateWithDocuments;
}
