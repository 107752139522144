import { EventEmitter2 } from 'eventemitter2';

export enum NetworkStatus {
  Offline = 'offline',
  Online = 'online',
}

export type NetworkEvent = 'changed' | NetworkStatus.Offline | NetworkStatus.Online;

export interface NetworkDetector extends EventEmitter2 {
  status: NetworkStatus;
  isOnline: boolean;
}
