import escapeStringForRegex from 'escape-string-regexp';

import { isHTMLElement } from '../../typeValidators';
import binarySearch from '../../utils/binarySearch';
import makeLogger from '../../utils/makeLogger';
import type { RangyRange } from '../types/rangy';
import getDeepestWith from './getDeepestWith';
import rangy from './rangy';

const logger = makeLogger(__filename);

const isTextShortShortEnoughToUseFindText = (input: string) => input.length < 85000;
const isRangeShortEnoughToUseFindText = (rangyRange: RangyRange) =>
  isTextShortShortEnoughToUseFindText(rangyRange.toString());

export default async function findText({
  containerNode,
  logPrefix = '',
  text,
}: {
  containerNode: HTMLElement;
  logPrefix?: string;
  text: string;
}): Promise<RangyRange | null> {
  const trimmedText = text.trim();

  const createRange = () => rangy.createRange(containerNode.ownerDocument);

  /*
    The eventual method we use to do a text search (findText from Rangy's TextRange module) can
    be very slow for large documents (e.g. books). To work around this, we:

    1. Check if the highlight text is short enough. If not, exit. Side note: the length limit is pretty
      arbitrary.
    2. Check if the container/document is short enough. If yes, no problem, go ahead and search.
    3. If not, get the deepest descendant of the container node which contains the entire string we're
      looking for. We use indexOf to do this which is much faster than findText.
    4. Check if this "deepest common ancestor" is usable (short enough).
    5. If not, find the last direct child of this descendant that comes before the string we're looking
      for exists after it. We use binary search here.
    6. Check if this range (from said child to the end of the deepest descendant) is short enough.
    7. If not, do the same the end position (move it up as much as possible).
    8. Use this range if possible, give up otherwise.
  */

  if (!isTextShortShortEnoughToUseFindText(trimmedText)) {
    logger.warn(`${logPrefix}skipping text search, the highlight text is too long`);
    return null;
  }

  // The range we'll eventually search in
  const range = createRange();

  const containerNodeRange = createRange();
  containerNodeRange.selectNodeContents(containerNode);

  if (isRangeShortEnoughToUseFindText(containerNodeRange)) {
    range.selectNodeContents(containerNode);
  } else {
    const trimmedTextRegex = escapeStringForRegex(trimmedText).replace(/\n+/g, '\\s+');
    const doesStringContainSearchText = (input?: string) => Boolean(input?.match(trimmedTextRegex));

    // Get the deepest descendant of the container node which contains the entire string we're looking for
    const deepestCommonAncestor = getDeepestWith<HTMLElement>(
      containerNode,
      (node): node is HTMLElement => {
        if (!isHTMLElement(node)) {
          return false;
        }
        const element = node as HTMLElement;
        return doesStringContainSearchText(element.innerText);
      },
    );

    if (!deepestCommonAncestor) {
      logger.debug("Can't find text (using indexOf)");
      return null;
    }

    range.selectNodeContents(deepestCommonAncestor);

    if (!isRangeShortEnoughToUseFindText(range)) {
      const children = Array.from(deepestCommonAncestor.children);

      // Move start position as far forward as possible
      const closestChildBeforeStartPosition = await binarySearch<Element>(children, async (child) => {
        const rangeClone = range.cloneRange();
        rangeClone.setStartAfter(child);
        return doesStringContainSearchText(rangeClone.toString());
      });

      if (closestChildBeforeStartPosition) {
        range.setStartAfter(closestChildBeforeStartPosition);
        const index = Array.from(deepestCommonAncestor.children).indexOf(
          closestChildBeforeStartPosition,
        );
        if (index > 0) {
          children.splice(0, index - 1);
        }
      }

      if (!isRangeShortEnoughToUseFindText(range)) {
        // Move end position as far back as possible
        const closestChildAfterEndPosition = await binarySearch<Element>(children, async (child) => {
          const rangeClone = range.cloneRange();
          rangeClone.setEndAfter(child);
          return doesStringContainSearchText(rangeClone.toString());
        });

        if (closestChildAfterEndPosition) {
          range.setEndAfter(closestChildAfterEndPosition);
        }

        if (!isRangeShortEnoughToUseFindText(range)) {
          logger.warn(`${logPrefix}skipping text search, we can't narrow down DOM enough`);
          return null;
        }
      }
    }
  }

  /*
    Actually do the text search...
  */

  const findRange = createRange();
  const regex = new RegExp(
    // Sometimes newlines, etc. can be different between saved and rendered text
    escapeStringForRegex(trimmedText)
      .replace(/(\s{2,}|\n)/g, '\\s+')

      /*
        There can be a discrepancy in how whitespace is handled between the extension, reader, creation, and text
        search. Example: on
        https://www.edweek.org/technology/should-it-stay-or-should-it-go-schools-trim-number-of-tech-tools-they-use/2022/09,
        if you highlight the paragraph that starts with "She and other ed-tech" and the following paragraph,
        `range.toString()` won't include whitespace between the paragraphs. Whereas if ran in our web app, whitespace
        is included. This lack of whitespace in the lookup string would cause the text search to fail.

        Rather than risk breaking stuff further by trying to implement our own `range.toString`, we allow optional
        whitespace between any `.` followed by an uppercase letter.
      */
      .replace(/(\.)([A-Z])/g, (match, g1, g2) => `${g1}\\s*?${g2}`),
    'gm',
  );
  if (
    findRange.findText(regex, {
      withinRange: range,
    })
  ) {
    return findRange;
  }

  logger.debug("Can't find text");
  return null;
}
