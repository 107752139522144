import { Token } from '../filters-compiler/types';
import type { FilteredView } from '../types';
import getEmojiFromStartOfString from './getEmojiFromStartOfString';
import getWords from './getWords';

export const shortListName = 'Shortlist';
export const shortListQuery = 'tag:shortlist AND (in:inbox OR in:later)';
export const shortListQueryWhenShortlistLocationIsEnabled = 'in:shortlist';
export const shortListTag = 'shortlist';
export const todaysDailyDigestId = 'todays-digest';

export const articlesQueries = ['category:article', 'type:article'];
export const epubsQueries = ['category:epub', 'type:epub'];
export const emailsQueries = ['category:email', 'type:email'];
export const pdfsQueries = ['category:pdf', 'type:pdf'];
export const tweetsQueries = ['category:tweet', 'type:tweet'];
export const videosQueries = ['category:video', 'type:video'];
export const allDefaultCategoriesQueries = [
  ...articlesQueries,
  ...emailsQueries,
  ...pdfsQueries,
  ...epubsQueries,
  ...tweetsQueries,
  ...videosQueries,
];

export function getCategoriesIconMap<T = JSX.Element>({
  articlesIcon,
  emailsIcon,
  epubsIcon,
  pdfsIcon,
  tweetsIcon,
  videosIcon,
}: { articlesIcon: T; emailsIcon: T; epubsIcon: T; pdfsIcon: T; tweetsIcon: T; videosIcon: T }): {
  [key: string]: T;
} {
  return allDefaultCategoriesQueries.reduce((acc, query) => {
    if (query.includes('article')) {
      return {
        ...acc,
        [query]: articlesIcon,
      };
    }

    if (query.includes('email')) {
      return {
        ...acc,
        [query]: emailsIcon,
      };
    }

    if (query.includes('pdf')) {
      return {
        ...acc,
        [query]: pdfsIcon,
      };
    }

    if (query.includes('epub')) {
      return {
        ...acc,
        [query]: epubsIcon,
      };
    }

    if (query.includes('tweet')) {
      return {
        ...acc,
        [query]: tweetsIcon,
      };
    }

    if (query.includes('video')) {
      return {
        ...acc,
        [query]: videosIcon,
      };
    }

    return acc;
  }, {});
}

export const getFilterViewAbbreviationNameOrEmoji = (name: string): string => {
  if (name === '') {
    return 'NN';
  }

  const [firstChar, secondChar = ''] = name;

  const emoji = getEmojiFromStartOfString(name);

  if (emoji) {
    return emoji;
  }

  const words = getWords(name, 'unknown');
  if (words.length === 1) {
    return `${firstChar.toLocaleUpperCase()}${secondChar}`;
  }

  const [firstCharOfSecondWord] = words[1];
  return `${firstChar.toLocaleUpperCase()}${(firstCharOfSecondWord || '').toLocaleUpperCase()}`;
};

export const getFilterViewAbbreviationName = (name: string): string => {
  if (name === '') {
    return 'NN';
  }

  const [firstChar, secondChar = ''] = name;

  const emoji = getEmojiFromStartOfString(name);

  if (emoji) {
    return '';
  }

  const words = getWords(name, 'unknown');

  if (words.length === 1) {
    return `${firstChar.toLocaleUpperCase()}${secondChar}`;
  }

  const [firstCharOfSecondWord] = words[1];
  return `${firstChar.toLocaleUpperCase()}${firstCharOfSecondWord.toLocaleUpperCase()}`;
};

export const getIsFilteringByOneRss = (filterTokens: Token[] | undefined) => {
  if (!filterTokens) {
    return false;
  }

  return Boolean(
    filterTokens.length === 3 &&
      (filterTokens[0].value.toLocaleLowerCase() === 'rsssource' ||
        filterTokens[0].value.toLocaleLowerCase() === 'rss_source'),
  );
};

export const getFeedIdFromTokens = (filterTokens: Token[] | undefined): string => {
  if (!filterTokens || !getIsFilteringByOneRss(filterTokens)) {
    return '';
  }

  return filterTokens[2].value;
};

export const getIsFilteringByOneTag = (filterTokens: Token[] | undefined) => {
  if (!filterTokens) {
    return false;
  }

  return Boolean(filterTokens.length === 3 && filterTokens[0].value === 'tag');
};

export const getTagNameFromTokens = (filterTokens: Token[] | undefined): string => {
  if (!filterTokens || !getIsFilteringByOneTag(filterTokens)) {
    return '';
  }

  return filterTokens[2].value;
};

export function isAllowedToDeleteFilteredView(id?: FilteredView['id']): boolean {
  return !isDailyDigest(id);
}

export function isAllowedToSortFilteredView(id?: FilteredView['id']): boolean {
  return !isDailyDigest(id);
}

export function isAllowedToEditFilteredView(view?: FilteredView): boolean {
  return !isDailyDigest(view?.id) && !view?.rssFolderId;
}

export function isDailyDigest(id?: FilteredView['id']): boolean {
  if (!id) {
    return false;
  }

  return id === todaysDailyDigestId || id.startsWith('digest-');
}

export function isEmptyRssFolder(view: FilteredView | undefined): boolean {
  return Boolean(view?.rssFolderId && !view.query);
}
