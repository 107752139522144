import { type AnyDocument, ReadingStatus } from '../../../types';
import { isFirstClassDocument } from '../../../typeValidators';
// eslint-disable-next-line import/no-cycle
import { CancelStateUpdate, updateState } from '../../models';
import { getDocument } from '../../stateGetters';
import { StateUpdateOptionsWithoutEventName } from '../../types';

export const setCurrentlyReading = async (
  documentId: AnyDocument['id'] | null,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  if (!documentId) {
    await updateState(
      (state) => {
        if (state.persistent.currentlyReadingId === documentId) {
          throw new CancelStateUpdate();
        }
        state.persistent.currentlyReadingId = documentId;
        state.persistent.currentlyReadingListQuery = null;
      },
      { ...options, eventName: 'set-currently-reading-doc', isUndoable: false },
    );
    return;
  }
  const existingDoc = await getDocument<AnyDocument>(documentId);
  if (
    !existingDoc ||
    !isFirstClassDocument(existingDoc) ||
    existingDoc.reading_status === ReadingStatus.Archived
  ) {
    return;
  }
  await updateState(
    (state) => {
      if (state.persistent.currentlyReadingId === documentId) {
        throw new CancelStateUpdate();
      }
      state.persistent.currentlyReadingId = documentId;
      state.persistent.currentlyReadingListQuery = JSON.stringify(state.focusedDocumentListQuery);
    },
    { ...options, eventName: 'set-currently-reading-doc', isUndoable: false },
  );
};
