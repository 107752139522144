import { useMemo } from 'react';

import type { CommonDatabaseHookOptions } from '../../../types/database';
// eslint-disable-next-line
import { globalState } from '../../models';

export default function useOptions<T extends CommonDatabaseHookOptions>(
  options: T,
  isMissingMandatoryQuery?: boolean,
): T {
  const areAllDatabaseHooksDisabled = globalState((state) => state.areAllDatabaseHooksDisabled);

  return useMemo(() => {
    const result = { ...options };
    if (areAllDatabaseHooksDisabled || isMissingMandatoryQuery) {
      result.isEnabled = false;
    }
    return result;
  }, [areAllDatabaseHooksDisabled, isMissingMandatoryQuery, options]);
}
