import DOMPurify from 'dompurify';

export default (input: string, extraTagsToAllow: string[] = []): HTMLElement => {
  const throwawayElement = document.createElement('div');
  throwawayElement.innerHTML = DOMPurify.sanitize(input, {
    /* eslint-disable @typescript-eslint/naming-convention */
    ADD_TAGS: extraTagsToAllow,
    /* eslint-enable @typescript-eslint/naming-convention */
  });
  return throwawayElement.firstChild as HTMLElement;
};
