export default class HttpError extends Error {
  public response: Response;
  public reason: string;

  constructor(response: Response, reason = '') {
    super(`${response.status} for ${response.url}${reason && ` Reason: ${reason}`}`);
    this.name = 'HttpError';
    this.response = response;
    this.reason = reason;
  }

  async getReasonFromJson(): Promise<void> {
    try {
      const jsonData = await this.response.json();
      this.reason = jsonData.reason;
      if (this.reason) {
        this.message += ` Reason: ${this.reason}`;
      }
    } catch (err) {
      // If the json didn't have a reason in it, oh well.
    }
  }
}
