import getSiblings from './getSiblings';

/*
  This gets every element between the given start and end elements (exclusive), but it won't return descendants;
  i.e. none of the results will contain each other.

  Imagine:
  - A
    - A1
    - A2
    - A3
  - B
    - B1
    - B2
    - B3
  - C
    - C1
    - C2
    - C3

  If the start is A2, and the end is C2, the results will be:
  - A3
  - B
  - C1

  See getElementsBetween.spec.ts for more.
*/
export default function getElementsBetween({
  container,
  end,
  start,
}: {
  container: Element;
  end: Element;
  start: Element;
}): Element[] {
  const startVersusEndPositionComparison = start.compareDocumentPosition(end);
  if (startVersusEndPositionComparison === Node.DOCUMENT_POSITION_DISCONNECTED) {
    throw new Error('start and end are in different documents');
  }
  if (startVersusEndPositionComparison === Node.DOCUMENT_POSITION_PRECEDING) {
    throw new Error('end is before start');
  }
  if (!container.contains(start)) {
    throw new Error('start is not in container');
  }
  if (!container.contains(end)) {
    throw new Error('end is not in container');
  }
  if (
    [Node.DOCUMENT_POSITION_CONTAINED_BY as number, Node.DOCUMENT_POSITION_CONTAINS].includes(
      startVersusEndPositionComparison as typeof Node.DOCUMENT_POSITION_CONTAINED_BY,
    ) ||
    start.contains(end) ||
    end.contains(start)
  ) {
    return [];
  }

  const results: Element[] = [];
  let firstOfLevel: Element | null | void = start;
  let endAncestor: Element | undefined;

  while (firstOfLevel && !firstOfLevel.isEqualNode(container) && container.contains(firstOfLevel)) {
    const nextResult = getSiblings({
      direction: 'next',
      element: firstOfLevel,
      matcher: (sibling) => !sibling.contains(end),
      shouldStopOnFirstFailedMatch: true,
    });

    results.push(...(nextResult.siblings as Element[]));

    if (nextResult.firstFailedMatch) {
      endAncestor = nextResult.firstFailedMatch as Element;
      break;
    }

    firstOfLevel = (firstOfLevel as Element).parentElement;
  }

  if (endAncestor && !endAncestor.isEqualNode(end)) {
    firstOfLevel = endAncestor.firstElementChild;

    while (firstOfLevel && !firstOfLevel.isEqualNode(container) && container.contains(firstOfLevel)) {
      if (firstOfLevel.isEqualNode(end)) {
        break;
      }
      results.push(firstOfLevel);

      const previousResult: ReturnType<typeof getSiblings> = getSiblings({
        direction: 'next',
        element: firstOfLevel,
        matcher: (sibling) => !sibling.contains(end),
        shouldStopOnFirstFailedMatch: true,
      });

      results.push(...(previousResult.siblings as Element[]));

      // When we find an ancestor of the end, go down
      if (previousResult.firstFailedMatch) {
        firstOfLevel = (previousResult.firstFailedMatch as Element).firstElementChild;
      } else {
        break;
      }
    }
  }

  return results;
}
