import sortBy from 'lodash/sortBy';
import type { RxCollection } from 'rxdb';

import type { DatabaseDocType } from '../types/database';
import getPrimaryKeyFromDatabaseEntry from './getPrimaryKeyFromDatabaseEntry';

export default function sortDatabaseItemsByIdList<TDocType extends DatabaseDocType>({
  ids,
  items,
  rxCollection,
}: {
  ids: string[];
  items: TDocType[];
  rxCollection: RxCollection;
}): TDocType[] {
  const idToIndexMap = Object.fromEntries(ids.map((itemId, index) => [itemId, index]));
  return sortBy(
    items,
    (item) => idToIndexMap[getPrimaryKeyFromDatabaseEntry(item, rxCollection)] ?? 9999999,
  );
}
