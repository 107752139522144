import { matchPath } from 'react-router-dom';

import { Category } from '../../types';
import { isMobile } from '../../utils/environment';
import pluralize from '../../utils/pluralize';

// NOTE: this is not the same as the Category model
export const getCategoriesForUrls = (): string[] =>
  Object.keys(Category).map((categoryName) => pluralize(categoryName).toLowerCase());

export const getCategoryUrlPlaceholder = (): string => `:category(${getCategoriesForUrls().join('|')})`;

export const getCategoryUrlArgument = (): string | null => {
  return (
    matchPath<{ category: string }>(isMobile ? '' : window.location.pathname, {
      path: `/${getCategoryUrlPlaceholder()}`,
    })?.params?.category ?? null
  );
};

export const getCategoryFromUrl = (): Category | undefined => {
  const categoryUrlArgument = getCategoryUrlArgument();

  if (!categoryUrlArgument) {
    return undefined;
  }

  return (
    Object.values(Category).find(
      (category) => pluralize(category).toLowerCase() === categoryUrlArgument,
    ) ?? undefined
  );
};
