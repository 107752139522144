import runInPageContext from './runInPageContext';

export const defineCustomElement = async (
  tagName: string,
  customElementString: string,
  options = {},
): Promise<void> => {
  if (customElements?.get(tagName)) {
    return;
  }

  /*
    Defining the custom element in the content script sandbox doesn't work in Firefox
    Related: https://bugzilla.mozilla.org/show_bug.cgi?id=1488010
  */
  await runInPageContext(
    `class ReadwiseElement extends HTMLElement {
      constructor() {
        super();

        [...this.children].forEach((child) => this.appendChild(child));
      }
    }

    try {
      customElements.define(
        "${tagName}",
        ${customElementString},
        ${JSON.stringify(options)},
      );
    } catch (e) {
      if(!e.message.includes("already been defined")
        && !e.message.includes("already been used with this registry")) {
        console.error("Failed to define custom element", e);
        throw e;
      }
    }`,
    true,
  );
};

export default {
  defineCustomElement,
};
