import flatten from 'lodash/flatten';
import type { MangoQuery } from 'rxdb';

import mangoQueryBuilders from '../../database/mangoQueryBuilders';
import { documentHasAnnotationsQuery } from '../../database/standardQueries';
import { AnyDocument, FirstClassDocument } from '../../types';
import { isUsingSQLite } from '../../utils/environment';
import { groupDocumentsByParsedDocId } from '../utils/groupDocumentsByParsedDocId';
// eslint-disable-next-line import/no-cycle
import database from './index';

// Hannes says fanning out here is better for perf than doing `parsed_doc_id: { $in: [...] }` in a single `find`
export async function findDocsForParsedDocIds(
  parsedDocIds: string[],
): Promise<{ [parsedDocId: string]: FirstClassDocument[] }> {
  // For SQLite it is quicker to use a single `$in` operation instead of fanning
  // out into multiple queries.
  let documents: AnyDocument[];
  if (isUsingSQLite) {
    const parsedDocIdsAsInts = parsedDocIds.map((id) => parseInt(id, 10));
    documents = await database.collections.documents.find({
      selector: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'rxdbOnly.indexFields.parsed_doc_id': {
          $in: parsedDocIdsAsInts,
        },
      },
    });
  } else {
    documents = flatten(
      await Promise.all(
        parsedDocIds.map((id) =>
          database.collections.documents.find({
            selector: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'rxdbOnly.indexFields.parsed_doc_id': parseInt(id, 10),
            },
          }),
        ),
      ),
    );
  }
  return groupDocumentsByParsedDocId(documents);
}

export async function containsAtLeastOneDocumentWithAnnotation(
  query: MangoQuery<AnyDocument>,
): Promise<boolean> {
  const firstDocWithAnnotation = await database.collections.documents.findOne({
    ...query,
    selector: mangoQueryBuilders.$and([query.selector, documentHasAnnotationsQuery]),
  });
  return Boolean(firstDocWithAnnotation);
}
