import { DisplayTheme } from '../../../shared/types';

export const BASE_COLORS = {
  white: '#FFFFFF',
  white60: 'rgba(255,255,255,0.6)',
  black: '#000000',
  blackish: '#252525',
  black3: 'rgba(23,30,39,0.8)',
  danger: '#e80000',
  emeraldGreen: '#32936F',
  lightGrey: '#F1F1F1F1',
  grey: '#A6A6A6',
  darkGrey: '#898A8C',
  veryDarkGrey: '#3E3E40',
  darkPrimaryDarker: '#090f13',
  darkPrimary: '#111B24',
  darkPrimaryLight: '#1B2834',
  darkPrimaryLighter: '#242D39',
  darkPrimaryLightest: '#2E3745',
  borderFocus: '#438FFF',
  blue20: '#001c73',
  blue30: '#293E9B',
  blue50: '#376EF2',
  blue60: '#598EF4',
  blue70: '#84ACF1',
  blue80: '#AEC8F1',
  neutral00: '#000000',
  neutral05: '#0c1117',
  neutral07: '#10161D',
  neutral10: '#151C23',
  neutral15: '#1E272F',
  neutral20: '#28313B',
  neutral30: '#3E4853',
  neutral35: '#49535F',
  neutral40: '#555F6B',
  neutral45: '#616C77',
  neutral50: '#6E7883',
  neutral60: '#88929C',
  neutral65: '#959FAA',
  neutral70: '#A3ACB7',
  neutral75: '#B2B9C2',
  neutral80: '#C1C7CE',
  neutral90: '#E0E3E6',
  neutral95: '#F0F1F2',
  neutral97: '#F9F9FA',
  neutral100: '#FFFFFF',
  neutralWarm05: '#180f09',
  neutralWarm10: '#251912',
  neutralWarm15: '#30231a',
  neutralWarm20: '#3c2d23',
  neutralWarm25: '#48382c',
  neutralWarm30: '#544335',
  neutralWarm35: '#614f3f',
  neutralWarm40: '#6d5b4a',
  neutralWarm45: '#7a6755',
  neutralWarm50: '#867460',
  neutralWarm55: '#93816b',
  neutralWarm60: '#a08e77',
  eutralWarm65: '#ad9b84',
  neutralWarm70: '#baa990',
  neutralWarm75: '#c7b79d',
  neutralWarm80: '#d4c5ab',
  neutralWarm85: '#e1d3b9',
  neutralWarm90: '#eae2d2',
  neutralWarm95: '#f4f0e9',
  neutralWarm97: '#faf9f6',
  neutralWarm100: '#FFFFFF',
  red20: '#4e2422',
  red50: '#DB3C2F',
  red60: '#F26159',
  red97: '#FEF4F4',
  purple40: '#8938B0',
  purple50: '#9748FF',
  purple60: '#BB72E4',
  indigo40: '#5749CF',
  indigo60: '#8D86E4',
  yellow70: '#b09200',
  yellow80: '#EBC300',
  yellow90: '#FDE266',
  yellow97: '#FFF7CE',
  orange70: '#FB9100',
  orange100: '#FF7A00',
  cyan100: '#00B2F6',
  green20: '#02382a',
  green50: '#20866B',
  green80: '#81d7ba',
  green97: '#EEF9F4',
  white50Opacity: 'rgba(255,255,255,0.5)',
  sepiaBackground: '#FBF0D9',
  sepiaText: '#5F4B32',
  teal70: '#00B7FA',
};

const U_ALPHA_LIGHT = {
  uAlpha100: 'rgba(62, 72, 83, 0.03)',
  uAlpha200: 'rgba(104,121,141,0.1)',
  uAlpha300: 'rgba(40, 49, 59, 0.09)',
  uAlpha400: 'rgba(40, 49, 59, 0.12)',
  uAlpha500: 'rgba(40, 49, 59, 0.15)',
  uAlphaRed200: 'rgba(219,60,47,0.06)',
  uAlphaBlue200: 'rgba(26,89,200,0.06)',
  uAlphaBlue300: 'rgba(24, 90, 189, 0.09)',
};

const U_ALPHA_DARK = {
  uAlpha100: 'rgba(92, 105, 119, 0.2)',
  uAlpha200: 'rgba(104,121,141,0.23)',
  uAlpha300: 'rgba(92, 105, 119, 0.34)',
  uAlpha400: 'rgba(92, 105, 119, 0.42)',
  uAlpha500: 'rgba(92, 105, 119, 0.52)',
  uAlpha600: 'rgba(92, 105, 119, 0.62)',
  uAlphaRed200: 'rgba(219,60,47,0.26)',
  uAlphaBlue200: 'rgba(45,117,229,0.15)',
  uAlphaBlue300: 'rgba(24, 90, 189, 0.09)',
};

const SHADOWS = {
  shadow100: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 1.0,
    elevation: 1,
  },
  shadow200: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.17,
    shadowRadius: 2.54,
    elevation: 3,
  },
  shadow300: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5.62,
    elevation: 8,
  },
  shadow400: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.23,
    shadowRadius: 12.81,
    elevation: 16,
  },
};

const highlightColorLight = 'rgba(255, 243, 134, 0.5)';
const highlightColorDark = '#0b5173';

export type Theme = typeof BASE_COLORS &
  typeof U_ALPHA_LIGHT &
  typeof SHADOWS & {
    type: DisplayTheme;
    annotationBarPopoverDeleteButtonBackground: string;
    bgBlurred: string;
    bgCanvas: string;
    bgInteractive: string;
    backgroundPrimary: string;
    backgroundSecondary: string;
    backgroundTertiary: string;
    backgroundElevated: string;
    backgroundBackdrop: string;
    borderPrimary: string;
    borderSecondary: string;
    borderSecondaryAlpha: string;
    borderTertiaryAlpha: string;
    playbackControlsBg: string;
    readerBackground: string;
    readerOverlay: string;
    readerHeaderBorder: string;
    text: string;
    textInverse: string;
    secondaryText: string;
    textTertiary: string;
    textError: string;
    textPlaceholder: string;
    textDisabled: string;
    readingTitleText: string;
    highlightColorTop: string;
    highlightColorBottom: string;
    highlightTextColor: string;
    inboxDropShadow: string;
    infoMetadataTitle: string;
    infoMetadataValue: string;
    infoCountBackground: string;
    tagBackgroundColor: string;
    buttonPrimaryDefaultColor: string;
    buttonPrimaryDefaultColorInverse: string;
    buttonSecondaryDefaultColor: string;
    textInteractive: string;
    currentlyReadingBackground: string;
    progressBarBackground: string;
    progressBarScrollBackground: string;
    activityIndicatorColor: string;
    iconDanger: string;
    iconFaint: string;
    iconPlaceholder: string;
    iconPrimary: string;
    iconSecondary: string;
    iconTertiary: string;
    iconInteractive: string;
    iconDisabled: string;
    toastBackground: string;
    toastText: string;
    toastButtonText: string;
    selection: string;
    teleprompt: string;
    inboxTabBarSelection: string;
    searchHighlight: string;
    iconInverse: string;
    digestNavButton: string;
    pdfDigestBackground: string;
    settingsBackground: string;
    listItemBackground: string;
    pageShadow: string;
    pageBorderBackground: string;
    pageScrollBackground: string;
    readerSettingsMenuGradientColor: string;
    switchButton: string;
  };
export const LIGHT_THEME: Theme = {
  ...SHADOWS,
  ...BASE_COLORS,
  type: DisplayTheme.Default,
  ...U_ALPHA_LIGHT,
  annotationBarPopoverDeleteButtonBackground: BASE_COLORS.red97,
  text: BASE_COLORS.neutral20,
  textInverse: BASE_COLORS.neutral100,
  secondaryText: BASE_COLORS.neutral45,
  textTertiary: BASE_COLORS.neutral60,
  textError: BASE_COLORS.red50,
  textPlaceholder: BASE_COLORS.neutral65,
  textDisabled: BASE_COLORS.neutral75,
  textInteractive: BASE_COLORS.blue50,
  readingTitleText: BASE_COLORS.neutral05,
  bgBlurred: BASE_COLORS.neutral80,
  bgCanvas: BASE_COLORS.neutral95,
  bgInteractive: BASE_COLORS.blue50,
  backgroundPrimary: BASE_COLORS.neutral100,
  backgroundSecondary: BASE_COLORS.neutral97,
  backgroundTertiary: BASE_COLORS.neutral95,
  backgroundElevated: BASE_COLORS.neutral100,
  backgroundBackdrop: U_ALPHA_LIGHT.uAlpha300,
  borderPrimary: BASE_COLORS.neutral80,
  borderSecondary: BASE_COLORS.neutral90,
  borderSecondaryAlpha: U_ALPHA_LIGHT.uAlpha300,
  borderTertiaryAlpha: U_ALPHA_LIGHT.uAlpha200,
  playbackControlsBg: '#F9F9FA',
  readerBackground: BASE_COLORS.neutral97,
  readerOverlay: 'rgba(0,0,0,1)',
  readerHeaderBorder: '#E4E6EA',
  inboxDropShadow: BASE_COLORS.veryDarkGrey,
  infoMetadataTitle: '#B1B6BB',
  infoMetadataValue: '#4B4D52',
  infoCountBackground: '#ECECEC',
  tagBackgroundColor: BASE_COLORS.lightGrey,
  highlightColorTop: highlightColorLight,
  highlightColorBottom: highlightColorLight,
  highlightTextColor: 'black',
  buttonPrimaryDefaultColor: 'rgba(24, 90, 189, 0.06)',
  buttonPrimaryDefaultColorInverse: 'rgba(51, 108, 255, 0.26)',
  buttonSecondaryDefaultColor: U_ALPHA_LIGHT.uAlpha200,
  progressBarBackground: BASE_COLORS.indigo40,
  progressBarScrollBackground: '#b8b9bd',
  currentlyReadingBackground: '#FAFCFF',
  activityIndicatorColor: BASE_COLORS.darkGrey,
  iconDanger: BASE_COLORS.red50,
  iconFaint: U_ALPHA_LIGHT.uAlpha200,
  iconPlaceholder: '#939FAD',
  iconPrimary: BASE_COLORS.neutral20,
  iconSecondary: BASE_COLORS.neutral45,
  iconTertiary: BASE_COLORS.neutral60,
  iconInteractive: BASE_COLORS.blue50,
  iconDisabled: BASE_COLORS.neutral70,
  toastBackground: BASE_COLORS.neutral10,
  toastText: '#F9F9FA',
  toastButtonText: '#AEC8F1',
  selection: 'rgba(55,110,242,0.25)',
  teleprompt: BASE_COLORS.blue50,
  inboxTabBarSelection: U_ALPHA_LIGHT.uAlpha400,
  searchHighlight: 'rgba(38, 117, 222, 0.12)',
  iconInverse: BASE_COLORS.neutral97,
  digestNavButton: BASE_COLORS.purple40,
  pdfDigestBackground: BASE_COLORS.neutral90,
  settingsBackground: BASE_COLORS.neutral100,
  listItemBackground: U_ALPHA_LIGHT.uAlpha200,
  pageShadow: 'rgba(12, 17, 23, 0.27)',
  pageBorderBackground: 'rgba(210,210,210,0.26)',
  pageScrollBackground: BASE_COLORS.neutral97,
  readerSettingsMenuGradientColor: `${BASE_COLORS.indigo40}20`,
  switchButton: BASE_COLORS.neutral100,
};

export const DARK_THEME: Theme = {
  ...BASE_COLORS,
  ...LIGHT_THEME,
  ...U_ALPHA_DARK,
  type: DisplayTheme.Dark,
  annotationBarPopoverDeleteButtonBackground: 'rgba(244, 143, 139, 0.1)',
  text: BASE_COLORS.neutral95,
  textInverse: BASE_COLORS.neutral20,
  textInteractive: BASE_COLORS.blue70,
  secondaryText: BASE_COLORS.neutral65,
  textTertiary: BASE_COLORS.neutral50,
  textError: BASE_COLORS.red60,
  textPlaceholder: BASE_COLORS.neutral45,
  textDisabled: BASE_COLORS.neutral40,
  readingTitleText: BASE_COLORS.neutral20,
  bgCanvas: BASE_COLORS.neutral00,
  backgroundPrimary: BASE_COLORS.neutral05,
  backgroundSecondary: BASE_COLORS.neutral10,
  backgroundTertiary: BASE_COLORS.neutral15,
  bgInteractive: BASE_COLORS.blue70,
  bgBlurred: '#000c15',
  backgroundElevated: BASE_COLORS.neutral10,
  backgroundBackdrop: 'rgba(0,0,0,0.4)',
  borderPrimary: BASE_COLORS.neutral20,
  borderSecondary: BASE_COLORS.neutral15,
  borderSecondaryAlpha: U_ALPHA_DARK.uAlpha300,
  borderTertiaryAlpha: U_ALPHA_DARK.uAlpha200,
  playbackControlsBg: '#1E272F',
  readerBackground: BASE_COLORS.neutral00,
  readerOverlay: 'rgba(0,0,0,0.75)',
  readerHeaderBorder: BASE_COLORS.darkPrimaryLightest,
  inboxDropShadow: 'black',
  infoMetadataTitle: '#ABABAB',
  infoMetadataValue: '#E8E8E8',
  infoCountBackground: '#445064',
  tagBackgroundColor: 'rgba(255,255,255,0.1)',
  highlightColorTop: highlightColorDark,
  highlightColorBottom: 'rgba(0,178,255,0.36)',
  highlightTextColor: 'white',
  buttonPrimaryDefaultColor: 'rgba(51, 108, 255, 0.26)',
  buttonPrimaryDefaultColorInverse: 'rgba(24, 90, 189, 0.06)',
  buttonSecondaryDefaultColor: U_ALPHA_DARK.uAlpha200,
  progressBarBackground: BASE_COLORS.purple50,
  progressBarScrollBackground: BASE_COLORS.neutral65,
  currentlyReadingBackground: BASE_COLORS.darkPrimaryLighter,
  activityIndicatorColor: BASE_COLORS.white,
  iconFaint: BASE_COLORS.neutral30,
  iconPlaceholder: '#939FAD',
  iconPrimary: BASE_COLORS.neutral95,
  iconSecondary: BASE_COLORS.neutral70,
  iconTertiary: BASE_COLORS.neutral45,
  iconInteractive: BASE_COLORS.blue70,
  iconDisabled: BASE_COLORS.neutral40,
  toastBackground: BASE_COLORS.neutral100,
  toastText: '#28313B',
  toastButtonText: '#376EF2',
  selection: 'rgba(89,142,244,0.35)',
  inboxTabBarSelection: U_ALPHA_DARK.uAlpha400,
  searchHighlight: 'rgba(69, 146, 238, 0.10)',
  iconInverse: BASE_COLORS.neutral20,
  digestNavButton: BASE_COLORS.purple60,
  pdfDigestBackground: BASE_COLORS.neutral20,
  settingsBackground: BASE_COLORS.neutral00,
  listItemBackground: U_ALPHA_DARK.uAlpha200,
  pageShadow: BASE_COLORS.neutral00,
  pageBorderBackground: BASE_COLORS.neutral00,
  pageScrollBackground: BASE_COLORS.neutral10,
  readerSettingsMenuGradientColor: `${BASE_COLORS.purple60}0A`,
  switchButton: BASE_COLORS.neutral100,
};

export const THEMES = {
  [DisplayTheme.Default]: LIGHT_THEME,
  [DisplayTheme.Dark]: DARK_THEME,
};

export const INVERSE_THEMES = {
  [DisplayTheme.Default]: DARK_THEME,
  [DisplayTheme.Dark]: LIGHT_THEME,
};

const HIGH_CONTRAST_LIGHT_THEME: Partial<Theme> = {
  borderPrimary: BASE_COLORS.neutral20,
  borderSecondary: BASE_COLORS.neutral35,
  borderSecondaryAlpha: BASE_COLORS.neutral40,
  borderTertiaryAlpha: BASE_COLORS.neutral45,
  inboxTabBarSelection: BASE_COLORS.blue70,
  selection: 'rgb(55,110,242)',
  textInteractive: BASE_COLORS.blue20,
  iconInteractive: BASE_COLORS.blue20,
  iconDisabled: BASE_COLORS.neutral70,
  switchButton: BASE_COLORS.neutral80,
};

const HIGH_CONTRAST_DARK_THEME = {
  borderPrimary: BASE_COLORS.neutral90,
  borderSecondary: BASE_COLORS.neutral80,
  borderSecondaryAlpha: BASE_COLORS.neutral70,
  borderTertiaryAlpha: BASE_COLORS.neutral70,
  inboxTabBarSelection: BASE_COLORS.blue30,
  selection: 'rgb(115,157,245)',
  textInteractive: BASE_COLORS.blue80,
  iconInteractive: BASE_COLORS.blue80,
  switchButton: BASE_COLORS.neutral90,
};

export const HIGH_CONTRAST_THEMES = {
  [DisplayTheme.Default]: HIGH_CONTRAST_LIGHT_THEME,
  [DisplayTheme.Dark]: HIGH_CONTRAST_DARK_THEME,
};

export const Spacing = {
  baseHorizontalMargin: 16,
  readerHeaderHeight: 60,
  readerBottomPadding: 200,
  maxDocumentWidth: 1024,
  minTabletDocumentWidth: 350,
};

export const Timing = {
  readingViewBannerHide: 400,
};

export const ThemeCSSMap = {
  [DisplayTheme.Dark]: 'theme--dark',
  [DisplayTheme.Default]: 'theme--light',
};

export const getInverseTheme = (theme: Theme): Theme => {
  if (theme.type === DisplayTheme.Dark) {
    return THEMES[DisplayTheme.Default];
  } else {
    return THEMES[DisplayTheme.Dark];
  }
};
