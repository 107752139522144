import * as jsonpatch from 'readwise-fast-json-patch';

import type { PersistentState } from '../types';
import removeNonSyncingPropertiesFromDocument from './removeNonSyncingPropertiesFromDocument';

export default (
  stateA: PersistentState,
  stateB: PersistentState,
): ReturnType<typeof jsonpatch.compare> => {
  const patch = jsonpatch.compare(stateA, stateB);
  for (const operation of patch) {
    const isFullDocumentAdd =
      operation.op === 'add' &&
      operation.path.startsWith('/documents/') &&
      operation.path.split('/').length === 3;
    if (!isFullDocumentAdd) {
      continue;
    }
    removeNonSyncingPropertiesFromDocument(operation.value);
  }
  return patch;
};
