import * as jsonpatch from 'readwise-fast-json-patch';

import type { JSONPatchOperationWithPathSegments } from '../types/jsonpatch';

export default function groupPatchOperationsByStorage({
  operations: allOperations, // it's renamed so it's more obvious if you accidentally use it
}: {
  operations: jsonpatch.Operation[];
}): {
  operationsForRxDb: JSONPatchOperationWithPathSegments[];
  operationsForZustand: JSONPatchOperationWithPathSegments[];
} {
  // We need to split the operations into two arrays; Zustand and RxDB
  const operationsForZustand: JSONPatchOperationWithPathSegments[] = [];
  const operationsForRxDb: JSONPatchOperationWithPathSegments[] = [];
  for (const operation of allOperations) {
    const operationWithPathSegments: JSONPatchOperationWithPathSegments = {
      ...operation,
      pathSegments: operation.path.split('/').filter(Boolean),
    };
    if (operationWithPathSegments.pathSegments[0] === 'documents') {
      operationsForRxDb.push(operationWithPathSegments);
    } else {
      operationsForZustand.push(operationWithPathSegments);
    }
  }

  return {
    operationsForRxDb,
    operationsForZustand,
  };
}
