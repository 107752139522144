import { Spacing } from '../css/baseStyles';
import type { MobileContentFrameWindow } from './types';

declare let window: MobileContentFrameWindow;

export const END_OF_READING_ANIMATE_THRESHOLD = 80;
export const END_OF_READING_ANIMATION_RANGE = 5;
const BUTTON_DESIRED_WIDTH = 375;

export default ({ os }: { os: string }) => {
  const endOfReadingElem = document.getElementById('end-of-reading');

  if (endOfReadingElem) {
    endOfReadingElem.addEventListener('click', () => {
      window.portalGateToForeground.emit('go_to_next_doc_or_exit');
    });
  }

  // Button text fontSize fix (iOS only) - we need it because svg text is scaling down when screen is too small to fit whole button
  if (os !== 'ios') {
    return;
  }
  const windowWidth = window.screen.width;
  const maxBtnWidth = windowWidth - Spacing.baseHorizontalMargin * 2;
  const btnWidth = Math.min(BUTTON_DESIRED_WIDTH, maxBtnWidth);
  const btnScale = btnWidth / BUTTON_DESIRED_WIDTH;

  const fontScale = 1 / btnScale;

  const endOfReadingText = document.getElementById('end-of-reading-text');
  if (endOfReadingText) {
    const currentFontSize = window.getComputedStyle(endOfReadingText).fontSize;
    const currentFontSizeNum = parseInt(currentFontSize.replace('px', ''), 10);
    const newFontSize = Math.ceil(currentFontSizeNum * fontScale);

    endOfReadingText.style.fontSize = `${newFontSize}px`;
  }
};

export const animateEndOfReadingButton = (scrollFromBottom: number) => {
  const endOfReadingElem = document.getElementById('end-of-reading');
  if (!endOfReadingElem || endOfReadingElem.style.animationPlayState === 'running') {
    return;
  }
  if (scrollFromBottom < END_OF_READING_ANIMATE_THRESHOLD + END_OF_READING_ANIMATION_RANGE) {
    const animationStartPointDifference =
      END_OF_READING_ANIMATE_THRESHOLD + END_OF_READING_ANIMATION_RANGE - scrollFromBottom;
    const animationDelay = Math.min(animationStartPointDifference / END_OF_READING_ANIMATE_THRESHOLD, 1);
    endOfReadingElem.style.animationDelay = `-${animationDelay}s`;
  }
  if (scrollFromBottom < END_OF_READING_ANIMATION_RANGE) {
    window.portalGateToForeground.emit('haptics_feedback');
    endOfReadingElem.style.animationPlayState = 'running';
    window.portalGateToForeground.emit('play_sound', { sound: 'click-done' });
  }
};
