import forEach from 'lodash/forEach';
import isObject from 'lodash/isObject';
import type { JsonSchema, RxSchema } from 'rxdb';

import { SubObjectPathResult } from '../../types/database';

type InputParams = {
  currentPath?: string;
  data?: RxSchema | JsonSchema | JsonSchema[keyof JsonSchema];
  matcher: (data: JsonSchema | JsonSchema[keyof JsonSchema]) => boolean;
  memoizeKey: string;
};

type MemoizedResults = {
  [memoizeKey: string]: SubObjectPathResult[];
};

// We memoize the results of this function because it can be expensive & called a looot
const memoizedResults: MemoizedResults = {};

export default function lookUpSchemaSubObjectPaths(input: InputParams): SubObjectPathResult[] {
  if (!(input.memoizeKey in memoizedResults)) {
    memoizedResults[input.memoizeKey] = _lookUpSchemaSubObjectPaths(input);
  }

  return memoizedResults[input.memoizeKey];
}

function _lookUpSchemaSubObjectPaths(input: InputParams): SubObjectPathResult[] {
  const results: SubObjectPathResult[] = [];
  if (input.currentPath && input.matcher(input.data as JsonSchema)) {
    results.push({
      definition: input.data as SubObjectPathResult['definition'],
      path: input.currentPath,
    });
  }

  forEach(input.data, (value, keyRaw) => {
    const key = keyRaw.toString();
    if (key.startsWith('_') || !isObject(value)) {
      return;
    }

    const nextPath = [input.currentPath, key].filter(Boolean).join('.');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let nextValue: any = value;
    if (nextValue.type === 'array' && nextValue.items) {
      if (input.matcher(nextValue)) {
        results.push({
          definition: input.data,
          path: nextPath,
        });
      }
      nextValue = nextValue.items;
    } else if (nextValue.type === 'object' && nextValue.properties) {
      if (input.matcher(nextValue)) {
        results.push({
          definition: input.data,
          path: nextPath,
        });
      }
      nextValue = nextValue.properties;
    }

    const nestedResults = _lookUpSchemaSubObjectPaths({
      ...input,
      currentPath: [input.currentPath, key].filter(Boolean).join('.'),
      data: nextValue,
    });
    results.push(...nestedResults);
  });

  return results;
}
