import isObject from 'lodash/isObject';

import stringifySafe from './stringifySafe';

type TErrorClass<TError extends Error> = new (message: string) => TError;

/*
  Use this to create an `Error` instance from an `Error`-like object or even a string.
  If you give an object, additional abitrary properties are supported.

  You can create any `Error` using the optional `ErrorClass` parameter, i.e. anything that extends `Error` like custom
  `Error`s.
*/
export default function createError<TError extends Error = Error>(
  input: unknown,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ErrorClass?: TErrorClass<TError>,
): TError {
  const ErrorClassToUse = ErrorClass ?? (Error as unknown as TErrorClass<TError>);

  if (isObject(input)) {
    if (input instanceof ErrorClassToUse) {
      return input;
    }

    if ('message' in input && typeof input.message === 'string') {
      const error = new ErrorClassToUse(input.message);
      Object.assign(error, input); // Set `stack` or any abitrary properties
      return error;
    }
  }

  // NOTE: stack will be inaccurate
  return new ErrorClassToUse(stringifySafe(input));
}
