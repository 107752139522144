import { RssFolder, RssFolderOrItem, RssItem } from '../types';

export const isRssFolder = (itemOrFolder: RssFolderOrItem): itemOrFolder is RssFolder =>
  'childrenRssItems' in itemOrFolder;

export const isRssPinnedItem = (itemOrFolder: RssFolderOrItem): itemOrFolder is RssItem => {
  const keyThatOnlyExistsInRssItem: keyof RssItem = 'rssSourceId';
  return (
    keyThatOnlyExistsInRssItem in itemOrFolder &&
    typeof itemOrFolder[keyThatOnlyExistsInRssItem] === 'string'
  );
};

export const isRssFolderOrItem = (view: RssFolderOrItem): view is RssFolderOrItem =>
  isRssFolder(view) || isRssPinnedItem(view);

export const getFeedItemQueryFromFeedId = (feedId: string) => `rssSource:"${feedId}"`;

export const getFeedTrueAndFeedItemQueryFromFeedId = (feedId: string) =>
  `feed:true AND ${getFeedItemQueryFromFeedId(feedId)}`;
