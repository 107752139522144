import { Highlight } from '../../types';
import { HighlightElement } from '../types';
import Renderer from './Renderer';

export const getHighlightElement = ({
  container,
  id,
}: {
  container: Document | HTMLElement;
  id?: Highlight['id'];
}) => container.querySelector<HighlightElement>(Renderer.getHighlightElementSelector(id));

export default function getHighlightElements({
  container,
  id,
}: {
  container: Document | HTMLElement;
  id?: Highlight['id'];
}) {
  return Array.from(
    container.querySelectorAll<HighlightElement>(Renderer.getHighlightElementSelector(id)),
  );
}
