import capitalize from 'lodash/capitalize';

import type { RangyRange } from '../types/rangy';
import { isTextNode } from './locationSerializer';
import rangy from './rangy';

// NOTE: this mutates the input range
export default function contractRangeIfPossible({
  doc,
  endToMove = 'start',
  rangyRange,
}: {
  doc?: Document;
  endToMove: 'end' | 'start';
  rangyRange: RangyRange;
}): boolean {
  const otherRange = rangy.createRange(doc);
  const originals = {
    container: rangyRange[`${endToMove}Container`],
    offset: rangyRange[`${endToMove}Offset`],
  };

  if (rangyRange[`${endToMove}Offset`]) {
    const newOffset = rangyRange[`${endToMove}Offset`] + (endToMove === 'start' ? 1 : -1);
    const container = rangyRange[`${endToMove}Container`];

    // Validate
    if (
      newOffset < 0 ||
      newOffset > (isTextNode(container) ? container.length : container.childNodes.length) - 1
    ) {
      return false;
    }

    otherRange.setStart(rangyRange[`${endToMove}Container`], newOffset);
  } else {
    otherRange.setStartBefore(rangyRange[`${endToMove}Container`]);
  }

  otherRange.collapse(true);

  const oppositeEnd = endToMove === 'start' ? 'end' : 'start';
  rangyRange[`set${capitalize(endToMove)}`](
    otherRange[`${oppositeEnd}Container`],
    otherRange[`${oppositeEnd}Offset`],
  );

  return (
    !rangyRange[`${endToMove}Container`].isEqualNode(originals.container) ||
    rangyRange[`${endToMove}Offset`] !== originals.offset
  );
}
