import type { MaybePromise } from '../../../utils/typescriptUtils';
import type Database from '../../Database';
import logger from '../logger';
import prepareUpsertTransformers from './prepareUpsertTransformers';

export default async function addMiddleware(
  database: Database,
  additionalMiddleware?: (database: Database) => MaybePromise<void>,
) {
  if (!database.rxDbInstance) {
    throw new Error('database.rxDbInstance is falsy');
  }

  for (const collection of Object.values(database.rxDbInstance.collections)) {
    const collectionName = collection.name;
    const runUpsertTransformers = prepareUpsertTransformers(collection);

    collection.preInsert(runUpsertTransformers, true);
    collection.preSave(runUpsertTransformers, true);

    collection.postInsert(
      (plainData, rxDocument) => logger.debug('postInsert', { collectionName, plainData, rxDocument }),
      true,
    );
    collection.postRemove(
      (plainData, rxDocument) => logger.debug('postRemove', { collectionName, plainData, rxDocument }),
      true,
    );
    collection.postSave(
      (plainData, rxDocument) => logger.debug('postSave', { collectionName, plainData, rxDocument }),
      true,
    );
  }

  if (additionalMiddleware) {
    await additionalMiddleware(database);
  }
}
