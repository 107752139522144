import {
  NonSyncingChildDocumentProperties,
  NonSyncingParentDocumentProperties,
} from '../nonSyncingDocumentProperties';
import { AnyDocument, Category } from '../types';

// NOTE: this function mutates the document passed as a param. The reason for
//  this is that this function can be called a looot, so performance is important.
export default (doc: AnyDocument): void => {
  if ([Category.Highlight, Category.Note].includes(doc.category)) {
    NonSyncingChildDocumentProperties.forEach((prop) => {
      delete doc[prop];
    });
  } else {
    NonSyncingParentDocumentProperties.forEach((prop) => {
      delete doc[prop];
    });
  }
};
