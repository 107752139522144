// eslint-disable-next-line import/no-cycle
import request from './request';

class ReadwiseFetchResponseError extends Error {
  response: Awaited<ReturnType<typeof request>>;

  constructor(response: Awaited<ReturnType<typeof request>>) {
    super(`[${response.status}] ${response.url}`);
    this.response = response;
  }
}

export class ReadwiseFetchClientError extends ReadwiseFetchResponseError {
  name = 'ReadwiseFetchClientError';
}

export class ReadwiseFetchNetworkConnectivityError extends Error {
  name = 'ReadwiseFetchNetworkConnectivityError';
}

export class ReadwiseFetchServerError extends ReadwiseFetchResponseError {
  name = 'ReadwiseFetchServerError';
}
