// eslint-disable-next-line import/no-cycle
import { updateState } from '../../models';

export const setAreAllDatabaseHooksDisabled = async (areAllHooksDisabled: boolean): Promise<void> => {
  await updateState(
    (state) => {
      state.areAllDatabaseHooksDisabled = areAllHooksDisabled;
    },
    {
      eventName: 'areAllDatabaseHooksDisabled-updated',
      shouldCreateUserEvent: false,
      userInteraction: 'unknown',
      isUndoable: false,
    },
  );
};
