import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { ReadwiseFetchServerError } from '../../utils/Errors';
import { PromptScope } from '../ghostreaderLegacy/types';
import { globalState } from '../models';
import { PromptModel, PromptScopeType, PromptsVersion } from './constants';
import { IndexedScope, OverriddenPrompts, Prompts, Prompts1, Prompts2 } from './types';

export function canUseOpenAIModel(model: PromptModel) {
  const hasOpenAIApiKey = globalState((state) => Boolean(state.persistent.settings.openai?.apiKey));
  return model === PromptModel.GPT_35_TURBO || hasOpenAIApiKey;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function isPromptsFormat1(prompts: Prompts): prompts is Prompts1 {
  return Array.isArray(prompts);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function isPromptsFormat2(prompts: Prompts): prompts is Prompts2 {
  return Object.hasOwn(prompts, 'version') && (prompts as Prompts2).version === PromptsVersion.V2;
}

export function migratePromptsFormat(prompts: Prompts) {
  if (isPromptsFormat1(prompts)) {
    return {
      version: PromptsVersion.V2,
      data: [
        {
          type: PromptScopeType.Automatic,
          title: 'Automatic prompts',
          description: 'Applies to newly saved documents automatically',
          prompts: prompts.map((prompt) => ({
            type: prompt.type,
            title: prompt.title,
            description: prompt.description,
            model: prompt.model,
            system: prompt.system,
            template: prompt.template,
            isCustomizable: true,
          })),
        },
        {
          type: PromptScopeType.DocumentSpecial,
          title: 'Special document level prompts',
          description: 'Applies special metadata to the document',
          prompts: prompts.map((prompt) => ({
            type: prompt.type,
            title: prompt.title,
            description: prompt.description,
            model: prompt.model,
            system: prompt.system,
            template: prompt.template,
            isCustomizable: false,
          })),
        },
      ],
    } as Prompts2;
  }

  if (isPromptsFormat2(prompts)) {
    return cloneDeep(prompts);
  }

  throw new Error('Prompts are malformed or provided in an unsupported format.');
}

export function migrateOverriddenPromptsFormat(prompts: OverriddenPrompts) {
  for (const [overriddenPromptScope, overriddenScopePrompts] of Object.entries(prompts)) {
    // only pick the minimum number of properties, we can override most with defaults
    const filteredOverriddenScopePrompts = {};
    // eslint-disable-next-line guard-for-in
    for (const promptKey in overriddenScopePrompts) {
      filteredOverriddenScopePrompts[promptKey] = pick(
        overriddenScopePrompts[promptKey],
        'model',
        'template',
      );
    }

    if (overriddenPromptScope === PromptScopeType.Document) {
      prompts[PromptScopeType.Automatic] = filteredOverriddenScopePrompts;
      prompts[PromptScopeType.DocumentSpecial] = filteredOverriddenScopePrompts;
      delete prompts[PromptScope.Document];
    }
  }

  return prompts;
}

/**
 * Map the prompt scopes and prompts into a data structure that can be accessed
 * in a fast way (type-based retrieval).
 *
 * @param prompts
 */
export function indexPrompts(prompts: Prompts2) {
  const index = new Map<PromptScopeType, IndexedScope>();

  for (const scope of prompts.data) {
    if (!index.has(scope.type)) {
      index.set(scope.type, {
        ...omit(scope, 'prompts'),
        prompts: new Map(),
      });
    }
    const indexedScope = index.get(scope.type)!;

    for (const prompt of scope.prompts) {
      indexedScope.prompts.set(prompt.type, prompt);
    }
  }

  return index;
}

/**
 * Normalize the default and user-customized prompts into a standard (latest)
 * format, and index them to make them accessible for application code.
 *
 * @param defaultPrompts
 * @param overriddenPrompts
 */
export function transformPrompts(defaultPrompts: Prompts, overriddenPrompts?: OverriddenPrompts) {
  const normalizedDefaultPrompts = migratePromptsFormat(defaultPrompts);
  const overriddenPromptsVersion = globalState(
    (state) => state.persistent.settings.overriddenPromptsVersion,
  );

  // if version is not set, we need to migrate the overridden prompts from v1 to v2
  const normalizedOverriddenPrompts = overriddenPrompts ? cloneDeep(overriddenPrompts) : undefined;
  if (normalizedOverriddenPrompts && overriddenPromptsVersion !== PromptsVersion.V2) {
    migrateOverriddenPromptsFormat(normalizedOverriddenPrompts);
  }

  // merge overridden prompts
  if (normalizedOverriddenPrompts) {
    for (const promptScope of normalizedDefaultPrompts.data) {
      if (normalizedOverriddenPrompts[promptScope.type]) {
        for (const prompt of promptScope.prompts) {
          if (normalizedOverriddenPrompts[promptScope.type][prompt.type]) {
            assign(prompt, normalizedOverriddenPrompts[promptScope.type][prompt.type]);
            prompt.isDefaultPrompt = false;
          }
        }
      }
    }
  }

  return indexPrompts(normalizedDefaultPrompts);
}

export function getModelLabel(model: PromptModel) {
  switch (model) {
    case PromptModel.GPT_4:
      return 'GPT-4';
    case PromptModel.GPT_4_TURBO:
      return 'GPT-4 Turbo';
    case PromptModel.GPT_4o:
      return 'GPT-4o';
    default:
      return 'GPT-3.5 Turbo';
  }
}

export function errorIncludesResponse(error: unknown): error is ReadwiseFetchServerError {
  return isObject(error) && Object.hasOwn(error, 'response');
}
