import type { AnyDocument } from '../types';
import type { DocumentTag } from '../types/tags';
import asArray from './asArray';
import { cleanAndValidateTagName, cleanUpTagName, cleanUpTagNames } from './cleanAndValidateTagName';
import nowTimestamp from './dates/nowTimestamp';

export const addTagToDocObject = (
  doc: AnyDocument,
  tagName: string,
  type: DocumentTag['type'] = 'manual',
) => {
  const { cleanTagName, validationError } = cleanAndValidateTagName(tagName);
  if (validationError) {
    throw validationError;
  }
  const tagKey = cleanTagName.toLowerCase();

  if (!doc.tags) {
    doc.tags = {};
  }

  if (hasTag(doc.tags, tagName)) {
    return;
  }

  doc.tags[tagKey] = {
    created: nowTimestamp(),
    name: cleanTagName,
    type,
  };
};

export function areTagNamesEqual(a: DocumentTag['name'], b: DocumentTag['name']): boolean {
  return cleanUpTagName(a) === cleanUpTagName(b);
}

// See `keepTagNamesThatExist` comment
export function hasAnyTag(
  documentTags: AnyDocument['tags'] | DocumentTag[],
  tagNames: DocumentTag['name'][],
): boolean {
  return keepTagNamesThatExist(documentTags, tagNames).length > 0;
}

// See `keepTagNamesThatExist` comment
export function hasTag(
  documentTags: AnyDocument['tags'] | DocumentTag[],
  tagName: DocumentTag['name'],
): boolean {
  return keepTagNamesThatExist(documentTags, [tagName]).length > 0;
}

// See `keepTagNamesThatExist` comment
export function keepTagNamesThatDontExist(
  documentTags: AnyDocument['tags'] | DocumentTag[],
  tagNames: DocumentTag['name'][],
): DocumentTag['name'][] {
  const tagNamesThatExist = keepTagNamesThatExist(documentTags, tagNames);
  const cleanTagNames = cleanUpTagNames(tagNames);
  if (!tagNamesThatExist.length) {
    return cleanTagNames;
  }
  return cleanTagNames.filter((tagName) => !tagNamesThatExist.includes(tagName));
}

/*
  The `documentTags` parameter is assumed to be clean tags (which would be the case if it's a real document from the
  database).
  The comparison/matching is case-sensitive by default.
  The resultant tag names will have been cleaned.
*/
export function keepTagNamesThatExist(
  documentTags: AnyDocument['tags'] | DocumentTag[],
  tagNames: DocumentTag['name'][],
  caseSensitivity: 'case-insensitive' | 'case-sensitive' = 'case-sensitive',
): DocumentTag['name'][] {
  if (!documentTags) {
    return [];
  }

  const cleanTagNames = tagNames.map((tagName) => cleanUpTagName(tagName));
  const tagNamesThatExist: { [key: DocumentTag['name']]: true } = {};

  for (const documentTag of asArray(documentTags)) {
    const cleanDocumentTagName = cleanUpTagName(documentTag.name);
    for (const cleanTagName of cleanTagNames) {
      if (
        caseSensitivity === 'case-sensitive'
          ? cleanTagName === cleanDocumentTagName
          : cleanTagName.toLowerCase() === cleanDocumentTagName.toLowerCase()
      ) {
        tagNamesThatExist[cleanTagName] = true;
      }
    }
  }

  return Object.keys(tagNamesThatExist);
}

export const removeTagFromDocObject = (doc: AnyDocument, tagName: string) => {
  if (!doc.tags) {
    return;
  }

  // Why case-insensitive? See comment at `DocumentTagsObject` definition
  const tagNamesToRemove = keepTagNamesThatExist(
    doc.tags,
    [tagName, cleanUpTagName(tagName)],
    'case-insensitive',
  );
  for (const tagNameToRemove of tagNamesToRemove) {
    delete doc.tags[tagNameToRemove.toLowerCase()];
  }
};
