// eslint-disable-next-line import/no-cycle
import { getScrollingManager } from './getScrollingManager';
import type { MobileContentFrameWindow } from './types';

declare let window: MobileContentFrameWindow;

interface SearchOccurrence {
  firstIndex: number;
  firstNode: Node;
  lastIndex: number;
  lastNode: Node;
}

let docContent: Element | null;
let nodeList: Node[] = [];
let occurrencesList: SearchOccurrence[] = [];
let searchText = '';
let searchHighlightersCollection: HTMLCollection;

const getMatchesNumber = (text: string, regExp: RegExp) => (text.match(regExp) ?? []).length;

export const clearSearch = () => {
  nodeList = [];
  occurrencesList = [];
  clearSearchHighlights();
};

export const search = (text: string) => {
  searchText = text;
  if (searchText.length === 0) {
    window.portalGateToForeground.emit('search-word-found', -1);
    return;
  }

  searchHighlightersCollection = document.getElementsByClassName('search-highlighter');
  clearSearch();
  docContent = document.getElementById('document-text-content');
  if (!docContent) {
    throw new Error('No document-text-content');
  }

  const treeWalker = document.createTreeWalker(docContent, NodeFilter.SHOW_TEXT, {
    acceptNode(node) {
      return node.parentElement?.tagName === 'STYLE'
        ? NodeFilter.FILTER_REJECT
        : NodeFilter.FILTER_ACCEPT;
    },
  });

  const searchRegExp = new RegExp(`(${searchText})`, 'gi');

  const textContent = treeWalker.currentNode.textContent || '';
  const Occurrences = (textContent.match(searchRegExp) ?? []).length;
  window.portalGateToForeground.emit('search-result', Occurrences);

  treeWalker.nextNode();

  nodeList = [];
  occurrencesList = [];
  let currentNode: Node | null = treeWalker.currentNode;
  let textContentAccumulator = '';
  let firstMatchFound = false;

  while (currentNode) {
    nodeList.push(currentNode);
    if (currentNode.textContent) {
      textContentAccumulator = `${textContentAccumulator}${currentNode.textContent}`;
      const nodeListCurrentIndex = nodeList.length - 1;
      while (searchRegExp.exec(currentNode.textContent) !== null) {
        const firstIndex = searchRegExp.lastIndex - searchText.length;
        const Occurrence: SearchOccurrence = {
          firstIndex,
          lastIndex: searchRegExp.lastIndex,
          firstNode: currentNode,
          lastNode: currentNode,
        };
        occurrencesList.push(Occurrence);
      }
      const OccurrencesFoundUntilNow = (textContentAccumulator.match(searchRegExp) ?? []).length;
      if (OccurrencesFoundUntilNow !== occurrencesList.length) {
        // Case where search text is split across multiple text nodes
        const consideredNodes = [];
        let regularMatchesCounter = 0;
        let totalMatches = 0;
        let tailNodesAccumulator = '';
        let nodeListIndex = nodeListCurrentIndex;

        do {
          const lastNode = nodeList[nodeListIndex];
          if (lastNode.textContent) {
            consideredNodes.push(lastNode);
            const matchesInLastNode = getMatchesNumber(lastNode.textContent, searchRegExp);
            regularMatchesCounter += matchesInLastNode;
            tailNodesAccumulator = `${lastNode.textContent}${tailNodesAccumulator}`;
            totalMatches = getMatchesNumber(tailNodesAccumulator, searchRegExp);
          }
          nodeListIndex--;
        } while (nodeListIndex > 0 && totalMatches === regularMatchesCounter);
        consideredNodes.reverse();
        const firstConsideredNode = consideredNodes[0];
        const numOfMatchesInFirstNode = getMatchesNumber(
          firstConsideredNode.textContent || '',
          searchRegExp,
        );
        for (let i = 0; i <= numOfMatchesInFirstNode; i++) {
          if (searchRegExp.exec(tailNodesAccumulator) == null) {
            break;
          }
        }
        const matchFirstIndex = searchRegExp.lastIndex - searchText.length;
        const lastConsideredNode = consideredNodes[consideredNodes.length - 1];
        const offsetFromEndOfText = tailNodesAccumulator.length - searchRegExp.lastIndex;
        const matchLastIndex = (lastConsideredNode.textContent || '').length - offsetFromEndOfText;

        const Occurrence: SearchOccurrence = {
          firstIndex: matchFirstIndex,
          firstNode: firstConsideredNode,
          lastIndex: matchLastIndex,
          lastNode: lastConsideredNode,
        };
        const OccurrencesFoundInCurrentNode = getMatchesNumber(
          currentNode.textContent || '',
          searchRegExp,
        );
        const exptectedOccurrenceIndex = occurrencesList.length - OccurrencesFoundInCurrentNode;
        occurrencesList.splice(exptectedOccurrenceIndex, 0, Occurrence);
      }
    }
    if (!firstMatchFound && occurrencesList.length > 0) {
      findElem(0);
      firstMatchFound = true;
    }
    currentNode = treeWalker.nextNode();
  }

  if (occurrencesList.length === 0) {
    window.portalGateToForeground.emit('search-word-found', -1);
  }
  window.portalGateToForeground.emit('search-result', occurrencesList.length);
};

const findElem = (index: number) => {
  const currentOccurrence = occurrencesList[index];
  const range = new Range();
  range.setStart(currentOccurrence.firstNode, 0);
  range.collapse(true);
  range.setStart(currentOccurrence.firstNode, currentOccurrence.firstIndex);
  range.setEnd(currentOccurrence.lastNode, currentOccurrence.lastIndex);

  highlightOccurrence(range);
  window.portalGateToForeground.emit('search-word-found', index);
};

export const findAgain = (index: number) => {
  if (!occurrencesList) {
    return;
  }
  findElem(index);
};

export const findNext = (index: number) => {
  if (!occurrencesList) {
    return;
  }
  const expectedNextIndex = index + 1;
  const hasNext = occurrencesList.length > expectedNextIndex;
  const nextIndex = hasNext ? expectedNextIndex : 0;

  findElem(nextIndex);
};

export const findPrev = (index: number) => {
  if (!occurrencesList) {
    return;
  }

  const expectedNextIndex = index - 1;
  const hasPrev = expectedNextIndex >= 0;
  const prevIndex = hasPrev ? expectedNextIndex : occurrencesList.length - 1;

  findElem(prevIndex);
};

const clearSearchHighlights = () => {
  if (searchHighlightersCollection) {
    Array.from(searchHighlightersCollection).forEach((searchHighlighter) => {
      searchHighlighter.remove();
    });
  }
};

const highlightOccurrence = (range: Range) => {
  clearSearchHighlights();

  const rects = range.getClientRects();
  if (!window.scrollingManager) {
    return;
  }
  const scrollableRootTop = window.scrollingManager.getScrollingElementTop();

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < rects.length; i++) {
    const rect = rects[i];

    const searchHighlighter = document.createElement('div');
    searchHighlighter.classList.add('search-highlighter');
    searchHighlighter.classList.remove('search-highlighter-pop');
    document.body.appendChild(searchHighlighter);

    const posTop = Math.floor(scrollableRootTop + rect.top);
    const width = rect.width;
    const left = rect.left;
    const height = rect.height;

    if (searchHighlighter) {
      searchHighlighter.style.top = `${posTop}px`;
      searchHighlighter.style.height = `${height}px`;
      searchHighlighter.style.width = `${width}px`;
      searchHighlighter.style.left = `${left - 2}px`;
      searchHighlighter.classList.add('search-highlighter-pop');
    }
  }

  const scrollingManager = getScrollingManager();
  // -300 so that we don't set the scroll position right at the top of the screen
  // disabled in pagination because we want to scroll to the accurate page

  const offset = window.pagination?.enabled ? 0 : -300;
  scrollingManager.scrollToRect(rects[0], offset);
};
