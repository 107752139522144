import type {
  CompressionTable,
  JsonSchema as KeyCompressionJsonSchema,
} from 'jsonschema-key-compression';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  compressedPath,
  createCompressionTable,
  DEFAULT_COMPRESSION_FLAG,
} from 'jsonschema-key-compression';
import { getPrimaryFieldOfPrimaryKey, RxJsonSchema } from 'rxdb';
import { flatClone } from 'rxdb/plugins/utils';

export function getIndexName(index: string | ReadonlyArray<string>) {
  return Array.isArray(index) ? index.join(',') : (index as string);
}

export function getStorageIndexName(index: string | ReadonlyArray<string>) {
  const parts = Array.isArray(index) ? Array.from(index) : [index];
  if (parts.length > 0 && parts[0] !== '_deleted') {
    parts.unshift('_deleted');
  }

  return parts.join('||');
}

export function getFilteredIndexes(
  compression: boolean,
  schema: RxJsonSchema<never>,
  wrappedStorageSchema: RxJsonSchema<never>,
  wrappedStorageIndexes: string[] | string[][],
) {
  /**
   * Simple case: There is no compression. We can just filter out indices that
   * aren't defined in the list of indices in the uncompressed schema.
   *
   * Complex case: When the schema is compressed, we need to compress the
   * override indices for the wrapped storage ourselves. We can only do that
   * with access to the original uncompressed schema. We use it to create a
   * compression table that returns the same values as present in the
   * compressed schema.
   */
  if (!compression) {
    const wrappedStorageIndexLookup: Set<string> = new Set(wrappedStorageIndexes.map(getIndexName));
    return schema.indexes?.filter((index) => wrappedStorageIndexLookup.has(getIndexName(index)));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const compressionSchema: KeyCompressionJsonSchema = flatClone(wrappedStorageSchema) as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete (compressionSchema as any).primaryKey;

  const table = createCompressionTable(compressionSchema, DEFAULT_COMPRESSION_FLAG, [
    getPrimaryFieldOfPrimaryKey(wrappedStorageSchema.primaryKey),
    '_rev',
    '_attachments',
    '_deleted',
    '_meta',
  ]);

  const wrappedStorageIndexLookup: Set<string> = new Set(
    wrappedStorageIndexes.map((index) => {
      const compressedIndex = compressIndex(table, index);
      return getIndexName(compressedIndex);
    }),
  );
  return schema.indexes?.filter((index) => wrappedStorageIndexLookup.has(getIndexName(index)));
}

function compressIndex(table: CompressionTable, index: string | ReadonlyArray<string>) {
  if (Array.isArray(index)) {
    // eslint-disable-next-line no-console
    return index.map((subIndex) => compressedPath(table, subIndex));
  } else {
    // eslint-disable-next-line no-console
    return compressedPath(table, index as string);
  }
}
