import { useMemo } from 'react';
import { EqualityChecker, StateSelector } from 'zustand';

import { FullZustandState } from '../../types';
// eslint-disable-next-line import/no-cycle
import { globalState } from '../models';
import useLiveValueRef from './useLiveValueRef';

export default function useGlobalStateWithFallback<T = unknown>(
  fallbackValue: T,
  selector: StateSelector<FullZustandState, T | undefined>,
  equalityFn?: EqualityChecker<T | undefined> | undefined,
): NonNullable<T> {
  if (!fallbackValue) {
    throw new Error('fallbackValue must be truthy, otherwise use globalState');
  }

  const valueFromState = globalState<T | undefined>(selector, equalityFn);
  const fallbackValueRef = useLiveValueRef(fallbackValue);
  return useMemo(
    () => (valueFromState ?? fallbackValueRef.current) as NonNullable<T>,
    [fallbackValueRef, valueFromState],
  );
}
