import makeLogger from './makeLogger';

const logger = makeLogger(__filename);

const replaceStringStrict = (input: string, lookup: string | RegExp, replaceValue: string) => {
  const result = input.replace(lookup, replaceValue);
  if (result === input) {
    const message = 'String replace had no effect';
    logger.error(message, { input, lookup, replaceValue });
    throw new Error(message);
  }
  return result;
};

export default replaceStringStrict;
