import type { RxCollection } from 'rxdb';

import { AnyDocument, Category, DocumentLocation, RxDBOnlyIndexFields } from '../../../../types';
import type { DatabaseUpsertTransformer } from '../../../../types/database';
import { isYouTubeUrl } from '../../../../typeValidators';
import { generateRandomInteger } from '../../../../utils/generateRandomNumber';
import { GUTENBERG_OFFSET, MAX_TIMESTAMP, MIN_TIMESTAMP } from '../../defineSchema/commonDefinitions';
import logger from '../../logger';

function getStringForSort(value?: string, maxLength = 20) {
  if (!value || value.length === 0) {
    return '';
  }
  return value.toLowerCase().substring(0, maxLength);
}

function getTimestampForDescSort(value?: number) {
  const result = MAX_TIMESTAMP - (value ?? MIN_TIMESTAMP);
  return Math.max(result, MIN_TIMESTAMP);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getOverrideOrReal(data: any, key: string) {
  const overrideValue = data.overrides?.[key];
  const value = data[key];
  return overrideValue ?? value;
}

function getTimestampForSort(value?: number) {
  return value ?? MIN_TIMESTAMP;
}

function computeIndexFields(doc: AnyDocument): RxDBOnlyIndexFields {
  return {
    author: getStringForSort(getOverrideOrReal(doc, 'author')),
    category: getStringForSort(getOverrideOrReal(doc, 'category')),
    childrenCount: Array.isArray(doc.children) ? doc.children.length : 0,
    firstOpenedAtExists: Boolean(doc.firstOpenedAt),
    hasHighlights: Array.isArray(doc.children) && doc.children.length > 0,
    hasShortlistTag: Boolean(typeof doc.tags === 'object' && 'shortlist' in doc.tags),
    isNewOrLater:
      doc.triage_status === DocumentLocation.New || doc.triage_status === DocumentLocation.Later,
    last_status_update: getTimestampForSort(doc.last_status_update),
    last_status_update_desc: getTimestampForDescSort(doc.last_status_update),
    lastOpenedAt: getTimestampForSort(doc.lastOpenedAt as number),
    length_in_seconds: Math.max(
      0,
      Math.round(
        doc.category === Category.Video || (doc.url && isYouTubeUrl(doc.url))
          ? doc.listening_time_seconds ?? 0
          : ((doc.word_count ?? 0) / 265) * 60,
      ),
    ),
    parsed_doc_id: doc.parsed_doc_id ? Number(doc.parsed_doc_id) : Number.MAX_SAFE_INTEGER,
    published_date: Math.max(0, (doc.published_date ?? 0) + GUTENBERG_OFFSET),
    randomNumber1: generateRandomInteger(0, 9),
    randomNumber2: generateRandomInteger(0, 9),
    randomNumber3: generateRandomInteger(0, 9),
    randomNumber4: generateRandomInteger(0, 9),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    readingPosition_scrollDepth: Math.round((doc.readingPosition?.scrollDepth ?? 0) * 100),
    saved_at: getTimestampForSort(doc.saved_at),
    saved_at_desc: getTimestampForDescSort(doc.saved_at),
    savedCount: Array.isArray(doc.saved_at_history) ? doc.saved_at_history.length : 1,

    /*
     * Calculate a computed field that combines lastSeenStatusUpdateAt with firstOpenedAtExists to prevent a potentially
     * expensive $or query for feed Unseen/Seen split views.
     *
     * Unseen:
     * $or: [
     *  { firstOpenedAtExists: false },
     *  { lastSeenStatusUpdateAt: { $gt: seenStatusChangedAtThreshold }}
     * ]
     * becomes
     * 'rxdbOnly.indexFields.showInUnseenAfter': { $gt: seenStatusChangedAtThreshold }
     *
     * Seen:
     * $or: [
     *  { firstOpenedAtExists: true },
     *  { lastSeenStatusUpdateAt: { $gt: seenStatusChangedAtThreshold }}
     * ]
     * becomes
     * 'rxdbOnly.indexFields.showInSeenAfter': { $gt: seenStatusChangedAtThreshold }
     */
    showInUnseenAfter: !doc.firstOpenedAt ? MAX_TIMESTAMP : doc.lastSeenStatusUpdateAt ?? MIN_TIMESTAMP,
    showInSeenAfter: doc.firstOpenedAt ? MAX_TIMESTAMP : doc.lastSeenStatusUpdateAt ?? MIN_TIMESTAMP,
    startedReading: Boolean(doc.readingPosition?.scrollDepth && doc.readingPosition.scrollDepth > 0.05),
    title: getStringForSort(getOverrideOrReal(doc, 'title'), 350),
    triage_status_exists: Boolean(doc.triage_status),
    // NOTE: triage_status_is_feed is also false if triage_status doesn't exist i.e. this is a highlight or a note.
    // if you want to query non-feed first class docs, combine this boolean selector with triage_status_exists.
    triage_status_is_feed: doc.triage_status === DocumentLocation.Feed,
  };
}

// The purpose of this transformer is to allow us doing:
// - case-insensitive sorting
// - sort by values that can be overriden
// - sort by random number
// More info: https://linear.app/readwise/issue/RW-31361/support-case-insensitive-sort
export default function getComputedForRxDBOnlyTransformer(
  collection: RxCollection,
): DatabaseUpsertTransformer {
  logger.debug('[upsertTransformers] getComputedForRxDBOnlyTransformer', {
    collectionName: collection.name,
  });

  const isDocumentsCollection = collection.name === 'documents';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function computedForRxDBOnlyTransformer(plainData: any) {
    if (!isDocumentsCollection) {
      return;
    }

    plainData.rxdbOnly ??= {};

    plainData.rxdbOnly.allTagsJoined = `|||${Object.keys(plainData.tags ?? {}).join('|||')}|||`;

    // computed index fields
    plainData.rxdbOnly.indexFields = computeIndexFields(plainData as AnyDocument);
  };
}
