import shuffleArray from 'lodash/shuffle';
import type { MangoQuery } from 'rxdb';
import type { MangoQuerySortPart } from 'rxdb/dist/types/types';

import { AnyDocument, SortOrder } from '../types';
import { isUsingSQLite } from '../utils/environment';
import { generateRandomInteger } from '../utils/generateRandomNumber';
import mangoQueryBuilders from './mangoQueryBuilders';
import { documentDoesntHaveAnnotationsQuery } from './standardQueries';

export function makeDocsWithTagNameQuery(tagName: string, exists = 1) {
  const tagNameTrimmed = tagName.trim();
  const tagKey = tagNameTrimmed.toLowerCase();

  if (isUsingSQLite) {
    return {
      selector: {
        // To work around SQLite's JSON path issues, we should use quotes around
        // key names that include special characters or follow unconventional
        // naming patterns (like having square brackets in the name).
        // This tells SQLite to treat the enclosed string as a literal key name,
        // including any special characters it contains.
        [`tags."${tagKey}"`]: {
          $exists: exists,
        },
      },
    };
  }

  if (tagNameTrimmed === '' || tagNameTrimmed.includes('.')) {
    // special cases that mess up JSON path, so we need to fall back to regex.
    // note that this is significantly slower, so we use it sparingly. however it is always safe, unlike the $exists query.
    // exact match, so surround with '|||' separators.
    const regexSelector = makeRegexSelector(`|||${tagNameTrimmed}|||`);

    if (exists === 0) {
      return {
        selector: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'rxdbOnly.allTagsJoined': {
            $not: regexSelector,
          },
        },
      };
    }

    return {
      selector: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'rxdbOnly.allTagsJoined': regexSelector,
      },
    };
  }

  return {
    selector: {
      [`tags.${tagKey}`]: {
        $exists: exists,
      },
    },
  };
}

export function makeRandomSortRules(): MangoQuerySortPart<AnyDocument>[] {
  const mangoQuerySortRules: MangoQuerySortPart<AnyDocument>[] = [];
  const randomNumbersColumns = shuffleArray([
    'randomNumber1',
    'randomNumber2',
    'randomNumber3',
    'randomNumber4',
  ]);
  randomNumbersColumns.forEach((randomNumbersColumn) => {
    const randomOrder = generateRandomInteger(0, 1) === 0 ? SortOrder.Asc : SortOrder.Desc;
    mangoQuerySortRules.push({ [`rxdbOnly.indexFields.${randomNumbersColumn}`]: randomOrder });
  });
  return mangoQuerySortRules;
}

export function excludeDocsWithAnnotations(query: MangoQuery<AnyDocument>): MangoQuery<AnyDocument> {
  return {
    ...query,
    selector: mangoQueryBuilders.$and([query.selector, documentDoesntHaveAnnotationsQuery]),
  };
}

export function makeRegexSelector(searchValue: string) {
  // Escape special characters
  const regexValue = searchValue.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  return {
    $regex: regexValue,
    $options: 'i',
  };
}
