// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { getTauriVersion, getVersion as getDesktopAppVersion } from '@tauri-apps/api/app';
import type { Arch, OsType, Platform } from '@tauri-apps/plugin-os';
import * as DesktopOS from '@tauri-apps/plugin-os';
import { useEffect, useState } from 'react';

import { isDesktopApp } from './environment';

export type DesktopAppInfo = {
  appVersion: string;
  tauriVersion: string;
  platform: Platform;
  osType: OsType;
  arch: Arch;
};

let cachedDesktopAppInfo: DesktopAppInfo | undefined;

async function _loadDesktopAppInfo(): Promise<DesktopAppInfo> {
  if (!isDesktopApp) {
    throw new Error('Cannot get desktop app info unless in desktop app');
  }
  const [appVersion, tauriVersion, platform, osType, arch] = await Promise.all([
    getDesktopAppVersion(),
    getTauriVersion(),
    DesktopOS.platform(),
    DesktopOS.type(),
    DesktopOS.arch(),
  ]);
  return {
    appVersion,
    tauriVersion,
    platform,
    osType,
    arch,
  };
}

export async function getDesktopAppInfo(): Promise<DesktopAppInfo> {
  if (!cachedDesktopAppInfo) {
    const desktopAppInfo = await _loadDesktopAppInfo();
    if (!cachedDesktopAppInfo) {
      cachedDesktopAppInfo = desktopAppInfo;
    }
  }
  return cachedDesktopAppInfo;
}

// Returns undefined if we're not inside the desktop app.
export function useDesktopAppInfo(): DesktopAppInfo | undefined {
  const [desktopAppInfo, setDesktopAppInfo] = useState<DesktopAppInfo | undefined>(cachedDesktopAppInfo);
  useEffect(() => {
    if (!isDesktopApp) {
      return;
    }
    if (cachedDesktopAppInfo) {
      return;
    }
    (async () => {
      setDesktopAppInfo(await getDesktopAppInfo());
    })();
  }, []);
  return desktopAppInfo;
}
