// eslint-disable-next-line import/no-cycle
import { portalGate as portalGateToForeground } from '../../../shared/foreground/portalGates/contentFrame/from/reactNativeWebview';
import delay from '../../../shared/utils/delay';
import { isURLToImage } from './setupAContainingImg';
import { getATagAncestor } from './setupLinks';
import type { MobileContentFrameWindow } from './types';

declare let window: MobileContentFrameWindow;

enum ImageHighlightState {
  NotHighlight = 'NotHighlight',
  Highlight = 'Highlight',
  ActiveHighlight = 'ActiveHighlight',
}

const getImageHighlightState = (img: HTMLImageElement): ImageHighlightState => {
  if (
    img.classList.contains('rw-image-highlight') &&
    img.classList.contains('rw-image-highlight--active')
  ) {
    return ImageHighlightState.ActiveHighlight;
  }
  if (img.classList.contains('rw-image-highlight')) {
    return ImageHighlightState.Highlight;
  }
  return ImageHighlightState.NotHighlight;
};

export const selectImageElementToHighlight = async (img: HTMLImageElement) => {
  const windowSelection = window.getSelection();
  if (windowSelection === null) {
    throw new Error('window.getSelection() returned null');
  }
  windowSelection.removeAllRanges();

  const imgRange = new Range();
  imgRange.setStartBefore(img);
  imgRange.setEndAfter(img);
  windowSelection.addRange(imgRange);

  await delay(50);
};

export default ({ os }: { os: string }) => {
  const longPressMaxDifference = os === 'ios' ? 10 : 5;

  let presstimer: ReturnType<typeof setTimeout> | null = null;
  let doubleTapTimer: ReturnType<typeof setTimeout> | null = null;
  // we need this variable to save current highlight state before any touch event is applied to get reliable value
  let currentImageHighlightState: ImageHighlightState | null = null;
  let longPressStartX = 0;
  let longPressStartY = 0;

  return function imagePressingInitializer() {
    const images = document.querySelectorAll<HTMLImageElement>('#document-text-content img');
    images.forEach((img, i) => {
      const imageId = `img-index-${i}`;
      img.id = imageId;

      img.addEventListener(
        'touchstart',
        (e) => {
          if (currentImageHighlightState === null) {
            currentImageHighlightState = getImageHighlightState(img);
          }

          if (presstimer === null) {
            const touch = e.changedTouches[0];
            longPressStartX = touch.pageX;
            longPressStartY = touch.pageY;

            presstimer = setTimeout(() => {
              // Long press event
              presstimer = null;

              const imageHighlightState = currentImageHighlightState;
              currentImageHighlightState = null;
              if (imageHighlightState === ImageHighlightState.NotHighlight) {
                selectImageElementToHighlight(img);
                portalGateToForeground.emit('highlight-image');
                return;
              }
              if (imageHighlightState === ImageHighlightState.Highlight) {
                const highlightId = img.getAttribute('data-highlight-id');
                portalGateToForeground.emit('highlight-clicked', {
                  id: highlightId,
                });
                return;
              }
              if (imageHighlightState === ImageHighlightState.ActiveHighlight) {
                portalGateToForeground.emit('image_clicked', {
                  src: img.src,
                  id: imageId,
                  highlighted: true,
                });
              }
            }, 300);
          }
        },
        { passive: true },
      );

      const onDoubleClick = () => {
        const imageHighlightState = currentImageHighlightState;
        currentImageHighlightState = null;
        if (imageHighlightState === ImageHighlightState.NotHighlight) {
          selectImageElementToHighlight(img);
          portalGateToForeground.emit('highlight-image');
          return;
        }
        if (imageHighlightState === ImageHighlightState.Highlight) {
          const highlightId = img.getAttribute('data-highlight-id');
          portalGateToForeground.emit('highlight-clicked', {
            id: highlightId,
          });
          return;
        }
        if (imageHighlightState === ImageHighlightState.ActiveHighlight) {
          const highlightId = img.getAttribute('data-highlight-id');
          portalGateToForeground.emit('highlight-double-clicked', {
            id: highlightId,
          });
        }
      };

      img.addEventListener(
        'touchend',
        (e) => {
          if (!window.pagination?.enabled) {
            // TODO: fix. This broke double-tap image highlighting; https://linear.app/readwise/issue/RW-34910/bug-image-highlighting-behavior-regressed-to-be-more-difficult
            // e.stopPropagation();
          }
          if (window.pagination?.enabled) {
            const { screenX } = e.changedTouches[0];
            if (
              screenX <= window.pagination.leftClickAreaWidth ||
              screenX >= window.pagination.rightClickAreaWidth
            ) {
              if (doubleTapTimer) {
                clearTimeout(doubleTapTimer);
              }
              if (presstimer) {
                clearTimeout(presstimer);
              }
              presstimer = null;
              doubleTapTimer = null;
              return;
            }
          }

          if (presstimer !== null) {
            clearTimeout(presstimer);
            presstimer = null;

            if (doubleTapTimer === null) {
              doubleTapTimer = setTimeout(() => {
                doubleTapTimer = null;

                const imageHighlightState = currentImageHighlightState;
                currentImageHighlightState = null;
                // Press event
                if (imageHighlightState === ImageHighlightState.NotHighlight) {
                  const aTagAncestor = getATagAncestor(img);
                  if (aTagAncestor && aTagAncestor.href && !isURLToImage(aTagAncestor.href)) {
                    window.portalGateToForeground.emit('link-clicked', aTagAncestor.href);
                  } else {
                    portalGateToForeground.emit('image_clicked', {
                      src: img.src,
                      id: imageId,
                      highlighted: false,
                    });
                  }
                } else if (imageHighlightState === ImageHighlightState.Highlight) {
                  const highlightId = img.getAttribute('data-highlight-id');
                  portalGateToForeground.emit('highlight-clicked', {
                    id: highlightId,
                  });
                } else if (imageHighlightState === ImageHighlightState.ActiveHighlight) {
                  portalGateToForeground.emit('image_clicked', {
                    src: img.src,
                    id: imageId,
                    highlighted: true,
                  });
                }
              }, 250);
            } else {
              clearTimeout(doubleTapTimer);
              doubleTapTimer = null;
              onDoubleClick();
            }
          }
        },
        { passive: true },
      );

      img.addEventListener(
        'touchmove',
        (e) => {
          const touch = e.changedTouches[0];
          const diffX = Math.abs(longPressStartX - touch.pageX);
          const diffY = Math.abs(longPressStartY - touch.pageY);

          if (diffX >= longPressMaxDifference || diffY >= longPressMaxDifference) {
            if (presstimer) {
              clearTimeout(presstimer);
              presstimer = null;
              currentImageHighlightState = null;
            }
          }
        },
        { passive: true },
      );
    });
  };
};
