import { contentFocusIndicatorFocusedTargetClass } from '../../constants.platform';
import removeSingleWrapperElementFromHtml from './removeSingleWrapperElementFromHtml';
// eslint-disable-next-line import/no-cycle
import Renderer from './Renderer';

// Remove bits that would cause false negative comparisons
export default (html: string): string =>
  /*
    Why removeSingleWrapperElementFromHtml?

    When creating highlights, it used to not include the outer HTML (for single elements). At some stage, that
    changed. This caused an issue where highlights would be re-rendered over and over because the HTML didn't match
    the expectation.

    Now, we remove the wrapper element here, so going forward, it's removed like it used to be. And it fixes old
    highlight's HTML.
  */
  removeSingleWrapperElementFromHtml(html.trim())
    .replace(/rw-image-highlight/gm, '')
    .replace(/ data-double-click-token="[^"]+?"/gm, '')
    .replace(/ data-highlight-id="[^"]+?"/gm, '')
    .replace(/ data-resize-status="[^"]+?"/gm, '')
    .replace(/ id="[^"]+?"/gm, '') // We add IDs to headings for example
    .replace(contentFocusIndicatorFocusedTargetClass, '')
    .replace(/\s+class="(\s+)?"/gm, '')
    .replace(/<p><\/p>$/m, '')
    .replace(/<\/?rw-highlight[^>]*?>/gm, '')
    .replace(
      new RegExp(`<span\\s+class="${Renderer.highlightIconWrapperClassName}"[^>]*?>.+?</span>`, 'gm'),
      '',
    )
    .replace(
      new RegExp(
        `<span[^>]+class="${Renderer.highlightResizeHandleClassName}[^>]*?><span></span></span>`,
        'gm',
      ),
      '',
    )
    .trim();
