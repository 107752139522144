const getDeepestWith = <T extends Node = Node>(
  node: Node,
  matcher: (current: Node) => current is T,
): T | null | undefined => {
  if (!matcher(node)) {
    return;
  }

  for (const childNode of Array.from(node.childNodes)) {
    const childNodeResult = getDeepestWith<T>(childNode, matcher);
    if (childNodeResult) {
      return childNodeResult;
    }
  }

  return node;
};

export default getDeepestWith;
