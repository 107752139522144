import type { AnyDocument, FirstClassDocument } from '../../types';
import { isFirstClassDocument } from '../../typeValidators';

export function groupDocumentsByParsedDocId(documents: AnyDocument[]): {
  [parsedDocId: string]: FirstClassDocument[];
} {
  const parsedDocIdToDocIds: { [key: string]: FirstClassDocument[] } = {};
  for (const document of documents) {
    if (!isFirstClassDocument(document)) {
      continue;
    }
    if (!document.parsed_doc_id) {
      continue;
    }
    const parsedDocId = document.parsed_doc_id.toString();
    parsedDocIdToDocIds[parsedDocId] ??= [];
    parsedDocIdToDocIds[parsedDocId].push(document);
  }
  return parsedDocIdToDocIds;
}
