import { DocumentLocation, SettingsState, SplitByKey } from '../../types';
// eslint-disable-next-line import/no-cycle
import { SplitBySeenValues, SplitByValues } from '../models';

export default function getSplitByDefaultValue(
  splitBy: string,
  documentLocations: SettingsState['documentLocations'],
): string | null {
  switch (splitBy) {
    case SplitByKey.Seen:
      return SplitBySeenValues.unseen.queryParamValue;

    case SplitByKey.DocumentLocation: {
      const newDocumentLocation =
        documentLocations.find((documentLocation) => documentLocation !== DocumentLocation.Feed) ??
        DocumentLocation.Later;
      const newSplitByValue = SplitByValues[SplitByKey.DocumentLocation][newDocumentLocation];
      return newSplitByValue.queryParamValue;
    }

    default:
      return null;
  }
}
