import { DisplayTheme } from '../../../shared/types';
import { getInverseTheme, Spacing, Theme } from '../css/baseStyles';
// eslint-disable-next-line import/no-cycle
import { PAGINATION_DOCUMENT_TOP_MARGIN } from './types';

const headerGradientLight = `linear-gradient(0deg, rgb(96.863% 98.039% 98.431%) 0%, rgb(97.243% 98.277% 98.621% / 0.87890625) 6.25%, rgb(97.598% 98.499% 98.799% / 0.765625) 12.5%, rgb(97.929% 98.706% 98.964% / 0.66015625) 18.75%, rgb(98.235% 98.897% 99.118% / 0.5625) 25%, rgb(98.517% 99.073% 99.259% / 0.47265625) 31.25%, rgb(98.775% 99.234% 99.387% / 0.390625) 37.5%, rgb(99.007% 99.38% 99.504% / 0.31640625) 43.75%, rgb(99.216% 99.51% 99.608% / 0.25) 50%, rgb(99.4% 99.625% 99.7% / 0.19140625) 56.25%, rgb(99.559% 99.724% 99.779% / 0.140625) 62.5%, rgb(99.694% 99.809% 99.847% / 0.09765625) 68.75%, rgb(99.804% 99.877% 99.902% / 0.0625) 75%, rgb(99.89% 99.931% 99.945% / 0.03515625) 81.25%, rgb(99.951% 99.969% 99.975% / 0.015625) 87.5%, rgb(99.988% 99.992% 99.994% / 0.00390625) 93.75%, rgb(100% 100% 100% / 0) 100% )`;
const headerGradientDark = `linear-gradient(to top, rgb(0, 0, 0) 14.29%, rgba(0, 0, 0, 0.87890625) 19.75%, rgba(0, 0, 0, 0.765625) 25.63%, rgba(0, 0, 0, 0.66015625) 31.09%, rgba(0, 0, 0, 0.5625) 34.45%, rgba(0, 0, 0, 0.47265625) 39.5%, rgba(0, 0, 0, 0.390625) 43.28%, rgba(0, 0, 0, 0.31640625) 46.22%, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.19140625), rgba(0, 0, 0, 0.140625), rgba(0, 0, 0, 0.09765625), rgba(0, 0, 0, 0.0625), rgba(0, 0, 0, 0.03515625), rgba(0, 0, 0, 0.015625), rgba(0, 0, 0, 0.00390625), rgba(0, 0, 0, 0))`;

export const generateImageFilterCSS = (saturationValue: number, opacityValue: number) => {
  return `blur(40px) saturate(${saturationValue}) opacity(${opacityValue})`;
};
export const generateHeaderImageFilterValues = (
  theme: Theme,
  brightness?: number,
  saturation?: number,
  lightness?: number,
) => {
  const isDarkMode = theme.type === DisplayTheme.Dark;

  if (!brightness || !saturation || !lightness) {
    const saturationValue = isDarkMode ? 1 : 1;
    const opacityValue = isDarkMode ? 0.5 : 0.3;
    return { saturation: saturationValue, opacity: opacityValue };
  }
  let saturationValue: number;
  let opacityValue: number;
  if (saturation <= 0.1) {
    saturationValue = isDarkMode ? 1.3 : 3;
  } else if (saturation < 0.4) {
    saturationValue = isDarkMode ? 1.2 : 2.3;
  } else if (saturation < 0.63) {
    saturationValue = isDarkMode ? 1.1 : 1.7;
  } else {
    saturationValue = isDarkMode ? 1 : 1;
  }

  if (brightness <= 0.1) {
    opacityValue = isDarkMode ? 0.6 : 0.4;
  } else if (brightness < 0.4) {
    opacityValue = isDarkMode ? 0.6 : 0.3;
  } else if (brightness < 0.63) {
    opacityValue = isDarkMode ? 0.5 : 0.25;
  } else {
    opacityValue = isDarkMode ? 0.4 : 0.2;
  }

  if (theme.type !== DisplayTheme.Dark) {
    if (lightness <= 0.3) {
      opacityValue += 0.2;
      saturationValue += 0.4;
    } else if (lightness > 0.8) {
      opacityValue = 0.4;
    }
  }
  if (isDarkMode) {
    if (lightness <= 0.2) {
      opacityValue += 0.3;
      saturationValue += 0.3;
    }
    // lightness modifier adding to opacity
    if (lightness <= 0.4) {
      opacityValue += 0.1;
      saturationValue += 0.3;
    }
  }

  const cappedOpacityValue = Math.min(0.6, opacityValue);

  return { saturation: saturationValue, opacity: cappedOpacityValue };
};

export type WebviewStylesProps = {
  theme: Theme;
  marginTop: number;
  isYoutubeVideo: boolean;
  isPaginatedMode: boolean;
  deviceHeight: number;
  isHighContrast: boolean;
};
export const buildWebviewStyles = ({
  theme,
  marginTop,
  isYoutubeVideo,
  isPaginatedMode,
  deviceHeight,
  isHighContrast,
}: WebviewStylesProps) => {
  return `
    * {
      box-sizing: border-box;
    }

    .hidden-image {
      display: none;
    }

    .header-container {
      position: relative;
      background: ${theme.type === DisplayTheme.Dark ? headerGradientDark : headerGradientLight};
    }

    .header {
      margin: auto;
      padding-top: ${isYoutubeVideo ? 0 : '32px'};
      max-width: ${Spacing.maxDocumentWidth}px;
    }

    Body {
      margin: 0;
      width: 100%;
      position: relative;
      margin: auto;
    }
    .bottom-margin {
      height: ${Spacing.readerBottomPadding}px;
    }

    .overflow-hidden-enforcer {
      position: relative;
      overflow: hidden;
    }

    #document-header .tags-container {
      display: flex;
      flex-wrap: wrap;
    }
    #document-header h1 {
      overflow: hidden;
      padding-bottom: 10px;
    }
    .tag {
      background-color: ${theme.uAlpha200};
      color: ${theme.text};
      border-radius: 4px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      margin-right: 4px;
      padding-left: 6px;
      padding-right: 6px;
      margin-bottom: 8px;
    }

    .failed-highlights-message {
      font-size: 14px;
      color: ${theme.secondaryText};
      margin-bottom: 16px;
    }

    .failed-highlights-message button {
      display: block;
      margin-top: 0.75em;
      margin-left: 0;
      font-size: 14px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }


    .image-before-load {
      height: 200px;
    }

    .document-container {
      width: 100%;
      position: relative;
      background-color: ${theme.readerBackground};
    }


    .rtl {
      direction: rtl;
      text-align: right;
    }

    .document-content {
      margin: auto;
      box-sizing: border-box;
      overflow: hidden;
      max-width: ${Spacing.maxDocumentWidth}px;
    }

    .document-content img {
      object-fit: contain;
    }

    .movable {
      transform: translateY(0px)!important;
    }

    .image-margin {
      top: 0;
      height: ${marginTop}px;
      z-index: -1;
      width: 100%;
      overflow: hidden;
    }

    .image-background {
      display: ${isYoutubeVideo ? 'none' : 'block'};
      top: -50px;
      position: fixed;
      height: ${marginTop + 150}px;
      width: 200%;
      z-index: -100;
      filter: ${generateImageFilterCSS(1, 0.3)};
      left: -50%;
      transition: opacity 1.2s ease;
      transform: scale(1);
    }

    .image-background img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .metadata svg {
      fill: ${theme.secondaryText};
      margin-right: 4px;
    }

    .tts-position-indicator {
      position: absolute;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: end;
      background: ${isHighContrast ? 'transparent' : theme.selection};
      border-radius: 5px;
      width: 1px;
      padding: 0 1px;
      left: -1px;
      outline-color: ${theme.selection};
      outline-style: solid;
      outline-width: 2px;
      user-select: none;
      -webkit-user-select: none;
      touch-action: none;
      pointer-events: none;
      transition: none; /*  .tts-position-indicator--with-transition */
    }

    .tts-position-indicator::before {
      content: '';
    }

    .tts-position-indicator--with-transition {
      transition: transform 0.3s, width 0.3s, top 0.3s;
    }

    .tts-position-indicator--with-transition.tts-position-indicator-large {
      transition: transform 0.2s, width 0.2s, top 0.2s, height 0.2s;
    }

    .tts-position-indicator--with-transition.tts-position-indicator-end {
      transition: width 0.2s;
    }

    .search-highlighter {
      position: absolute;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: end;
      background: ${theme.searchHighlight};
      outline: 2px solid var( --focus-ring-color);
      border-radius: 4px;
      width: 1px;
      padding: 0 1px;
      left: -1px;
    }

    .search-highlighter-pop {
      animation: push 0.3s linear 1;
    }

    @keyframes push{
      50%  {transform: scale(1.2);}
    }

    #bottom-divider {
      height: 30px;
      border-top: 1px solid ${theme.borderTertiaryAlpha};
      background: ${theme.readerBackground};
    }

    .end-of-reading-container {
      margin: 0 auto;
      margin-bottom: 150px;
      display: block;
      padding-top: 38px;
      width: calc(100% - ${Spacing.baseHorizontalMargin} * 2);
      max-width: 375px;
      background-color: ${theme.readerBackground};
      padding-bottom: 24px;
      padding-left: ${Spacing.baseHorizontalMargin}px;
      padding-right: ${Spacing.baseHorizontalMargin}px;
      overflow: hidden;
    }

    #end-of-reading-button {
      fill: ${theme.buttonPrimaryDefaultColor}
    }

    #end-of-reading-text {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 17px;
      fill: ${theme.textInteractive};
    }

    /* End of reading keyframes */
    @keyframes a0_t { 0% { transform: translate(187.5px,36px) scale(0,0); animation-timing-function: cubic-bezier(0.372625,0,0.867035,0.778726); } 48.35% { transform: translate(187.5px,36px) scale(0.525,0.535555); animation-timing-function: cubic-bezier(0.645231,0.631791,1,1); } 50% { transform: translate(187.5px,36px) scale(0.603701,0.617328); animation-timing-function: cubic-bezier(0.804425,0.026502,0.58,1); } 53.35% { transform: translate(187.5px,36px) scale(1.003701,1.003113); } 58.35% { transform: translate(187.5px,36px) scale(1,1); } 100% { transform: translate(187.5px,36px) scale(1,1); } }
    @keyframes btn_t { 0% { transform: translate(-32px,-32px); } 50% { transform: translate(-32px,-32px); animation-timing-function: cubic-bezier(0.595,0,0.685,1.505); } 58.35% { transform: translate(-168px,-32px); } 100% { transform: translate(-168px,-32px); } }
    @keyframes btn_o { 0% { opacity: 0; } 39.15% { opacity: 0; } 50% { opacity: 1; } 58.35% { opacity: 1; } 100% { opacity: 1; } }
    @keyframes btn_w { 0% { width: 64px; } 50% { width: 64px; animation-timing-function: cubic-bezier(0.595,0,0.685,1.505); } 58.35% { width: 336px; } 100% { width: 336px; } }
    @keyframes text_t { 0% { transform: translate(187.5px,42.181877px) scale(0.8,0.8); } 51.65% { transform: translate(187.5px,42.181877px) scale(0.8,0.8); animation-timing-function: cubic-bezier(0.595,0,0.685,1.505); } 58.35% { transform: translate(187.5px,42.181877px) scale(1,1); } 100% { transform: translate(187.5px,42.181877px) scale(1,1); } }
    @keyframes text_o { 0% { opacity: 0; } 50% { opacity: 0; } 58.35% { opacity: 1; } 100% { opacity: 1; } }
    @keyframes checkmark_t { 0% { transform: translate(187.5px,36px) scale(2.6,2.6) translate(-16.0004px,-16.6004px); } 14.15% { transform: translate(187.5px,36px) scale(0.317,0.317) translate(-16.0004px,-16.6004px); } 50% { transform: translate(187.5px,36px) scale(1.6,1.6) translate(-16.0004px,-16.6004px); } 100% { transform: translate(187.5px,36px) scale(1.6,1.6) translate(-16.0004px,-16.6004px); } }
    @keyframes checkmark_o { 0% { opacity: 0; } 14.15% { opacity: 0; animation-timing-function: cubic-bezier(0.585,0,0.983145,0.469); } 50% { opacity: 1; animation-timing-function: steps(1); } 50.85% { opacity: 0; } 100% { opacity: 0; } }
    @keyframes check2_t { 0% { transform: translate(183.510605px,36px) rotate(0deg); } 45% { transform: translate(183.510605px,36px) rotate(0deg); } 48.35% { transform: translate(183.510604px,36.000002px) rotate(0deg); } 50% { transform: translate(187.511px,38.888527px) rotate(-44deg); } 100% { transform: translate(187.511px,38.888527px) rotate(-44deg); } }
    @keyframes check2_o { 0% { opacity: 1; } 50% { opacity: 1; } 50.85% { opacity: 0; } 100% { opacity: 0; } }
    @keyframes check2_sw { 0% { stroke-width: 0px; } 48.35% { stroke-width: 2px; } 100% { stroke-width: 2px; } }
    @keyframes check1_t { 0% { transform: translate(183.510605px,36px) rotate(0deg) translateX(0.010605px); } 45% { transform: translate(183.510605px,36px) rotate(0deg) translateX(0.010605px); } 48.35% { transform: translate(183.510604px,36.000002px) rotate(0deg) translateX(0.010605px); } 50% { transform: translate(183.334058px,39.282374px) rotate(43deg) translateX(0.010605px); } 100% { transform: translate(183.334058px,39.282374px) rotate(43deg) translateX(0.010605px); } }
    @keyframes check1_o { 0% { opacity: 1; } 50% { opacity: 1; } 50.85% { opacity: 0; } 100% { opacity: 0; } }
    @keyframes check1_sw { 0% { stroke-width: 0px; } 48.35% { stroke-width: 2px; } 100% { stroke-width: 2px; } }
    @keyframes line_sw { 0% { stroke-width: 0px; } 48.35% { stroke-width: 2px; } 100% { stroke-width: 2px; } }

    .youtube-current-teleprompter {
      background: ${theme.teleprompt};
      color: white;
    }

    .tts-play-button {
      color: ${theme.secondaryText};
      border: none;
      background: ${theme.uAlpha200};
      font-size: 13px;
      line-height: 20px;
      padding: 6px 12px 6px 9px;
      margin: -6px 2px;
      display: flex;
      align-items: center;
      border-radius: 56px;
      text-align: center;
      font-weight: 500;
    }

    .tts-play-button svg {
      margin-right: 2px;
    }
    .tts-play-button svg > path {
      fill: ${theme.text};
      color: ${theme.text};
    }
    .tts-play-button svg > rect {
      fill: ${getInverseTheme(theme).text};
      color: ${getInverseTheme(theme).text};
    }

    .tts-play-text, .tts-stop-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .tts-stop-text {
      display: none;
    }
    .tts-playing {
      background: ${theme.iconSecondary};
      color: ${getInverseTheme(theme).text};
    }

    .tts-playing > .tts-stop-text {
      display: flex;
    }

    .tts-playing > .tts-play-text {
      display: none;
    }

    .document-header-domain {
      margin-right: 10px;
    }
    .document-header-domain span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .document-header-domain-favicon {
      min-width: 16px;
      margin-right: 10px;
      object-fit: cover;
    }

    ${isPaginatedMode ? buildPaginationStyles({ theme, deviceHeight }) : ''}
  `;
};

interface PaginationStyleFunctionProps {
  theme: Theme;
  deviceHeight: number;
}
const buildPaginationStyles = ({ theme, deviceHeight }: PaginationStyleFunctionProps) => {
  return `
      body {
        margin-top: ${PAGINATION_DOCUMENT_TOP_MARGIN}px;
        height: 100%;
      }

      ::-webkit-scrollbar {
        display: none;
      }

      .disable-scroll {
        overflow: hidden!important;
      }

      .hide-snapshot-content {
        display: none!important;
      }

      .hide-snapshot-content-from-selection {
        display: none!important;
      }

      .hide-divider {
        display: none!important;
      }

      .hide-content-snapshot {
        display: none!important;
      }

      .page-divider {
        position: absolute;
        z-index: 998;
        width: 200%;
        height: 2px;
        -webkit-user-select: none;
        user-select: none;
        background: red;
        opacity: 0.3;
        background: ${theme.readerBackground};
        opacity: 1;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .page-number {
        padding-top: 20px;
        color: ${theme.secondaryText};
        font-size: 14px;
        display: none;
      }

      .divider-border {
        width: 100%;
        height: 30px;
        transition: opacity 0.5s ease, box-shadow 0.5s ease, background 0.5s ease;
        left: -25%;
        position: relative;
        background-size: 2px 2px;
        background-position: -19px -19px;
        opacity: 0;
        box-shadow: 0px 0px 7px 0px ${theme.pageShadow} inset;
        background-image: radial-gradient(${theme.pageBorderBackground} 1px, transparent 0);
      }

      .divider-shadow {
        opacity: 1;
      }

      .non-splittable-divider {
        height: 80px;
        background: none;
        display: none;
        align-items: flex-end;
      }

      .non-splittable-divider .divider-border {
        opacity: 1;
        height: 30px;
        background: ${theme.readerBackground};
        background-image: none;
        box-shadow: none;
        transition: opacity 0.3s ease;
      }

      .non-splittable-divider .divider-shadow {
        display: none;
        opacity: 0;
      }

      .page-margin {
        position: fixed;
        z-index: 100;
        width: 100%;
        opacity: 1;
        background: ${theme.readerBackground};
        -webkit-user-select: none;
        user-select: none;
        display: none;
      }

      .document-content {
        padding-bottom: 0px!important;
        margin-bottom: 0px!important;
      }
       .document-content img {
        break-inside: avoid-column;
        max-height: 250px !important;
        min-height: 250px;
        object-fit: contain;
      }
       .document-content video {
        max-height: 250px !important;
        min-height: 250px;
      }
      .document-root {
      }

      .top-page-margin {
        top: 0;
      }
      .bottom-page-margin {
        bottom: 0;
      }

      .end-of-reading-container {
        position: relative;
        z-index: 999;
      }

      .show-end-of-reading-container {
        display: none;
      }

      .bottom-margin {
          height: ${deviceHeight}px;
      }

      .pagination-margin-container {
        position: absolute;
        top:0;
        min-width: 100%;
        min-height: 100%;
      }

      .absolutely-positioned-content {
        -webkit-user-select: none;
        user-select: none;
        pointer-events: none;
        position: absolute;
        z-index: 1000;
        top: 0;
        padding-bottom: 0!important;
        margin-bottom: 0!important;
        opacity: 0.8;
        background: ${theme.readerBackground};
        overflow-x: hidden;
        opacity: 1;
        left: 0;
        right: 0;
      }
      .absolutely-positioned-content.bottom {
        z-index: 1001;
      }

      .absolutely-positioned-content-visible {
        z-index: 1000;
      }

      .content-bottom {
        position: relative;
      }
      .opaque-document-root {
        opacity: 0;
      }

      .debug-styles .absolutely-positioned-content {
        background: beige;
      }
      .debug-styles .absolutely-positioned-content.bottom {
        background: #f98bff;
      }
      .debug-styles .page-divider {
        background: red;
      }
      .header-secondary-image-container {
        min-height: 200px;
        max-height: 200px;
      }
  `;
};
