import type { AnyDocument, DocumentWithParsedDocId } from '../types';
import { DocumentLocation } from '../types';
import { isFirstClassDocument, notEmpty } from '../typeValidators';

export function shouldIncludeDocumentInSearch(
  doc: undefined | Pick<AnyDocument, 'triage_status' | 'category' | 'parsed_doc_id'>,
): doc is DocumentWithParsedDocId {
  return (
    notEmpty(doc) &&
    isFirstClassDocument(doc) &&
    doc.triage_status !== DocumentLocation.Feed &&
    Boolean(doc.parsed_doc_id)
  );
}
