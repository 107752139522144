import TauriSQL from '@tauri-apps/plugin-sql';

import { DeferredPromise } from '../utils/DeferredPromise';
import { isDesktopApp } from '../utils/environment';
import type { Cache as ICache, CacheInstance } from './Cache';

export type KVRow = {
  key: string;
  value: string;
};

export class DesktopCacheInstance implements CacheInstance {
  _db: TauriSQL | undefined;
  _dbInitializedPromise = new DeferredPromise<void>();

  constructor(options: LocalForageOptions) {
    if (!isDesktopApp) {
      // dummy cache instance, necessary because web still imports this and runs into an error trying to communicate with Tauri SQL.
      return;
    }
    this._initialize(options);
  }

  get db(): TauriSQL {
    if (!this._db) {
      throw new Error('DB not initialized');
    }
    return this._db;
  }

  async getItem<T>(key: string): Promise<T> {
    await this._dbInitializedPromise;
    const [row] = await this.db.select<KVRow[]>('SELECT * FROM keyvaluepairs WHERE key=? LIMIT 1;', [
      key,
    ]);
    return row ? JSON.parse(row.value) : null;
  }

  async getItems<T>(keys?: string[] | null): Promise<{ [key: string]: T }> {
    await this._dbInitializedPromise;
    let rows;
    if (keys) {
      rows = await this.db.select<KVRow[]>(
        `SELECT * FROM keyvaluepairs WHERE key IN (${keys.map(() => '?').join(',')});`,
        keys,
      );
    } else {
      rows = await this.db.select<KVRow[]>('SELECT * FROM keyvaluepairs;');
    }
    return Object.fromEntries(rows.map(({ key, value }) => [key, JSON.parse(value)]));
  }

  async setItem<T>(key: string, value: T): Promise<T> {
    await this._dbInitializedPromise;
    const valueString = JSON.stringify(value);
    await this.db.execute(
      'INSERT INTO keyvaluepairs (key, value) VALUES (?, ?) ON CONFLICT DO UPDATE SET value=?;',
      [key, valueString, valueString],
    );
    return value;
  }

  async keys(): Promise<string[]> {
    await this._dbInitializedPromise;
    const rows = await this.db.select<KVRow[]>('SELECT key FROM keyvaluepairs;');
    return rows.map(({ key }) => key);
  }

  async removeItem(key: string): Promise<void> {
    await this._dbInitializedPromise;
    await this.db.execute('DELETE FROM keyvaluepairs WHERE key=?;', [key]);
  }

  async removeItems(keys: string[]): Promise<void> {
    await this._dbInitializedPromise;
    await this.db.execute(
      `DELETE FROM keyvaluepairs WHERE key IN (${keys.map(() => '?').join(',')});`,
      keys,
    );
  }

  async clear(): Promise<void> {
    await this._dbInitializedPromise;
    await this.db.execute('DELETE FROM keyvaluepairs;');
  }

  private async _initialize(options: LocalForageOptions) {
    this._db = await TauriSQL.load(`sqlite:${options.name}.cache.sqlite`);
    await this._db.execute(`
      CREATE TABLE IF NOT EXISTS keyvaluepairs (
        key TEXT PRIMARY KEY,
        value JSON
      );
    `);
    this._dbInitializedPromise.resolve();
  }
}

const defaultCache = new DesktopCacheInstance({
  name: 'default',
});

export const DesktopCache: ICache = {
  createInstance(options: LocalForageOptions): CacheInstance {
    return new DesktopCacheInstance(options);
  },
  getItem: defaultCache.getItem.bind(defaultCache),
  getItems: defaultCache.getItems.bind(defaultCache),
  keys: defaultCache.keys.bind(defaultCache),
  removeItem: defaultCache.removeItem.bind(defaultCache),
  removeItems: defaultCache.removeItems.bind(defaultCache),
  setItem: defaultCache.setItem.bind(defaultCache),
  clear: defaultCache.clear.bind(defaultCache),
};
