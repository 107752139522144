import { DocumentLocation, FeedDocumentLocation, SettingsState } from '../../types';

export default function getSortActiveViewName({
  activeDocumentLocation,
  documentLocations,
  isFeedView,
}: {
  activeDocumentLocation?: DocumentLocation | FeedDocumentLocation;
  documentLocations: SettingsState['documentLocations'];
  isFeedView: boolean;
}) {
  if (isFeedView) {
    return `feed-${activeDocumentLocation}`;
  }

  return (
    activeDocumentLocation ??
    documentLocations.find((documentLocations) => documentLocations !== DocumentLocation.Feed) ??
    DocumentLocation.Later
  );
}
