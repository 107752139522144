import { Category } from '../types';

export default (url: string): Category => {
  const verboseUrl = new URL(url);
  let category: Category;
  if (verboseUrl.pathname.endsWith('.pdf')) {
    category = Category.PDF;
  } else if (url.match(/.+?(twitter|x).com\/.+?\/status\/\d+\/?/)) {
    category = Category.Tweet;
  } else if (url.includes('youtube.com') || url.includes('youtu.be')) {
    category = Category.Video;
  } else {
    category = Category.Article;
  }
  return category;
};
