// eslint-disable-next-line restrict-imports/restrict-imports
import '../../../background/portalGates/toForeground/singleProcess';

// eslint-disable-next-line restrict-imports/restrict-imports
import type * as backgroundMethods from '../../../background/methods';
import PortalGate, { CallResponsePayload } from '../../../PortalGate';
import foregroundEventEmitter from '../../eventEmitter';
// eslint-disable-next-line import/no-cycle
import * as methods from '../../methods';
import type { SingleProcessEventNamesAndArguments } from '../../types/events';

/*
  This is the portal gate to the background. Specifically, when both the background and
  foreground aren't separated into multiple processes. See the equivalent file in the
  background for more info.
*/

export const portalGate = new PortalGate<typeof backgroundMethods, SingleProcessEventNamesAndArguments>({
  getGateName: () => 'Foreground',
  localMethods: methods,
  loggingPrefix: '🖼',
  loggingColor: 'darkgreen',
  portalName: 'background-foreground',
  sendMessage: async ({ message, data }) => {
    foregroundEventEmitter.emit(message, data);
  },
  shouldLog: false,
});

foregroundEventEmitter.onAny((event: string | string[], data: CallResponsePayload['data']) => {
  portalGate.handleIncomingMessage({ message: event as string, data });
});

export default portalGate.methods;
