import type Database from '../../database/Database';
// eslint-disable-next-line import/no-cycle
import saveGlobalTagsCountMiddleware from '../../database/internals/saveGlobalTagsCountMiddleware';
import { isTest } from '../../utils/environment';
// eslint-disable-next-line import/no-cycle
import { populateMetadataSearchMiddleware } from './populateMetadataSearchMiddleware';

export const additionalMiddleware = async (database: Database) => {
  if (!database.rxDbInstance) {
    throw new Error('database.rxDbInstance is falsy');
  }

  saveGlobalTagsCountMiddleware(database);
  if (!isTest) {
    populateMetadataSearchMiddleware(database.rxDbInstance);
  }
};
