import elementReady from 'element-ready';

export default async (code: string, isAsync?: boolean): Promise<void> => {
  if (typeof code !== 'string') {
    throw new Error('Code must be a string');
  }

  await elementReady('head');

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.textContent = isAsync ? `(async function() { ${code} })()` : code;

  /*
    Put in head rather than body to be safe. Example issue (not our fault):
    https://linear.app/readwise/issue/RW-9068/bug-danieldoyongmailcom-web-[tia-652]-user-in-discord-reporting-bug

    This also removes the need for adding `style="display:none"`. We previously found that some sites style scripts
    as `display:block`.
  */
  document.head.appendChild(script);
};
