import { BaseDirectory, removeFile } from '@tauri-apps/plugin-fs';
import TauriSQL from '@tauri-apps/plugin-sql';

import type { GenericSqliteRow, ISqliteDatabase, SqliteValue } from './sqliteDatabase';

export class DesktopSqliteDatabase implements ISqliteDatabase {
  private _db: TauriSQL | undefined;

  constructor(readonly filename: string) {}

  async init(): Promise<void> {
    this._db = await TauriSQL.load(this._tauriDbUri);
  }

  get db(): TauriSQL {
    if (!this._db) {
      throw new Error('DB not initialized');
    }
    return this._db;
  }

  async execute(query: string, params?: SqliteValue[]): Promise<void> {
    await this.db.execute(query, params);
  }

  select<Row extends GenericSqliteRow = GenericSqliteRow>(
    query: string,
    params?: SqliteValue[],
  ): Promise<Row[]> {
    return this.db.select<Row[]>(query, params);
  }

  async save(): Promise<void> {
    // no-op, on desktop the sqlite DB is automatically persisted
  }

  async close(): Promise<void> {
    await this.db.close(this._tauriDbUri);
  }

  async delete() {
    try {
      await removeFile(`${this.filename}.sqlite`, {
        dir: BaseDirectory.AppData,
      });
    } catch (e) {
      // do nothing, because the file didn't exist
    }
  }

  private get _tauriDbUri() {
    return `sqlite:${this.filename}.sqlite`;
  }
}
