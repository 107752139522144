import chunk from 'lodash/chunk';
import isArray from 'lodash/isArray';
import { ulid } from 'ulid';

import type {
  DatabaseCollectionCommonUpdateFunctionOptions,
  DatabaseCollectionCommonUpdateFunctionOptionsWhenUpdatesHaveSideEffects,
  DatabaseCollectionNames,
} from '../types/database';
import delay from '../utils/delay';
import collectionNamesWithoutUpdateSideEffects from './collectionNamesWithoutUpdateSideEffects';
import documentUpsertChunkSize from './internals/documentUpsertChunkSize';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TFuncReturnTypeDefault = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TFuncDefault = (...args: any[]) => TFuncReturnTypeDefault;

/*
  This allows you to easily batch a database update function call safely. It'll even take care of chaining the
  subsequent state update patches together so stuff like undo will work (and more).
  Example:

  await batchifyDatabaseUpdate({
    args: [ids, options],
    collectionName: 'documents',
    func: options.database.collections.documents.deleteByIds,
  })
*/
export default async function batchifyDatabaseUpdate<
  TCollectionName extends DatabaseCollectionNames,
  TFuncReturnType extends TFuncReturnTypeDefault = TFuncReturnTypeDefault,
  TFunc extends TFuncDefault = TFuncDefault,
>({
  args,
  batchSize = documentUpsertChunkSize,
  collectionName,
  delay: delayAmount,
  func,
}: {
  args: Parameters<TFunc>;
  batchSize?: number;
  collectionName: TCollectionName;
  delay?: number;
  func: TFunc;
}): Promise<Awaited<TFuncReturnType>[]> {
  if (!isArray(args[0])) {
    throw new Error('args[0] must be an array');
  }
  if (args.length > 3) {
    throw new Error('args is expected to have at most 3 elements');
  }
  const lastArg = args[args.length - 1];
  if (typeof lastArg !== 'object' || lastArg === null) {
    throw new Error('Expected last args element to be an object (options object)');
  }

  const options = { ...lastArg } as DatabaseCollectionCommonUpdateFunctionOptions<TCollectionName>;
  // Connect the batches via correlation ID if appropriate
  if (
    !collectionNamesWithoutUpdateSideEffects.includes(
      collectionName as (typeof collectionNamesWithoutUpdateSideEffects)[0],
    ) &&
    !('correlationId' in options)
  ) {
    (options as DatabaseCollectionCommonUpdateFunctionOptionsWhenUpdatesHaveSideEffects).correlationId =
      ulid();
  }

  const modifiedArgs = Array.from(args);
  modifiedArgs[modifiedArgs.length - 1] = options;

  const batches = chunk(args[0], batchSize);
  const results: Awaited<TFuncReturnType>[] = [];
  for (let i = 0; i < batches.length; i++) {
    if (i && typeof delayAmount === 'number') {
      await delay(delayAmount);
    }
    modifiedArgs[0] = batches[i];
    const result = await func(...modifiedArgs);
    results.push(result);
  }

  return results;
}
