import type { JsonSchemaTypes, TopLevelProperty } from 'rxdb/dist/types/types';

/*
  Be very careful when changing this file.
  These common definitions are in use by collections already. Let's say you are adding a new collection and tweak one of
  these. That'll be fine for your new collection, but there will be a fatal error preventing the app to initialize
  because the pre-existing collections' schema is now changed without a migration.
  You could not use the common definition, create a new one, carefully add a parameter to an existing one, or change it
  but make sure there's a migration.
*/

type Definition = TopLevelProperty & Required<Pick<TopLevelProperty, 'type'>>;

// 2100-01-01
export const MAX_TIMESTAMP = 4102444800000;
// 2000-01-01
export const MIN_TIMESTAMP = 946684800000;
// Use the year 1440 to offset negative timestamp values in indices
export const GUTENBERG_OFFSET = 16725229521000;

export const anyNumber: Omit<Definition, 'type'> & { type: JsonSchemaTypes } = {
  type: 'number',
};

export const integer: Omit<Definition, 'type'> & { type: JsonSchemaTypes } = {
  type: 'integer',
};

export const booleanInteger = {
  ...integer,
  minimum: 0,
  maximum: 1,
  multipleOf: 1,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'x-auto-convert-value-to-and-from-boolean': true,
};

export function getIndexableInteger(minimum = 0, maximum = Number.MAX_SAFE_INTEGER) {
  return {
    ...integer,
    minimum,
    maximum,
    multipleOf: 1,
  };
}

export function getTimestamp({
  shouldAllowAnyTimeInPast,
  shouldAllowNull,
}: {
  shouldAllowAnyTimeInPast?: boolean;
  shouldAllowNull?: boolean;
} = {}) {
  const result = {
    ...integer,
    maximum: MAX_TIMESTAMP,
    type: shouldAllowNull ? [integer.type, 'null'] : integer.type,
  };
  if (!shouldAllowAnyTimeInPast) {
    result.minimum = MIN_TIMESTAMP;
  }
  return result;
}

/**
 * An indexable timestamp is like a regular timestamp, but doesn't allow for null values.
 * It establishes the multiplier as 1, enabling potential timestamp values in an index to be distinguished with a
 * granularity as fine as 1 millisecond.
 */
export function getIndexableTimestamp() {
  return {
    ...integer,
    minimum: 0,
    maximum: Number.MAX_SAFE_INTEGER,
    multipleOf: 1,
  };
}

export function getString(maxLengthArgument: number) {
  const result: Definition = {
    type: 'string',
  };
  if (maxLengthArgument !== Infinity) {
    result.maxLength = maxLengthArgument;
  }
  return result;
}

export function getLowercasedForSortString(maxLengthArgument = 5) {
  const result: Definition = {
    type: 'string',
    maxLength: maxLengthArgument,
  };
  return result;
}

export function orType(definition: Definition, additionalType: string) {
  const originalTypes = typeof definition.type === 'string' ? [definition.type] : definition.type;
  return {
    ...definition,
    type: [...originalTypes, additionalType],
  };
}

export function orStringInteger(integerDefinition: typeof integer) {
  const result = {
    ...integerDefinition,
    type: [integerDefinition.type, 'string'],
  };
  if (typeof integerDefinition.maximum === 'number') {
    result.maxLength = integerDefinition.maximum.toString().length;
  }
  return result;
}

export const ulid = getString(26);
