import get from 'lodash/get';
import set from 'lodash/set';
import { RxCollection } from 'rxdb';

import lookUpSchemaSubObjectPaths from './lookUpSchemaSubObjectPaths';

export default function convertBooleanIntegersToBooleans(collection: RxCollection, plainData: object) {
  const results = lookUpSchemaSubObjectPaths({
    data: collection.schema.jsonSchema.properties,
    matcher: (data) => data['x-auto-convert-value-to-and-from-boolean'],
    memoizeKey: `${collection.name}-x-auto-convert-value-to-and-from-boolean`,
  });
  for (const { path } of results) {
    const currentValue = get(plainData, path);
    if (typeof currentValue === 'number') {
      set(plainData, path, Boolean(currentValue));
    }
  }
}
