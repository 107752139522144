import debounce from 'lodash/debounce';

import { NetworkStatus } from '../../../NetworkDetector';
import capitalize from '../../../utils/capitalize';
import { isExtension, isMobile } from '../../../utils/environment';
// eslint-disable-next-line import/no-cycle
import { updateState } from '../../models';
import { createToast } from '../../toasts.platform';

// If the network is very unstable, we don't want frequent toasts alerting us
const createNetworkStatusToast = debounce(
  (status: NetworkStatus) => {
    createToast({
      category: status === NetworkStatus.Online ? 'success' : 'default',
      content: capitalize(status),
    });
  },
  5000,
  { leading: true },
);

export const setNetworkStatus = async (status: NetworkStatus, isOnline: boolean): Promise<void> => {
  await updateState(
    (state) => {
      if (state.isOnline === isOnline && state.networkStatus === status) {
        // We don't need to do anything
        return;
      }
      state.isOnline = isOnline;
      state.networkStatus = status;
      if (!isExtension && !isMobile) {
        createNetworkStatusToast(status);
      }
    },
    {
      shouldCreateUserEvent: false,
      eventName: 'network-status-changed',
      userInteraction: null,
      isUndoable: false,
    },
  );
};
