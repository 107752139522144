import elementReady from 'element-ready';

import getHtmlFromSelection from './getHtmlFromSelection';
import isElementTypable from './isElementTypable';

type EventHandler = { eventName: string; callback?: EventListener };
type EventHandlerWithCallback = EventHandler & { callback: NonNullable<EventHandler['callback']> };

type ConstructorParams = {
  onSelectionChange?: () => Promise<void>;
  onTextOrImageSelectedWithMouse?: (finalEvent: MouseEvent) => Promise<void>;
  onTextOrImageSelectedWithTouch?: () => Promise<void>;
};

export default class SelectionEventsHandler {
  hasBeenDestroyed = false;
  lastSeenSelectedHtml?: string;
  onSelectionChange: ConstructorParams['onSelectionChange'];
  onTextOrImageSelectedWithMouse: ConstructorParams['onTextOrImageSelectedWithMouse'];
  onTextOrImageSelectedWithTouch: ConstructorParams['onTextOrImageSelectedWithTouch'];
  _destroyCallbacks: (() => void)[] = [];

  constructor(params: ConstructorParams) {
    this.onSelectionChange = params.onSelectionChange;
    this.onTextOrImageSelectedWithMouse = params.onTextOrImageSelectedWithMouse;
    this.onTextOrImageSelectedWithTouch = params.onTextOrImageSelectedWithTouch;

    this._listen();
  }

  destroy() {
    if (this.hasBeenDestroyed) {
      throw new Error('Has already been destroyed');
    }
    for (const destroyCallback of this._destroyCallbacks) {
      destroyCallback();
    }
    this.hasBeenDestroyed = true;
  }

  async _first(eventHandlers: EventHandler[]) {
    const removeListeners = () => {
      for (const otherEventHandler of eventHandlers as EventHandlerWithCallback[]) {
        document.removeEventListener(otherEventHandler.eventName, otherEventHandler.callback);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const callbackWrapper =
      (callback: EventListener = () => {}): EventListener =>
      (e) => {
        removeListeners();
        return callback(e);
      };

    eventHandlers.forEach((eventHandler) => {
      eventHandler.callback = callbackWrapper(eventHandler.callback);
      this._next(eventHandler.eventName, eventHandler.callback);
    });
  }

  _isValidSelection() {
    const selection = window.getSelection();
    if (!selection) {
      return false;
    }

    const selectedText = selection.toString().trim();
    const selectedHtml = getHtmlFromSelection(selection);

    // Ignore if no text is selected but allow if an image is selected
    if ((!selectedText && !/<img/i.test(selectedHtml)) || selectedHtml === this.lastSeenSelectedHtml) {
      return false;
    }
    return true;
  }

  async _listen() {
    await elementReady('body');
    if (this.hasBeenDestroyed) {
      return;
    }

    if (this.onSelectionChange) {
      document.addEventListener('selectionchange', this.onSelectionChange);
      this._destroyCallbacks.push(() => {
        document.removeEventListener(
          'selectionchange',
          this.onSelectionChange as NonNullable<typeof this.onSelectionChange>,
        );
      });
    }

    const onMouseDown = this._onMouseDown.bind(this);
    const onTouchEnd = this._onTouchEnd.bind(this);
    const onTouchStart = this._onTouchStart.bind(this);
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('touchend', onTouchEnd);
    document.addEventListener('touchstart', onTouchStart);

    this._destroyCallbacks.push(() => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('touchstart', onTouchStart);
    });
  }

  _next(eventName: string, callback: EventListener) {
    document.addEventListener(eventName, callback, {
      once: true,
    });
  }

  _onMouseDown(e: MouseEvent) {
    if (this.hasBeenDestroyed) {
      throw new Error('Listener fired after instance has been destroyed');
    }
    if (
      isElementTypable(document.activeElement) ||
      (e.target as HTMLElement).closest('readwise-tooltip-container')
    ) {
      return;
    }

    this.lastSeenSelectedHtml = getHtmlFromSelection();

    this._first([
      {
        eventName: 'selectionchange',
        callback: () =>
          this._next('mouseup', async (event) => {
            // Ignore if no text is selected but allow if an image is selected
            if (!this._isValidSelection() || !this.onTextOrImageSelectedWithMouse) {
              return;
            }
            await this.onTextOrImageSelectedWithMouse(event as MouseEvent);
          }),
      },
      { eventName: 'mouseup' },
    ]);
  }

  async _onTouchEnd(e: TouchEvent) {
    if (this.hasBeenDestroyed) {
      throw new Error('Listener fired after instance has been destroyed');
    }
    if (!this.onTextOrImageSelectedWithTouch || !this._isValidSelection()) {
      return;
    }
    await this.onTextOrImageSelectedWithTouch();
  }

  _onTouchStart(e: TouchEvent) {
    if (this.hasBeenDestroyed) {
      throw new Error('Listener fired after instance has been destroyed');
    }
    if (
      isElementTypable(document.activeElement) ||
      (e.target as HTMLElement).closest('readwise-tooltip-container')
    ) {
      return;
    }

    this.lastSeenSelectedHtml = getHtmlFromSelection();
  }
}
