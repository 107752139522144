/* eslint-disable prefer-promise-reject-errors */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class DeferredPromise<T> implements Promise<T> {
  [Symbol.toStringTag] = 'Promise';
  catch: Promise<T>['catch'];
  finally: Promise<T>['finally'];
  reject: (error: Error) => void;
  resolve: (arg: T) => void;
  status: 'not-ready' | 'pending' | 'rejected' | 'resolved' = 'pending';
  then: Promise<T>['then'];

  _promise: Promise<T>;

  constructor() {
    this.resolve = () => {
      throw this._getEarlyCallError('resolve');
    };
    this.reject = () => {
      throw this._getEarlyCallError('reject');
    };

    this._promise = new Promise((resolve, reject) => {
      this.resolve = (...args) => {
        if (this.status === 'rejected') {
          throw new Error('Already rejected');
        }
        if (this.status === 'resolved') {
          throw new Error('Already resolved');
        }
        const result = resolve(...args);
        this.status = 'resolved';
        return result;
      };
      this.reject = (...args) => {
        if (this.status === 'rejected') {
          throw new Error('Already rejected');
        }
        if (this.status === 'resolved') {
          throw new Error('Already resolved');
        }
        const result = reject(...args);
        this.status = 'rejected';
        return result;
      };
    });

    this.catch = this._promise.catch.bind(this._promise);
    this.finally = this._promise.finally.bind(this._promise);
    this.status = 'pending';
    this.then = this._promise.then.bind(this._promise);
  }

  _getEarlyCallError(name: string): Error {
    return new Error(`${name} called before it was set`);
  }
}
