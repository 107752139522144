import localforage from 'localforage';
import { extendPrototype as installGetItems } from 'localforage-getitems';
import { extendPrototype as installRemoveItems } from 'localforage-removeitems';

import type { Cache as ICache } from './Cache';

// extend the localforage prototypes with these libraries:
installGetItems(localforage);
installRemoveItems(localforage);

if (!localforage.supports(localforage.INDEXEDDB)) {
  throw new Error('Browser does not support IndexedDB (according to localforage)');
} else {
  localforage.config({
    driver: localforage.INDEXEDDB,
  });

  let hasReadyPromiseSettled = false;
  const readyPromise = localforage.ready();

  readyPromise
    .then(() => {
      hasReadyPromiseSettled = true;
      const driverName = localforage.driver();
      if (driverName !== localforage.INDEXEDDB) {
        throw new Error(`localforage is not using IndexedDB (driver: ${driverName})`);
      }
    })
    .catch((e) => {
      hasReadyPromiseSettled = true;
      throw e;
    });

  const timeoutInSeconds = 10;
  setTimeout(() => {
    if (!hasReadyPromiseSettled) {
      throw new Error(`localforage ready promise has not settled after ${timeoutInSeconds} seconds`);
    }
  }, timeoutInSeconds * 1000);
}

export const BrowserCache: ICache = localforage;
