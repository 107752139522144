import nowTimestamp from '../../utils/dates/nowTimestamp';
import delay from '../../utils/delay';
import { isDesktopApp, isMobile } from '../../utils/environment';
import makeLogger from '../../utils/makeLogger';
// eslint-disable-next-line import/no-cycle
import { globalState } from '../models';
// eslint-disable-next-line import/no-cycle
import background, {
  portalGate as backgroundPortalGate,
} from '../portalGates/toBackground/singleProcess';
import type { CreateSqliteDatabaseFunction, ISqliteDatabase } from '../sqliteDatabase';
// eslint-disable-next-line import/no-cycle
import { iterateThroughDocumentsInBatches } from '../stateGetters';
import type { DocumentSearchEngine, ISearchDocument } from './documentSearchEngine';

const SEARCH_SCHEMA_VERSION = 'searchSchemaVersion';
const LAST_SEARCH_SCHEMA_UPDATE_AT = 'lastSearchSchemaUpdateAt';

const logger = makeLogger(__filename);

const OLD_SEARCH_DB_NAME = isMobile ? 'search2' : isDesktopApp ? 'search' : 'contentDbDump';

// Exists separately from DocumentSearchEngine because this prevents it having circular imports,
// which break Cypress tests.
export class SchemaMigrator {
  private isMigratingContentFromOldSearchDB = false;
  private _engine: DocumentSearchEngine | undefined;
  private _oldSearchDB: ISqliteDatabase | undefined;

  public init(engine: DocumentSearchEngine, createSqliteDatabase: CreateSqliteDatabaseFunction) {
    this._engine = engine;
    this._oldSearchDB = createSqliteDatabase(OLD_SEARCH_DB_NAME);
  }

  get engine(): DocumentSearchEngine {
    if (!this._engine) {
      throw new Error('SchemaMigrator not initialized');
    }
    return this._engine;
  }

  get oldSearchDB(): ISqliteDatabase {
    if (!this._oldSearchDB) {
      throw new Error('SchemaMigrator not initialized');
    }
    return this._oldSearchDB;
  }

  // Called by the document search engine when it upserts documents, to optionally preload existing content.
  public async maybeLoadContentFromOldSearchDB(documents: ISearchDocument[]): Promise<
    | {
        [parsed_doc_id: string]: string;
      }
    | undefined
  > {
    if (!this.isMigratingContentFromOldSearchDB) {
      return undefined;
    }
    const documentsWithoutContent = documents.filter((doc) => !doc.html_content);
    if (documentsWithoutContent.length === 0) {
      return undefined;
    }
    const oldContents = await this.oldSearchDB.select<{ id: string; body: string }>(
      `
        SELECT id, body
        FROM search
        WHERE id IN (${documentsWithoutContent.map(() => '?').join(', ')})
      `,
      documentsWithoutContent.map((doc) => doc.parsed_doc_id),
    );
    return Object.fromEntries(oldContents.map(({ id, body }) => [id, body]));
  }

  public async migrateSchema() {
    const searchSchemaVersion: number = (await background.getCacheItem(SEARCH_SCHEMA_VERSION)) ?? 0;
    const lastSearchSchemaUpdateAt: number | null =
      (await background.getCacheItem(LAST_SEARCH_SCHEMA_UPDATE_AT)) ?? null;
    logger.debug('Checking whether search DB schema needs to be migrated..', {
      searchSchemaVersion,
      lastSearchSchemaUpdateAt,
    });
    switch (searchSchemaVersion) {
      case 0:
        await this._migrateToSearchSchemaVersion1();
        break;
      case 1:
        logger.debug('Search schema up to date, nothing to migrate, bailing..');
        break;
      default:
        throw new Error(`Unknown search schema version: ${searchSchemaVersion}`);
    }
    // can import data async, no need to await & block database initialization
    // else:
    //     either search DB is already up-to-date with RxDB + DocumentContent Store, so
    //     - let populateMetadataSearchMiddleware() handle metadata import on RxDB document upsert
    //     - let populateDocumentSearchEngineWithContent() handle content import
    //     OR search DB doesn't exist yet, which can only happen if persistent state isn't loaded either i.e. initial sync
    //     - search DB will be automatically populated while fetching document metadata from server
    //     - populateDocumentSearchEngineWithContent() handles content import at some point after initial sync is done
    // in both cases we do nothing here except ensure lastSearchSchemaUpdateAt is set
  }

  public async resetMigrationState() {
    logger.debug('Resetting search DB migration state');
    await background.setCacheItem(LAST_SEARCH_SCHEMA_UPDATE_AT, null);
    await background.setCacheItem(SEARCH_SCHEMA_VERSION, 0);
  }

  private async _enableContentImportFromOldSearchDBIfExists() {
    try {
      await this.oldSearchDB.init();
      const [{ oldDbContentCount }] = await this.oldSearchDB.select<{ oldDbContentCount: number }>(
        'SELECT COUNT(1) AS oldDbContentCount FROM search;',
      );
      if (oldDbContentCount > 0) {
        logger.debug('Migrating content from old search DB');
        // setting this boolean flag ensures document content is copied over from old search DB upon metadata upsert
        this.isMigratingContentFromOldSearchDB = true;
      } else {
        await this.oldSearchDB.close();
      }
    } catch (error) {
      // ignore, old search DB probably doesn't exist
      logger.debug('Ignoring error in old search DB', { error });
    }
  }

  private async _migrateToSearchSchemaVersion1() {
    const { persistentStateLoaded } = globalState.getState();
    logger.debug('Starting migration to search schema 1', { persistentStateLoaded });
    await this._enableContentImportFromOldSearchDBIfExists();
    if (persistentStateLoaded) {
      logger.debug('Persistent state loaded, rebuilding search DB..');
      // Don't `await` rebuilding search DB since we don't want to block the caller on indexing their entire library.
      this._rebuildSearchDBFromRxDB();
    } else {
      logger.debug('Persistent state NOT loaded, building search DB on initial sync..');
      // if persistent state is NOT loaded, metadata will be automatically inserted upon initial sync from server
      // in this case, we have to wait until persistent state is finished loading to mark the migration as done
      const onPersistentStateLoadedFromServer = () => {
        this._completeMigrationToSchemaVersion1();
        backgroundPortalGate.off('persistentStateLoadedFromServer', onPersistentStateLoadedFromServer);
      };
      backgroundPortalGate.on('persistentStateLoadedFromServer', onPersistentStateLoadedFromServer);
    }
  }

  private async _completeMigrationToSchemaVersion1() {
    if (this.isMigratingContentFromOldSearchDB) {
      // TODO: there is an edge case here if there is 0 content in the old DB but a bunch of metadata
      //   in this case, the search DB will erroneously not be truncated
      await this._destroyOldSearchDB();
    }
    const now = nowTimestamp();
    logger.debug('Setting last search schema updated at', { now });
    await background.setCacheItem(LAST_SEARCH_SCHEMA_UPDATE_AT, now);
    await background.setCacheItem(SEARCH_SCHEMA_VERSION, 1);
    logger.debug('Migration to schema version 1 complete');
  }

  private async _rebuildSearchDBFromRxDB() {
    logger.debug('(Re)importing all document metadata from RxDB');
    globalState.setState((state) => {
      state.isMigratingSearchDatabase = true;
    });
    await this.engine.deleteDatabase();
    await this.engine.prepareDatabase();
    await iterateThroughDocumentsInBatches(
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'rxdbOnly.indexFields.triage_status_is_feed': false,
      },
      async (batch, batchNum) => {
        logger.debug('Importing batch from RxDB', {
          batch,
          batchNum,
        });
        // if isMigratingContentFromOldSearchDB, then below will also copy doc content over from old search DB
        await this.engine.upsertDocuments(batch);
        await delay(isMobile ? 50 : 10);
      },
    );
    // at this point, when we've upserted every document in RxDB, all the content will have been
    // copied from the old search DB, so we can safely truncate it.
    await this._completeMigrationToSchemaVersion1();
    globalState.setState((state) => {
      state.isMigratingSearchDatabase = false;
    });
  }

  private async _destroyOldSearchDB() {
    logger.debug('Destroying old search DB');
    // not strictly necessary to do this, but saves a lot of disk space on the user's device
    await this.oldSearchDB.delete();
    await this.oldSearchDB.save();
  }
}
