import { getDesktopAppInfo } from '../utils/appInfo.desktop';
import exceptionHandler from '../utils/exceptionHandler.platform';
import getServerBaseUrl from '../utils/getServerBaseUrl.platform';
import background from './portalGates/toBackground/singleProcess';

export async function getAuthedPathForDesktop(): Promise<string> {
  const { osType } = await getDesktopAppInfo();
  return `/to_reader_desktop?osType=${osType}`;
}

export const DESKTOP_SESSIONID = 'desktop_sessionid';

export async function getDesktopSessionId() {
  return background.getCacheItem<string>(DESKTOP_SESSIONID);
}

export async function deleteDesktopSessionId() {
  await background.removeCacheItems([DESKTOP_SESSIONID]);
}

export async function setDesktopSessionId(sessionId: string) {
  await background.setCacheItem(DESKTOP_SESSIONID, sessionId);
}

/**
 * Authenticates a URI for desktop by requesting a one-time token that is included in the given URL's query params.
 *
 * @param {string} urlString - The URI to authenticate.
 * @returns {Promise<string>} - The authenticated URI.
 */
export async function authenticateUriWithOneTimeToken(urlString: string): Promise<string> {
  // prevent a circular import. is cached automatically by browser so no need to memoize manually.
  // eslint-disable-next-line import/no-cycle
  const { default: requestWithAuth } = await import(
    '../utils/requestWithAuth.platformIncludingExtension'
  );
  const url = new URL(urlString);
  const response = await requestWithAuth(`${getServerBaseUrl()}/api/create_one_time_token`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      pathname: url.pathname,
    }),
  });
  if (!response.ok) {
    exceptionHandler.captureException('could not create one time token for desktop', {
      extra: {
        urlString,
        response,
      },
    });
    return urlString;
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { one_time_token }: { one_time_token: string } = await response.json();
  url.searchParams.set('one_time_token', one_time_token);
  return url.toString();
}
