import type { HighlightResizeState } from '../../types/highlights';
import { isTextNode } from '../../typeValidators';
import type { RangySelection } from '../types/rangy';
import contractRangeIfPossible from './contractRangeIfPossible';
import getOppositeEnd from './getOppositeEnd';
import getSiblings from './getSiblings';
import isImage from './isImage';

const PUNCTUATION_REGEX = /[!"$%'().;?[\]`{}“”‘’]/;
// This is not an exhaustive list, feel free to extend this list if missing something
const INLINE_TEXT_TAGS = ['B', 'EM', 'A', 'SPAN', 'STRONG', 'I', 'U', 'RW-HIGHLIGHT'];

const countWhitespace = (text: string) => (text.match(/\s+/g) ?? []).length;

function canModifyEnd(end: 'end' | 'start', getHighlightResizeState: () => HighlightResizeState) {
  const highlightResizeState = getHighlightResizeState();

  if (highlightResizeState.status === 'native-selection-made-but-user-hasnt-started-resizing-yet') {
    // Just the highlight should be selected
    return false;
  }

  // When a resize is happening, the end not being moved should stay where it is
  if (
    highlightResizeState.status === 'actively-resizing' &&
    highlightResizeState.edgeResizeStartedFrom &&
    getOppositeEnd(highlightResizeState.edgeResizeStartedFrom) === end
  ) {
    return false;
  }

  return true;
}

export function grabPunctuationAtStart(
  rangySelection: RangySelection,
  getHighlightResizeState: () => HighlightResizeState,
) {
  if (
    !canModifyEnd('start', getHighlightResizeState) ||
    !rangySelection.rangeCount ||
    rangySelection.isCollapsed
  ) {
    return;
  }

  // If this selection is just one or two words, dont grab punctuation;
  if (countWhitespace(rangySelection.toString()) < 2) {
    return;
  }

  const rangyRange = rangySelection.getRangeAt(0);
  if (
    isImage(rangyRange.startContainer) ||
    isImage(rangyRange.startContainer.childNodes[rangyRange.startOffset]) ||
    isImage(rangyRange.startContainer.childNodes[rangyRange.startOffset + 1])
  ) {
    return;
  }

  const firstNodeInSelection = rangyRange.startContainer;
  const offsetInNode = rangyRange.startOffset;
  if (!firstNodeInSelection || !firstNodeInSelection.textContent) {
    return;
  }

  /*
    IF we are at the start of the node
    IF we have a parent element and its an inline tag
   */
  if (
    offsetInNode === 0 &&
    firstNodeInSelection.parentElement &&
    INLINE_TEXT_TAGS.includes(firstNodeInSelection.parentElement.nodeName ?? '')
  ) {
    const { siblings: previousTextNodes } = getSiblings({
      direction: 'previous',
      element: firstNodeInSelection.parentElement,
      matcher: isTextNode,
      shouldIncludeNonElements: true,
    });
    /*
      if we have a previous text node select the node at the very end of its text
      If it ends on a punctuation, this code will then grab it recursively
     */
    let lastNode = null;
    if (previousTextNodes.length > 0) {
      lastNode = previousTextNodes[0];
      if (lastNode.textContent) {
        rangyRange.setStart(lastNode, lastNode.textContent.length);
        rangySelection.setSingleRange(rangyRange);
        return grabPunctuationAtStart(rangySelection, getHighlightResizeState);
      }
    }
  }

  let currentOffset = offsetInNode;
  while (currentOffset > 0) {
    const charToTest = firstNodeInSelection.textContent.slice(currentOffset - 1, currentOffset);
    if (!PUNCTUATION_REGEX.test(charToTest)) {
      break;
    }
    currentOffset -= 1;
  }
  if (currentOffset === offsetInNode) {
    return;
  }

  rangyRange.setStart(firstNodeInSelection, Math.max(currentOffset, 0));
  rangySelection.setSingleRange(rangyRange);
}

export function grabPunctuationAtEnd(
  rangySelection: RangySelection,
  getHighlightResizeState: () => HighlightResizeState,
) {
  if (
    !canModifyEnd('end', getHighlightResizeState) ||
    !rangySelection.rangeCount ||
    rangySelection.isCollapsed
  ) {
    return;
  }

  // If this selection is just one or two words, dont grab punctuation;
  if (countWhitespace(rangySelection.toString()) < 2) {
    return;
  }

  const rangyRange = rangySelection.getRangeAt(rangySelection.rangeCount - 1);
  if (
    isImage(rangyRange.endContainer) ||
    isImage(rangyRange.endContainer.childNodes[rangyRange.endOffset]) ||
    isImage(rangyRange.endContainer.childNodes[rangyRange.endOffset - 1])
  ) {
    return;
  }
  const lastNodeInSelection = rangyRange.endContainer;
  const offsetInNode = rangyRange.endOffset;

  if (!lastNodeInSelection || !lastNodeInSelection.textContent) {
    return;
  }

  /*
    IF we are at the end of the node
    IF we have a parent element and its an inline tag
  */
  if (
    lastNodeInSelection.textContent.length === offsetInNode &&
    lastNodeInSelection.parentElement &&
    INLINE_TEXT_TAGS.includes(lastNodeInSelection.parentElement.nodeName ?? '')
  ) {
    const { siblings: nextTextNodes } = getSiblings({
      direction: 'next',
      element: lastNodeInSelection.parentElement,
      matcher: isTextNode,
      shouldIncludeNonElements: true,
    });
    /*
     if we have a next text node select the node at the very start of its text
     If it starts on a punctuation, this code will then grab it recursively
    */
    if (nextTextNodes.length > 0 && nextTextNodes[0].textContent) {
      rangyRange.setEnd(nextTextNodes[0], 0);
      rangySelection.setSingleRange(rangyRange);
      return grabPunctuationAtEnd(rangySelection, getHighlightResizeState);
    }
  }

  let currentOffset = offsetInNode;
  while (currentOffset <= lastNodeInSelection.textContent.length) {
    const charToTest = lastNodeInSelection.textContent.slice(currentOffset, currentOffset + 1);
    if (!PUNCTUATION_REGEX.test(charToTest)) {
      break;
    }
    currentOffset += 1;
  }

  if (currentOffset === offsetInNode) {
    return;
  }

  rangyRange.setEnd(lastNodeInSelection, currentOffset);
  rangySelection.setSingleRange(rangyRange);
}

// NOTE: this mutates the input selection
const trimSelectionStart = (
  rangySelection: RangySelection,
  getHighlightResizeState: () => HighlightResizeState,
): void => {
  if (!canModifyEnd('start', getHighlightResizeState) || !rangySelection.rangeCount) {
    return;
  }

  const rangyRange = rangySelection.getRangeAt(0);
  if (
    isImage(rangyRange.startContainer) ||
    isImage(rangyRange.startContainer.childNodes[rangyRange.startOffset]) ||
    isImage(rangyRange.startContainer.childNodes[rangyRange.startOffset + 1])
  ) {
    return;
  }

  const selectedText = rangySelection.toString();
  if (!selectedText || !/^\s/.test(rangySelection.toString())) {
    return;
  }

  const didContract = contractRangeIfPossible({
    doc: rangySelection.anchorNode?.ownerDocument ?? undefined,
    endToMove: 'start',
    rangyRange,
  });
  if (!didContract) {
    return;
  }
  const textAfterContraction = rangyRange.toString();
  if (!textAfterContraction || !textAfterContraction.includes(selectedText.trim())) {
    return;
  }
  rangySelection.setSingleRange(rangyRange);

  // Safety check; blocks hypotethical infinite loop
  if (rangySelection.toString() === selectedText) {
    return;
  }
  trimSelectionStart(rangySelection, getHighlightResizeState);
};

// NOTE: this mutates the input selection
const trimSelectionEnd = (
  rangySelection: RangySelection,
  getHighlightResizeState: () => HighlightResizeState,
): void => {
  if (!canModifyEnd('end', getHighlightResizeState)) {
    return;
  }

  const rangyRange = rangySelection.getRangeAt(rangySelection.rangeCount - 1);
  if (
    isImage(rangyRange.endContainer) ||
    isImage(rangyRange.endContainer.childNodes[rangyRange.endOffset]) ||
    isImage(rangyRange.endContainer.childNodes[rangyRange.endOffset - 1])
  ) {
    return;
  }

  const selectedText = rangySelection.toString();
  if (!selectedText || !/\s$/.test(rangySelection.toString())) {
    return;
  }

  const didContract = contractRangeIfPossible({
    doc: rangySelection.anchorNode?.ownerDocument ?? undefined,
    endToMove: 'end',
    rangyRange,
  });

  if (!didContract) {
    return;
  }
  const textAfterContraction = rangyRange.toString();
  if (!textAfterContraction || !textAfterContraction.includes(selectedText.trim())) {
    return;
  }
  rangySelection.setSingleRange(rangyRange);

  // Safety check; blocks hypotethical infinite loop
  if (rangySelection.toString() === selectedText) {
    return;
  }
  trimSelectionEnd(rangySelection, getHighlightResizeState);
};

/*
  NOTE: this mutates the input selection.
  Why is `getHighlightResizeState` a function? Because some of the functions that need to check it are
  recursive and the value could change in the meantine.
*/
export default ({
  getHighlightResizeState,
  selection,
  shouldNotGrabPunctuation,
}: {
  getHighlightResizeState: () => HighlightResizeState;
  selection: RangySelection;
  shouldNotGrabPunctuation?: boolean;
}): void => {
  trimSelectionStart(selection, getHighlightResizeState);
  trimSelectionEnd(selection, getHighlightResizeState);
  if (shouldNotGrabPunctuation) {
    return;
  }
  grabPunctuationAtStart(selection, getHighlightResizeState);
  grabPunctuationAtEnd(selection, getHighlightResizeState);
};
