import { type RxCollection, type RxDocumentData } from 'rxdb';

import type { DatabaseDocType, RxDocumentWithoutOrmMethods } from '../../types/database';
import convertBooleanIntegersToBooleans from './convertBooleanIntegersToBooleans';

// See RxDocumentData<T>
const RxDocumentDataMetaPropertyKeys: ReadonlyArray<keyof RxDocumentData<unknown>> = [
  '_deleted',
  '_attachments',
  '_rev',
  '_meta',
] as const;

export function removeRxDBMetaPropertiesFromDocumentData<DocType>(
  rxDocumentData: RxDocumentData<DocType> | DocType,
): void {
  for (const key of RxDocumentDataMetaPropertyKeys) {
    delete rxDocumentData[key];
  }
}

// NOTE: this function modifies the given document in place and returns it.
// the given document must therefore be mutable.
export function convertRxDocumentDataToOurJson<DocType extends DatabaseDocType>(
  rxCollection: RxCollection,
  rxDocumentData: RxDocumentData<DocType> | DocType,
): DocType {
  removeRxDBMetaPropertiesFromDocumentData(rxDocumentData);
  convertBooleanIntegersToBooleans(rxCollection, rxDocumentData as object);
  return rxDocumentData;
}

export default function convertRxDocumentToOurJson<DocType>(
  rxCollection: RxCollection,
  rxDocument: RxDocumentWithoutOrmMethods<DocType>,
): DocType {
  // clones immutable RxDocument as JSON, so we can mutate it.
  return convertRxDocumentDataToOurJson(rxCollection, rxDocument.toMutableJSON());
}

export function convertRxDocumentsToOurJson<DocType>(
  rxCollection: RxCollection,
  rxDocuments: RxDocumentWithoutOrmMethods<DocType>[],
): DocType[] {
  return rxDocuments.map((rxDocument) => convertRxDocumentToOurJson(rxCollection, rxDocument));
}
