import React from 'react';

import makeLogger from '../../../shared/utils/makeLogger';

const logger = makeLogger(__filename);

/**
 * Fallback component displayed when dynamic import of a component fails.
 */
export default function DynamicImportFailed(props: unknown) {
  logger.debug('DynamicImportFailed component', { props });
  return <div>Could not load component</div>;
}
