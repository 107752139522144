import { MangoQuery } from 'rxdb';

import type { AnyDocument } from '../../../../types';
// eslint-disable-next-line import/no-cycle
import database from '../../../database';
import type { StateUpdateResult } from '../../../types';

export async function updateDocument<T extends AnyDocument>(
  docIdOrQuery: Parameters<typeof database.collections.documents.findOneAndIncrementalModify>[0],
  updateDoc: (doc: T) => T | void,
  options: Parameters<typeof database.collections.documents.findOneAndIncrementalModify>[2],
): StateUpdateResult {
  return database.collections.documents.findOneAndIncrementalModify<T>(
    docIdOrQuery,
    (doc) => {
      if (!doc) {
        return doc;
      }
      return updateDoc(doc) ?? doc;
    },
    {
      errorMessageIfNothingFound:
        options.errorMessageIfNothingFound ??
        `updateDocument: no document found (eventName: ${
          options.eventName
        }, docIdOrQuery: ${JSON.stringify(docIdOrQuery)})`,
      ...options,
    },
  );
}

export async function updateDocuments<T extends AnyDocument>(
  documentIdsOrQuery: MangoQuery<AnyDocument> | string[],
  updateOneDoc: (doc: T) => T | void,
  options: Parameters<typeof database.collections.documents.findByIdsAndIncrementalModify>[2],
): StateUpdateResult {
  const errorMessageIfNothingFound =
    options.errorMessageIfNothingFound ??
    `updateDocuments: no documents found (eventName: ${
      options.eventName
    }, docIdOrQuery: ${JSON.stringify(documentIdsOrQuery)})`;
  if (Array.isArray(documentIdsOrQuery)) {
    return database.collections.documents.findByIdsAndIncrementalModify<T>(
      documentIdsOrQuery,
      (doc) => {
        if (!doc) {
          return doc;
        }
        return updateOneDoc(doc) ?? doc;
      },
      {
        errorMessageIfNothingFound,
        ...options,
      },
    );
  }
  return database.collections.documents.findAndIncrementalModify<T>(
    documentIdsOrQuery,
    (doc) => {
      if (!doc) {
        return doc;
      }
      return updateOneDoc(doc) ?? doc;
    },
    {
      errorMessageIfNothingFound,
      ...options,
    },
  );
}

export async function patchDocuments<T extends AnyDocument>(
  documentIds: Parameters<typeof database.collections.documents.findByIdsAndIncrementalPatch>[0],
  patch: Partial<T>,
  options: Parameters<typeof database.collections.documents.findByIdsAndIncrementalPatch>[2],
): StateUpdateResult {
  return database.collections.documents.findByIdsAndIncrementalPatch<T>(documentIds, patch, {
    errorMessageIfNothingFound:
      options.errorMessageIfNothingFound ??
      `patchDocuments: no documents found (eventName: ${options.eventName})`,
    ...options,
  });
}
