export default (element: HTMLElement, eventNames: string[]): Promise<void> => {
  return new Promise((resolve) => {
    const callback = () => {
      // Remove listeners
      for (const eventName of eventNames) {
        element.removeEventListener(eventName, callback);
      }

      resolve();
    };

    for (const eventName of eventNames) {
      element.addEventListener(eventName, callback, { once: true });
    }
  });
};
