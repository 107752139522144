import isEqual from 'lodash/isEqual';
import type { MangoQuerySelector } from 'rxdb';

import { isFirstClassDocumentQuery } from '../database/standardQueries';
import { AnyDocument } from '../types';

function negateOperator(operator: string, value: unknown) {
  switch (operator) {
    case '$eq':
      return { $ne: value };
    case '$ne':
      return { $eq: value };
    case '$in':
      return { $nin: value };
    case '$nin':
      return { $in: value };
    case '$gt':
      return { $lte: value };
    case '$lt':
      return { $gte: value };
    case '$gte':
      return { $lt: value };
    case '$lte':
      return { $gt: value };
    case '$exists':
      return { $exists: !value };
    case '$not':
      return value;
    default:
      return { $eq: value };
  }
}

function removeFirstClassDocQuery(
  query: MangoQuerySelector<AnyDocument> | MangoQuerySelector<AnyDocument>[],
) {
  if (Array.isArray(query)) {
    return query.filter((q) => !isEqual(q, isFirstClassDocumentQuery));
  }

  return query;
}

export function negateQuery(_query: MangoQuerySelector<AnyDocument>) {
  const negatedQuery: MangoQuerySelector<AnyDocument> = {};
  const query = removeFirstClassDocQuery(_query);

  // Base case: if query is undefined
  if (!query) {
    return negatedQuery;
  }

  if (!Array.isArray(query) && query.$and) {
    const andQueries = removeFirstClassDocQuery(query.$and) as MangoQuerySelector<AnyDocument>[];
    const negatedConditions: MangoQuerySelector<AnyDocument>[] = andQueries.map((condition) =>
      negateQuery(condition),
    );
    return { $and: negatedConditions };
  }

  if (!Array.isArray(query) && query.$or) {
    const orQueries = removeFirstClassDocQuery(query.$or) as MangoQuerySelector<AnyDocument>[];
    const negatedConditions: MangoQuerySelector<AnyDocument>[] = orQueries.map((condition) =>
      negateQuery(condition),
    );
    return { $and: negatedConditions };
  }

  // For individual conditions
  for (const key in query) {
    if (Object.hasOwn(query, key)) {
      const subQuery = query[key];

      if (typeof subQuery === 'object' && !Array.isArray(subQuery)) {
        negatedQuery[key] = {};

        for (const operator in subQuery) {
          if (Object.hasOwn(subQuery, operator)) {
            Object.assign(negatedQuery[key], negateOperator(operator, subQuery[operator]));
          }
        }
      } else {
        // Treat it as a direct value (implicit $eq)
        negatedQuery[key] = { $ne: subQuery };
      }
    }
  }

  return negatedQuery;
}
