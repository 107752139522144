import {
  Expression,
  ExpressionLogical,
  ExpressionNode,
  NodeType,
  PartialExpression,
  PartialExpressionLogical,
} from './types';

export function isNextString({
  input,
  subString,
  currentIndex,
}: { input: string; subString: string; currentIndex: number }): boolean {
  return input.slice(currentIndex).startsWith(subString);
}

export const match =
  <R>(matcher: {
    field: (field: ExpressionNode) => R;
    logical: (logical: ExpressionLogical) => R;
  }) =>
  (expr: Expression): R => {
    switch (expr.type) {
      case NodeType.Node:
        return matcher.field(expr);
      case NodeType.Logical:
        return matcher.logical(expr);
    }
  };

export const matchWithDefault =
  <R>(
    matcher: {
      field: (field: Expression) => R;
      logical: (logical: PartialExpressionLogical) => R;
    },
    defaultValue: R,
  ) =>
  (expr: PartialExpression): R => {
    if (!expr) {
      return defaultValue;
    }

    switch (expr.type) {
      case NodeType.Node:
        return matcher.field(expr);
      case NodeType.Logical:
        return matcher.logical(expr);
    }
  };
