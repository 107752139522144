import getBoundingClientRect from '../../../shared/foreground/utils/getBoundingClientRect';

export type Options = Pick<ScrollIntoViewOptions, 'block'> & {
  block: 'start' | 'end';
  margin?: number;
  scrollableAncestor: HTMLElement;
};

/*
  Only use this if the built-in Element.scrollIntoView cannot be used.
  This is designed to work the way with some extra options.

  `margin`: the scrollable ancestor will be srolled so that the element is this margin distance from the boundary if possible
*/
export default async (
  input: HTMLElement | DOMRect,
  { behavior, block, margin = 0, scrollableAncestor }: Options & Pick<ScrollOptions, 'behavior'>,
): Promise<void> => {
  if (!margin && !(input instanceof DOMRect)) {
    throw new Error('No margin given. Use built-in Element.scrollIntoView instead');
  }

  const scrollableRect = await getBoundingClientRect(scrollableAncestor);
  const rect = input instanceof DOMRect ? input : await getBoundingClientRect(input);
  const sideToAlign = block === 'start' ? 'top' : 'bottom';
  const boundary = scrollableRect[sideToAlign];
  const elementEdge = rect[sideToAlign];

  if (boundary - elementEdge === margin) {
    return;
  }

  let newScrollTop = scrollableAncestor.scrollTop + elementEdge + margin * (block === 'start' ? -1 : 1);

  if (block === 'end') {
    newScrollTop -= scrollableAncestor.clientHeight;
  }

  scrollableAncestor.scroll({
    behavior,
    left: 0,
    top: Math.max(newScrollTop, 0),
  });
};
