export enum PromptModel {
  // TODO: These lint rules are excessive
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums, @typescript-eslint/naming-convention
  GPT_35_TURBO = 'GPT_35_TURBO',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums, @typescript-eslint/naming-convention
  GPT_4 = 'GPT_4',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums, @typescript-eslint/naming-convention
  GPT_4_TURBO = 'GPT_4_TURBO',
  // eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums, @typescript-eslint/naming-convention
  GPT_4o = 'GPT_4o',
}

export enum PromptScope {
  Document = 'DOCUMENT',
}

export enum PromptType {
  SummarizeDocument = 'SUMMARIZE_DOCUMENT',
  TagDocument = 'TAG_DOCUMENT',
}

export type Prompt = {
  model: PromptModel;
  scope: PromptScope;
  system: string;
  template: string;
  title: string;
  type: PromptType;
  description?: string;
  position: number;
};

export type Prompts = {
  [Scope in PromptScope]?: {
    [Type in PromptType]?: Prompt;
  };
};
