export default function setSelectionBaseAndExtentIfDifferent(
  argumentsForSetBaseAndExtent: Parameters<(typeof Selection)['prototype']['setBaseAndExtent']>,
  existingSelection: Selection,
) {
  const [anchorNode, anchorOffset, focusNode, focusOffset] = argumentsForSetBaseAndExtent;

  const isEqual =
    anchorNode.isEqualNode(existingSelection.anchorNode) &&
    anchorOffset === existingSelection.anchorOffset &&
    focusNode.isEqualNode(existingSelection.focusNode) &&
    focusOffset === existingSelection.focusOffset;

  if (isEqual) {
    return;
  }

  existingSelection.setBaseAndExtent(anchorNode, anchorOffset, focusNode, focusOffset);
}
