import type { MangoQuerySelector } from 'rxdb';

import type {
  DatabaseCollectionNames,
  DatabaseCollectionNamesToDocType,
  DatabaseDocType,
} from '../types/database';
import { notEmpty } from '../typeValidators';

function $and<
  TCollectionName extends DatabaseCollectionNames,
  TDocType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
>(
  selectors: (MangoQuerySelector<TDocType> | false | null | undefined)[] = [],
): { $and: MangoQuerySelector<TDocType>[] } | MangoQuerySelector<TDocType> {
  const filteredSelectors = selectors.filter(isSelector);
  if (filteredSelectors.length === 1) {
    return filteredSelectors[0];
  }
  return flattenIfAppropriate({
    $and: filteredSelectors,
  });
}

function $nor<
  TCollectionName extends DatabaseCollectionNames,
  TDocType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
>(
  selectors: (MangoQuerySelector<TDocType> | false | null | undefined)[] = [],
): { $nor: MangoQuerySelector<TDocType>[] } | MangoQuerySelector<TDocType> {
  return {
    $nor: selectors.filter(isSelector),
  };
}

function $or<
  TCollectionName extends DatabaseCollectionNames,
  TDocType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
>(
  selectors: (MangoQuerySelector<TDocType> | false | null | undefined)[] = [],
): { $or: MangoQuerySelector<TDocType>[] } | MangoQuerySelector<TDocType> {
  const filteredSelectors = selectors.filter(isSelector);
  if (filteredSelectors.length === 1) {
    return filteredSelectors[0];
  }
  const flattened = flattenIfAppropriate({
    $or: filteredSelectors,
  });
  flattened.$or.sort((a, b) => {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    const doesAKeysContainA$Key = aKeys.some((key) => key.startsWith('$'));
    const doesBKeysContainA$Key = bKeys.some((key) => key.startsWith('$'));

    return (
      (doesAKeysContainA$Key ? Infinity : aKeys.length) -
      (doesBKeysContainA$Key ? Infinity : bKeys.length)
    );
  });
  return flattened;
}

function flattenIfAppropriate<
  TDocType extends DatabaseDocType,
  TSelector extends
    | {
        $and: NonNullable<MangoQuerySelector<TDocType>['$and']>;
      }
    | {
        $nor: NonNullable<MangoQuerySelector<TDocType>['$nor']>;
      }
    | {
        $or: NonNullable<MangoQuerySelector<TDocType>['$or']>;
      },
>(selector: TSelector): TSelector {
  const operator = Object.keys(selector)[0] as '$and' | '$nor' | '$or';
  const childSelectors = selector[operator] as
    | NonNullable<MangoQuerySelector<TDocType>['$and']>
    | NonNullable<MangoQuerySelector<TDocType>['$nor']>
    | NonNullable<MangoQuerySelector<TDocType>['$or']>;

  const result = {
    [operator]: [],
  } as TSelector;

  for (const childSelector of childSelectors) {
    // If the input is `{ $or: [...] }`, put any children which use `$or` at the top-level of the result (i.e. flatten)
    const operandsOfSameOperatorChild = childSelector[operator];
    if (operandsOfSameOperatorChild) {
      result[operator].push(...operandsOfSameOperatorChild);
    } else {
      result[operator].push(childSelector);
    }
  }

  return result;
}

function isSelector<TDocType extends DatabaseDocType>(
  selector: MangoQuerySelector<TDocType> | false | null | undefined,
): selector is MangoQuerySelector<TDocType> {
  return selector !== false && notEmpty(selector);
}

export default {
  $and,
  $nor,
  $or,
};
