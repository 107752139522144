import { convertQueryToRxDBQuery } from '../../filters-compiler/convertQueryToRxDBQuery';
import { DocumentLocation, FilteredView, SortKey, SortOrder } from '../../types';
// eslint-disable-next-line import/no-cycle
import getSplitByDefaultValue from './getSplitByDefaultValue';

export default function getLastUpdatedMangoQueryFromView(
  view: FilteredView,
  documentLocations: DocumentLocation[],
) {
  const { mangoQuery } = convertQueryToRxDBQuery({
    query: view.query,
    splitBy: view.splitBy,
    splitByValue: view.splitBy
      ? getSplitByDefaultValue(view.splitBy, documentLocations) ?? undefined
      : undefined,
    sortRules: [
      {
        id: '1',
        key: SortKey.LastStatusUpdate,
        order: SortOrder.Desc,
      },
    ],
  });

  return mangoQuery;
}
