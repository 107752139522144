import { isDesktopApp } from '../../utils/environment';
import type { CreateSqliteDatabaseFunction, ISqliteDatabase } from './sqliteDatabase';
import { BrowserSqliteDatabase } from './sqliteDatabase.browser';
import { DesktopSqliteDatabase } from './sqliteDatabase.desktop';

export const createSqliteDatabase: CreateSqliteDatabaseFunction = (filename): ISqliteDatabase => {
  if (isDesktopApp) {
    return new DesktopSqliteDatabase(filename);
  } else {
    return new BrowserSqliteDatabase(filename);
  }
};
