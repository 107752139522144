// eslint-disable-next-line restrict-imports/restrict-imports
import { Cache as OurCache } from '../background/cache.platform';
import deleteIndexedDbDatabase from '../utils/deleteIndexedDbDatabase';
import { isTest } from '../utils/environment';

export default async function clearLocalRxdbData() {
  // clear out any keys used for the persistent query cache:
  const persistentQueryCacheKeys = (await OurCache.keys()).filter((key) => key.startsWith('qc:'));
  await OurCache.removeItems(persistentQueryCacheKeys);

  for (const key in localStorage) {
    if (key.startsWith('rx-') || key.includes('rxdb')) {
      localStorage.removeItem(key);
    }
  }

  let indexedDbDatabases: IDBDatabaseInfo[] = [];
  try {
    indexedDbDatabases = await indexedDB.databases();
  } catch (e) {
    // Ignore
  }

  const indexDbDatabaseNamesToDelete = ['indexeddb', 'localstorage-meta-optimized', 'reader', 'remote'];

  for (const indexedDbDatabaseInfo of indexedDbDatabases) {
    if (!indexedDbDatabaseInfo.name) {
      continue;
    }
    if (
      indexedDbDatabaseInfo.name.startsWith('findBestIndex_') ||
      indexedDbDatabaseInfo.name.startsWith('rx-') ||
      indexedDbDatabaseInfo.name.includes('rxdb') ||
      isTest
    ) {
      indexDbDatabaseNamesToDelete.push(indexedDbDatabaseInfo.name);
    }
  }

  await Promise.all(indexDbDatabaseNamesToDelete.map(deleteIndexedDbDatabase));
}
