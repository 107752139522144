// eslint-disable-next-line import/no-cycle
import Database from '../../../database/Database';
import type { DatabaseCollectionNames, RxCollections } from '../../../types/database';

export default function getRxCollection<TCollectionName extends DatabaseCollectionNames>(
  database: Database,
  collectionName: TCollectionName,
): RxCollections[TCollectionName] {
  const rxDBInstance = database.getRxDBInstance();
  return rxDBInstance.collections[collectionName];
}
