/* eslint-disable @typescript-eslint/ban-ts-comment, simple-import-sort/imports */

import type { RangyClassApplier } from '../types/rangy';
// @ts-ignore
import rangy from './rangy';

let rangyClassApplier: RangyClassApplier;
export default (): RangyClassApplier => {
  if (rangyClassApplier) {
    return rangyClassApplier;
  }
  // @ts-ignore
  rangy.init();
  // @ts-ignore
  rangyClassApplier = rangy.createClassApplier('it-doesnt-matter', {
    elementTagName: 'it-doesnt-matter',
    normalize: false,
    useExistingElements: false,
  });
  return rangyClassApplier;
};
/* eslint-enable @typescript-eslint/ban-ts-comment */
