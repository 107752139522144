import type { MangoQuery } from 'rxdb';

import {
  type AnyDocument,
  type FirstClassDocument,
  type UserEvent,
  DocumentLocation,
} from '../../../../types';
import nowTimestamp from '../../../../utils/dates/nowTimestamp';
import makeLogger from '../../../../utils/makeLogger';
// eslint-disable-next-line import/no-cycle
import database from '../../../database';
import { recentUndoneUserEvents, updateState } from '../../../models';
import {
  removeFeedIdFromSidebarFromState,
  updateDocumentLocationOfDoc,
  updateEmailOriginalViewStatus,
} from '../../../stateUpdateHelpers';
import { ToastCategory } from '../../../Toaster';
import { createToast } from '../../../toasts.platform';
import { StateUpdateOptionsWithoutEventName } from '../../../types';
import { getCategoryUrlArgument } from '../../../utils/categoryUrlHelpers';
import getUIFriendlyNameForDocumentLocation from '../../../utils/getUIFriendlyNameForDocumentLocation';
import { deleteDocumentsById } from './remove';
import { updateDocuments } from './update';

const logger = makeLogger(__filename);

let bulkCorrelationId: UserEvent['correlationId'] | undefined;
let idsToBulk: string[] = [];

interface DoBulkActionOnDocsProps {
  numPreviouslyUpdated?: number;
  docQuery?: MangoQuery<AnyDocument>;
  applyBulkActionToBatch: (
    docIds: AnyDocument['id'][],
    options: Parameters<typeof updateState>[1],
  ) => Promise<{ userEvent?: UserEvent }>;
  createToastOptions: ({
    updateResult,
    updatedDocsCount,
    totalDocsToUpdateCount,
  }: {
    updateResult: {
      userEvent?: UserEvent | undefined;
    };
    updatedDocsCount: number;
    totalDocsToUpdateCount: number;
  }) => {
    content: string;
    category: ToastCategory;
    link?: string;
    linkTooltipContent?: string;
    undoableUserEventId: string;
  };
  eventsIds?: string[];
  afterFinish?: (eventsIds: string[]) => void;
  options: Parameters<typeof updateState>[1];
}

const BULK_ACTION_BATCH_SIZE = 1000;

export async function doBulkActionOnDocs({
  numPreviouslyUpdated = 0,
  docQuery,
  applyBulkActionToBatch,
  createToastOptions,
  afterFinish,
  eventsIds = [],
  options,
}: DoBulkActionOnDocsProps): Promise<void> {
  if (numPreviouslyUpdated === 0 && idsToBulk.length) {
    createToast({
      content: 'Please, wait for the previous bulk action to finish',
      category: 'warning',
    });
    return;
  }

  let numDocsToUpdate = 0;

  // TODO: omit finding doc IDs entirely by deferring query execution until inside applyBulkActionToBatch().
  //   NOTE: we'd have to batch differently i.e. not by list of IDs, but instead maybe by ID like iterateThroughDocumentsInBatches()
  //   that said, it actually doesn't seem like executing this query takes up a significant portion of the time of a bulk action.
  const docIds = docQuery && (await database.collections.documents.findIds(docQuery));
  const isFirstRun = !idsToBulk.length && docIds;
  if (docIds && docIds.length === 0) {
    logger.warn('No documents found for bulk action, cancelling', {
      docIdsOrQuery: JSON.stringify(docQuery, null, 2),
    });
    return;
  }

  if (isFirstRun) {
    idsToBulk = docIds;
    logger.debug('Total docs length to update:', { count: docIds.length });
  }

  const docIdsBatch = idsToBulk.slice(0, BULK_ACTION_BATCH_SIZE);
  const updateResult = await applyBulkActionToBatch(docIdsBatch, {
    ...options,
    correlationId: bulkCorrelationId,
  });

  numDocsToUpdate = idsToBulk.length;

  logger.debug('Current batch size:', { count: docIdsBatch.length });

  idsToBulk = idsToBulk.slice(BULK_ACTION_BATCH_SIZE);

  const userEventId = updateResult.userEvent?.id;
  const newEventsIds = userEventId ? [...eventsIds, userEventId] : eventsIds;

  if (!bulkCorrelationId) {
    bulkCorrelationId = updateResult.userEvent?.correlationId;
  }

  const updatedDocsCount = BULK_ACTION_BATCH_SIZE + numPreviouslyUpdated;

  const hasFinished = !idsToBulk.length;

  if (hasFinished) {
    bulkCorrelationId = undefined;
  } else {
    setTimeout(async () => {
      // If the user has already undone one bulk action, stop the rest.
      if (recentUndoneUserEvents.find((e) => e.correlationId === bulkCorrelationId)) {
        idsToBulk = [];
        bulkCorrelationId = undefined;
        return;
      }

      doBulkActionOnDocs({
        numPreviouslyUpdated: updatedDocsCount,
        applyBulkActionToBatch,
        createToastOptions,
        afterFinish,
        eventsIds: newEventsIds,
        options,
      });
    }, 1000);
  }

  if (options.userInteraction) {
    const totalDocsToUpdateCount = numDocsToUpdate + updatedDocsCount - BULK_ACTION_BATCH_SIZE;
    const toastOptions = createToastOptions({ updateResult, updatedDocsCount, totalDocsToUpdateCount });
    createToast(toastOptions);

    if (hasFinished && afterFinish) {
      afterFinish(newEventsIds);
    }
  }
}

export const newMoveAllDocsToNewStatus = async ({
  docQuery,
  newStatus,
  options,
}: {
  docQuery: MangoQuery<AnyDocument> | undefined;
  newStatus: DocumentLocation;
  options: StateUpdateOptionsWithoutEventName;
}): Promise<void> => {
  const triageValues = Object.values(DocumentLocation);

  if (!triageValues.includes(newStatus)) {
    return;
  }

  doBulkActionOnDocs({
    docQuery,
    createToastOptions: ({ updateResult, updatedDocsCount, totalDocsToUpdateCount }) => {
      let toastMessage;

      const uiFriendlyDocumentLocation = getUIFriendlyNameForDocumentLocation(newStatus);
      if (idsToBulk.length) {
        toastMessage = `Moving ${updatedDocsCount} / ${totalDocsToUpdateCount} documents to ${uiFriendlyDocumentLocation}...`;
      } else {
        toastMessage = `Moved all to ${uiFriendlyDocumentLocation}`;
      }

      return {
        content: toastMessage,
        category: 'success' as ToastCategory,
        link: `/${[getCategoryUrlArgument(), newStatus].filter(Boolean).join('/')}`,
        linkTooltipContent: `Go to ${uiFriendlyDocumentLocation}`,
        undoableUserEventId: (updateResult?.userEvent as UserEvent).id,
      };
    },
    applyBulkActionToBatch: (docIds, options) =>
      updateDocuments<FirstClassDocument>(
        docIds,
        (doc) => updateDocumentLocationOfDoc(doc, newStatus),
        options,
      ),
    options: {
      ...options,
      eventName: 'move-all-docs-to-new-status',
    },
  });
};

export const markDocumentsAsOpenOrUnopen = async ({
  docQuery,
  markAsOpen,
  afterFinish,
  options,
}: {
  docQuery: MangoQuery<AnyDocument> | undefined;
  markAsOpen: boolean;
  afterFinish?: DoBulkActionOnDocsProps['afterFinish'];
  options: StateUpdateOptionsWithoutEventName;
}): Promise<void> => {
  const openedOrUnopened = markAsOpen ? 'seen' : 'unseen';

  doBulkActionOnDocs({
    docQuery,
    createToastOptions: ({ updateResult, updatedDocsCount, totalDocsToUpdateCount }) => {
      let toastMessage;

      if (idsToBulk.length) {
        toastMessage = `Marking ${updatedDocsCount} / ${totalDocsToUpdateCount} documents as ${openedOrUnopened}...`;
      } else {
        toastMessage = `${totalDocsToUpdateCount} Documents marked as ${openedOrUnopened}`;
      }

      return {
        content: toastMessage,
        category: 'success' as ToastCategory,
        undoableUserEventId: (updateResult?.userEvent as UserEvent).id,
      };
    },
    applyBulkActionToBatch: (docIds, options) => {
      const now = nowTimestamp();

      return updateDocuments(
        docIds,
        (doc) => {
          if (markAsOpen) {
            if (!doc.firstOpenedAt) {
              doc.firstOpenedAt = now;
            }
            doc.lastOpenedAt = now;
          } else {
            if (doc.firstOpenedAt) {
              delete doc.firstOpenedAt;
            }

            if (doc.lastOpenedAt) {
              delete doc.lastOpenedAt;
            }
          }
          doc.lastSeenStatusUpdateAt = now;
        },
        {
          ...options,
          errorMessageIfNothingFound: false,
        },
      );
    },
    afterFinish,
    options: {
      ...options,
      eventName: `mark-all-docs-as-${openedOrUnopened}`,
    },
  });
};

export const deleteAllDocumentsInList = async ({
  docQuery,
  options,
}: {
  docQuery: MangoQuery<AnyDocument> | undefined;
  options: StateUpdateOptionsWithoutEventName;
}): Promise<void> => {
  const count = docQuery ? await database.collections.documents.count(docQuery) : 0;
  createToast({
    category: 'default',
    content: `Deleting ${count} documents in list`,
  });
  doBulkActionOnDocs({
    docQuery,
    createToastOptions: ({ updateResult, updatedDocsCount, totalDocsToUpdateCount }) => {
      let toastMessage;

      if (idsToBulk.length) {
        toastMessage = `Deleting ${updatedDocsCount} / ${totalDocsToUpdateCount} documents...`;
      } else {
        toastMessage = `${totalDocsToUpdateCount} Documents deleted`;
      }

      return {
        content: toastMessage,
        category: 'success' as ToastCategory,
        undoableUserEventId: (updateResult?.userEvent as UserEvent).id,
      };
    },
    applyBulkActionToBatch: (docIds, options) => deleteDocumentsById(docIds, options),
    options: {
      ...options,
      eventName: 'delete-all-documents-in-list',
    },
  });
};

export async function removeFeeds(
  feedIds: string[],
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> {
  const updateResult = await updateState(
    (state) => {
      feedIds.forEach((feedId) => {
        if (state.persistent.rssFeeds) {
          delete state.persistent.rssFeeds[feedId];
        }

        // Make sure we remove feed from sidebar if it's pinned
        removeFeedIdFromSidebarFromState({ feedId, state });
      });
    },
    { ...options, eventName: 'document-feed-removed' },
  );

  const docsToRemoveFromFeedQuery = {
    selector: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'rxdbOnly.indexFields.triage_status_is_feed': false,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'rxdbOnly.indexFields.triage_status_exists': true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'source_specific_data.rss_feed': {
        $in: feedIds,
      },
    },
  };
  const docsToDeleteQuery = {
    selector: {
      $or: [
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { 'rxdbOnly.indexFields.triage_status_is_feed': true },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { 'rxdbOnly.indexFields.triage_status_exists': false },
      ],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'source_specific_data.rss_feed': {
        $in: feedIds,
      },
    },
  };

  // This is a little hacky: we set the global var bulkCorrelationId as it's used by doBulkActionOnDocs to correlated docs for undoing
  const correlationId = updateResult?.userEvent?.correlationId;
  bulkCorrelationId = correlationId;
  await Promise.all([
    deleteAllDocumentsInList({
      docQuery: docsToDeleteQuery,
      options: { userInteraction: 'unknown', correlationId },
    }),
    updateDocuments(
      docsToRemoveFromFeedQuery,
      (doc) => {
        doc.shows_in_feed = false;
      },
      {
        userInteraction: 'unknown',
        correlationId,
        eventName: 'documents-updated-on-feed-removal',
        errorMessageIfNothingFound: false,
      },
    ),
  ]);

  const undoableUserEventId = (updateResult.userEvent as UserEvent).id;
  createToast({
    content: 'RSS removed from feed',
    category: 'success',
    undoableUserEventId,
  });
}

export const setOriginalViewStatusOnAllEmailDocs = async ({
  docQuery,
  originalEmailViewStatus,
  options,
}: {
  docQuery: MangoQuery<AnyDocument> | undefined;
  originalEmailViewStatus: boolean | undefined;
  options: StateUpdateOptionsWithoutEventName;
}): Promise<void> => {
  doBulkActionOnDocs({
    docQuery,
    createToastOptions: ({ updateResult, updatedDocsCount, totalDocsToUpdateCount }) => {
      const toastMessage = `Updated Original View Status on ${totalDocsToUpdateCount} documents`;

      return {
        content: toastMessage,
        category: 'success' as ToastCategory,
        undoableUserEventId: (updateResult?.userEvent as UserEvent).id,
      };
    },
    applyBulkActionToBatch: (docIds, options) =>
      updateDocuments<FirstClassDocument>(
        docIds,
        (doc) => updateEmailOriginalViewStatus(doc, originalEmailViewStatus),
        options,
      ),
    options: {
      ...options,
      eventName: 'update-email-original-view-status',
    },
  });
};
