import { AnyDocument, DocumentWithTransientData, PartialDocument } from '../../types';
import { isDocumentWithSummary } from '../../typeValidators';
import getDocumentOverrideOrReal from '../../utils/getDocumentOverrideOrReal';

/**
 * @return
 *
 * `generatedSummary`: The GPT-generated summary if it exists, else undefined,
 *
 * `extractedSummary`: The summary extracted during parsing if it exists, else undefined.
 *
 * `summary`: The summary to display to the user. Generated summary, extracted summary, or empty string, in that order.
 *
 */
export function getSummaries(
  document:
    | AnyDocument
    | DocumentWithTransientData
    | PartialDocument<AnyDocument, 'summary' | 'category' | 'generated_summary' | 'overrides'>,
): {
  generatedSummary: string | undefined;
  extractedSummary: string | undefined;
  summary: string;
} {
  const extractedSummary =
    (isDocumentWithSummary(document) && getDocumentOverrideOrReal(document, 'summary')) || undefined;
  const generatedSummary =
    (isDocumentWithSummary(document) && getDocumentOverrideOrReal(document, 'generated_summary')) ||
    undefined;

  const summary = generatedSummary || extractedSummary || '';
  return { generatedSummary, extractedSummary, summary };
}
