import omit from 'lodash/omit';

import type { MinimalPersistentState, PersistentState } from '../types';

export default function minimizeState(persistentState: PersistentState | MinimalPersistentState): {
  persistentState: MinimalPersistentState;
} {
  persistentState.currentlyReadingId = null;
  persistentState.rssFeeds = null;
  persistentState.settings = omit(persistentState.settings, 'tts', 'tts_v2');

  return { persistentState: persistentState as MinimalPersistentState };
}
