import { getQueryFromAst } from './getQueryFromAst';
import { parseFromQuery } from './parser';
import {
  AST,
  Expression,
  ExpressionLogical,
  ExpressionNode,
  NodeType,
  PartialExpression,
  PartialExpressionLogical,
} from './types';
import { match, matchWithDefault } from './utils';

const isEqualNode = (a: ExpressionNode, b: ExpressionNode): boolean => {
  return a.key === b.key && a.operator === b.operator && a.value === b.value;
};

const isEqualLogical = (a: ExpressionLogical, b: ExpressionLogical): boolean => {
  if (a.operator !== b.operator) {
    return false;
  }

  const isEqLeft = isEqualExpression(a.left, b.left);

  if (isEqLeft) {
    const isEqualRight = isEqualExpression(a.right, b.right);
    return isEqualRight;
  }

  return false;
};

export const isEqualExpression = (a: Expression, b: Expression): boolean => {
  if (a.type === NodeType.Node && b.type === NodeType.Node) {
    return isEqualNode(a, b);
  }

  if (a.type === NodeType.Logical && b.type === NodeType.Logical) {
    return isEqualLogical(a, b);
  }

  return false;
};

// When removing a child from an AST sometimes we end up with undefined nodes
// (for this example I'll write queries but it actually is the AST of the query)
// If the query is `author:rick OR (category:pdf AND author:removeMe)`
// and we want to remove `author:removeMe`
// we are going to get an AST for this query `author:rick OR (category:pdf AND undefined)`.
// This helper fixes that and makes the AST to be `author:rick OR category:pdf`
const fixPartial = (partial: PartialExpression): AST | undefined => {
  const matcher = {
    field: (field: Expression) => field,
    logical: (op: PartialExpressionLogical) => {
      if (!op.left && op.right) {
        return fixPartial(op.right);
      }

      if (!op.right && op.left) {
        return fixPartial(op.left);
      }

      const left = op.left && fixPartial(op.left);
      const right = op.right && fixPartial(op.right);

      if (left && right) {
        return {
          ...op,
          left,
          right,
        };
      }

      return left ?? right;
    },
  };

  return matchWithDefault(matcher, undefined)(partial);
};

export const filter = (
  filterCheck: (expr: Expression) => boolean,
  expr: Expression,
): PartialExpression => {
  if (!filterCheck(expr)) {
    return undefined;
  }

  return match<PartialExpression>({
    field: (field) => (filterCheck(field) ? field : undefined),
    logical: (logicalNode) => {
      const keepRight = filterCheck(logicalNode.right);
      const keepLeft = filterCheck(logicalNode.left);

      if (!keepLeft && !keepRight) {
        return undefined;
      }

      return {
        ...logicalNode,
        left: filterCheck(logicalNode.left) ? filter(filterCheck, logicalNode.left) : undefined,
        right: filterCheck(logicalNode.right) ? filter(filterCheck, logicalNode.right) : undefined,
      };
    },
  })(expr);
};

export const dropChild = (ast: AST, child: AST): AST | undefined => {
  const newAst = filter((e) => !isEqualExpression(e, child), ast);
  return fixPartial(newAst);
};

export const removeSubQueryFromQuery = ({
  query,
  subQuery,
}: { query: string; subQuery: string }): string => {
  if (query === subQuery) {
    return '';
  }

  const { ast } = parseFromQuery(query);
  const { ast: childAst } = parseFromQuery(subQuery);

  if (!ast || !childAst) {
    throw new Error('An error ocurred');
  }

  const newAst = dropChild(ast, childAst);

  if (newAst) {
    return getQueryFromAst(newAst);
  }

  return '';
};
