import { isRssFolder } from '../../../utils/feed';
// eslint-disable-next-line import/no-cycle
import { CancelStateUpdate, updateState } from '../../models';
import { isNarrowScreenSize } from '../../stateHooks';
import type { StateUpdateOptionsWithoutEventName } from '../../types';
import isNotebookViewUrl from '../../utils/isNotebookViewUrl';
import isReaderViewUrl from '../../utils/isReaderViewUrl';

export const toggleHideSidebars = async (
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        const newValue = !(
          state.leftSidebarHiddenForNarrowScreen || state.rightSidebarHiddenForNarrowScreen
        );
        if (
          state.leftSidebarHiddenForNarrowScreen === newValue &&
          state.rightSidebarHiddenForNarrowScreen === newValue
        ) {
          throw new CancelStateUpdate();
        }
        if (isReaderViewUrl()) {
          state.leftSidebarHiddenForNarrowScreen = newValue;
        } else {
          state.client.navigationSidebar.isWebNavigationSidebarHidden = newValue;
        }
        state.rightSidebarHiddenForNarrowScreen = newValue;
      } else if (isReaderViewUrl() || isNotebookViewUrl()) {
        const newValue = !(
          state.leftSidebarHiddenInReadingView || state.rightSidebarHiddenInReadingView
        );
        if (
          state.leftSidebarHiddenInReadingView === newValue &&
          state.rightSidebarHiddenInReadingView === newValue
        ) {
          throw new CancelStateUpdate();
        }
        state.leftSidebarHiddenInReadingView = newValue;
        state.rightSidebarHiddenInReadingView = newValue;
      } else {
        const newValue = !(
          state.client.navigationSidebar.isWebNavigationSidebarHidden ||
          state.client.rightSidebarHiddenInList
        );
        state.client.navigationSidebar.isWebNavigationSidebarHidden = newValue;
        state.client.rightSidebarHiddenInList = newValue;
      }
    },
    {
      ...options,
      isUndoable: false,
      eventName: 'hide-sidebars-toggled',
    },
  );
};

export const hideSidebars = async (
  hide = true,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        state.leftSidebarHiddenForNarrowScreen = hide;
        state.rightSidebarHiddenForNarrowScreen = hide;
      } else if (isReaderViewUrl() || isNotebookViewUrl()) {
        state.leftSidebarHiddenInReadingView = hide;
        state.rightSidebarHiddenInReadingView = hide;
      } else {
        state.client.rightSidebarHiddenInList = hide;
      }
    },
    { ...options, isUndoable: false, eventName: 'hide-sidebars-toggled' },
  );
};

export const hideDesktopNavigationSidebar = async (
  isWebNavigationSidebarHidden = true,
  options?: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.navigationSidebar.isWebNavigationSidebarHidden = isWebNavigationSidebarHidden;
    },
    {
      ...(options ?? {}),
      userInteraction: options?.userInteraction ?? 'click',
      isUndoable: false,
      eventName: 'hide-desktop-navigation-sidebar-changed',
    },
  );
};

export const toggleDesktopNavigationSidebar = async (
  options?: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.navigationSidebar.isWebNavigationSidebarHidden =
        !state.client.navigationSidebar.isWebNavigationSidebarHidden;
    },
    {
      ...(options ?? {}),
      userInteraction: options?.userInteraction ?? 'click',
      isUndoable: false,
      eventName: 'toggle-desktop-navigation-sidebar-changed',
    },
  );
};

export const setIsLibraryCollapsed = async (
  isLibraryCollapsed: boolean,
  options?: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.navigationSidebar.isLibraryCollapsed = isLibraryCollapsed;
    },
    {
      ...(options ?? {}),
      userInteraction: options?.userInteraction ?? 'click',
      isUndoable: false,
      eventName: 'is-library-collapsed-changed',
    },
  );
};

export const setIsFeedCollapsed = async (
  isFeedCollapsed: boolean,
  options?: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.navigationSidebar.isFeedCollapsed = isFeedCollapsed;
    },
    {
      ...(options ?? {}),
      userInteraction: options?.userInteraction ?? 'click',
      isUndoable: false,
      eventName: 'is-feed-collapsed-changed',
    },
  );
};

export const setIsPinnedViewsCollapsed = async (
  isPinnedViewsCollapsed: boolean,
  options?: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.navigationSidebar.isPinnedViewsCollapsed = isPinnedViewsCollapsed;
    },
    {
      ...(options ?? {}),
      userInteraction: options?.userInteraction ?? 'click',
      isUndoable: false,
      eventName: 'pinned-views-is-collapsed-changed',
    },
  );
};

export const toggleHideRightSidebar = async (
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        state.rightSidebarHiddenForNarrowScreen = !state.rightSidebarHiddenForNarrowScreen;
      } else if (isReaderViewUrl() || isNotebookViewUrl()) {
        state.rightSidebarHiddenInReadingView = !state.rightSidebarHiddenInReadingView;
      } else {
        state.client.rightSidebarHiddenInList = !state.client.rightSidebarHiddenInList;
      }
    },
    { ...options, isUndoable: false, eventName: 'hide-right-sidebar-toggled' },
  );
};

export const hideRightSidebar = async (
  hide = true,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      if (isNarrowScreenSize(state.screenWidth)) {
        state.rightSidebarHiddenForNarrowScreen = hide;
      } else if (isReaderViewUrl() || isNotebookViewUrl()) {
        state.rightSidebarHiddenInReadingView = hide;
      } else {
        state.client.rightSidebarHiddenInList = hide;
      }
    },
    { ...options, isUndoable: false, eventName: 'hide-right-sidebar-toggled' },
  );
};

export const expandOrCollapseAllNavItems = async (
  isCollapsed: boolean,
  options: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.navigationSidebar.isLibraryCollapsed = isCollapsed;
      state.client.navigationSidebar.isFeedCollapsed = isCollapsed;
      state.client.navigationSidebar.isPinnedViewsCollapsed = isCollapsed;
      state.persistent.rssFoldersAndItems.forEach((rssFolder) => {
        if (isRssFolder(rssFolder)) {
          rssFolder.isCollapsedInSidebar = isCollapsed;
        }
      });
    },
    { ...options, isUndoable: false, eventName: 'expand-or-collapsed-all-nav-items' },
  );
};
