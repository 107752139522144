import React from 'react';

export default function Icon({ children, text }: { children: JSX.Element; text: string }): JSX.Element {
  return (
    <>
      {text && <span className="hideAccessibly">{text}</span>}
      {React.cloneElement(children, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'aria-hidden': true,
      })}
    </>
  );
}
