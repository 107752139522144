import React from 'react';

import Icon from './Icon';

export default function AnimatedCheckmarkIcon({ text = 'Done' }: { text?: string } = {}): JSX.Element {
  return (
    <Icon text={text}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" height="28" width="28">
        <style>{`
        @keyframes a0_t { 0% { transform: translate(14px,14px) scale(1,1); } 48.387% { transform: translate(14px,14px) scale(1,1); animation-timing-function: cubic-bezier(0,1.1,1,1); } 100% { transform: translate(14px,14px) scale(1.2,1.2); } }
        @keyframes a0_o { 0% { opacity: 0; } 47.29% { opacity: 0; } 48.387% { opacity: 1; animation-timing-function: cubic-bezier(0,1,1,1); } 100% { opacity: 0; } }
        @keyframes a1_t { 0% { transform: translate(14px,14px) rotate(-270deg); animation-timing-function: cubic-bezier(.3,0,1,.8); } 51.613% { transform: translate(14px,14px) rotate(270deg); } 100% { transform: translate(14px,14px) rotate(270deg); } }
        @keyframes a1_o { 0% { opacity: 0; } 27.935% { opacity: 1; } 100% { opacity: 1; } }
        @keyframes a1_do { 0% { stroke-dashoffset: 50px; animation-timing-function: cubic-bezier(0,0,1,1); } 51.613% { stroke-dashoffset: 10px; } 100% { stroke-dashoffset: 10px; } }
        @keyframes a2_t { 0% { transform: translate(13.9px,14.4px) scale(.7,.7) translate(-5px,0px); } 47.29% { transform: translate(13.9px,14.4px) scale(.7,.7) translate(-5px,0px); animation-timing-function: cubic-bezier(.3,-0.2,.5,2.1); } 61.29% { transform: translate(13.9px,14.4px) scale(1,1) translate(-5px,0px); } 100% { transform: translate(13.9px,14.4px) scale(1,1) translate(-5px,0px); } }
        @keyframes a2_o { 0% { opacity: 0; } 47.29% { opacity: 0; } 61.29% { opacity: 1; } 100% { opacity: 1; } }
        .animatedCheckmarkIconEllipse1 {
          animation: animation: 1.55s linear both a0_t, 1.55s linear both a0_o;
        }
        .animatedCheckmarkIconEllipse2 {
          animation: 1.55s linear both a1_t, 1.55s linear both a1_o, 1.55s linear both a1_do;
        }
        .animatedCheckmarkIconPath {
          animation: 1.55s linear both a2_t, 1.55s linear both a2_o;
        }
      `}</style>
        <ellipse
          className="animatedCheckmarkIconEllipse1"
          rx="11.8"
          ry="11.8"
          fill="#fff"
          opacity="0"
          transform="translate(14,14) scale(1.00138,1.00138)"
        />
        <ellipse
          className="animatedCheckmarkIconEllipse2"
          rx="6"
          ry="6"
          fill="none"
          stroke="#fff"
          strokeWidth="12"
          strokeDasharray="50"
          strokeDashoffset="50"
          opacity="0"
          transform="translate(14,14) rotate(-270)"
        />
        <path
          className="animatedCheckmarkIconPath"
          d="M-0.8,0.3L2.9,3.9C2.9,3.9,10.1,-3.3,10.7,-3.9"
          stroke="#282828"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          opacity="0"
          transform="translate(13.9,14.4) scale(0.7,0.7) translate(-5,0)"
        />
      </svg>
    </Icon>
  );
}
