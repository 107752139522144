export default (url: string): string => {
  try {
    const { host } = new URL(url);
    return host.replace('www.', '');
  } catch (e) {
    if (e instanceof TypeError) {
      return '';
    } else {
      throw e;
    }
  }
};
