/*
  This is a simple wrapper around `fetch`. It has the same interface. It is different in two ways:

  1. If the request errors due to network, the `Error` thrown is a `ReadwiseFetchNetworkConnectivityError`. Sentry
    ignores these.
  2. If the response status code is 400-599, it throws. Many fetch libraries do this.

  These changes are safe, you can use this function instead of fetch anywhere and with any URL. For this reason, do not
  add any Readwise-specific code for auth, headers, etc. Keep it simple and clean.

  There is `requestWithAuth` which uses this plus adds in authentication headers.
*/

// eslint-disable-next-line import/no-cycle
import networkDetector from '../networkDetector.platform';
import createError from './createError';
// eslint-disable-next-line import/no-cycle
import {
  ReadwiseFetchClientError,
  ReadwiseFetchNetworkConnectivityError,
  ReadwiseFetchServerError,
} from './Errors';

// eslint-disable-next-line no-restricted-globals
type Fetch = typeof fetch;
type FetchResponse = Awaited<ReturnType<Fetch>>;

function modifyCaughtError<T>(input: T): T | Error {
  if (!(input instanceof Error)) {
    return input;
  }

  const originalError: Error = input;
  const lowercaseMessage = originalError.message.toLowerCase();

  if (
    (lowercaseMessage.includes('connection was lost') ||
      lowercaseMessage.includes('could not be loaded') ||
      lowercaseMessage.includes('failed to fetch') ||
      lowercaseMessage.includes('load failed') ||
      lowercaseMessage.includes('network') ||
      lowercaseMessage.includes('request failed') ||
      lowercaseMessage.includes('timed out')) &&
    !networkDetector.isOnline
  ) {
    return createError(originalError, ReadwiseFetchNetworkConnectivityError);
  }

  return originalError;
}

function processUnparsedResponse<T extends FetchResponse>(response: T): T {
  if (response.status >= 400) {
    if (response.status < 500) {
      throw new ReadwiseFetchClientError(response);
    }
    if (response.status < 600) {
      throw new ReadwiseFetchServerError(response);
    }
  }

  return response;
}

export default async function request(...args: Parameters<Fetch>): ReturnType<Fetch> {
  let response: FetchResponse | undefined;

  try {
    // eslint-disable-next-line no-restricted-globals
    response = await fetch(...args);
  } catch (error) {
    throw modifyCaughtError(error);
  }

  return processUnparsedResponse(response);
}
