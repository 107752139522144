import { useEffect, useRef } from 'react';

/*
  A ref which is always up to date with the input
*/
export default function useLiveValueRef<T = unknown>(input: T): React.MutableRefObject<T> {
  const ref = useRef<T>(input);
  useEffect(() => {
    ref.current = input;
  }, [input]);
  return ref;
}
