import { setDesktopSessionId } from '../../../shared/foreground/auth.desktop';

export async function handleDesktopAuthCallback() {
  if (location.pathname !== '/--/doneauth') {
    return;
  }
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('sessionid');
  if (!sessionId) {
    throw new Error('done auth but no sessionid');
  }
  await setDesktopSessionId(sessionId);
  if (location.pathname === '/--/doneauth') {
    location.pathname = '/';
  }
}
