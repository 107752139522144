import { check as checkUpdates, Update } from '@tauri-apps/plugin-updater';

// eslint-disable-next-line import/no-cycle
import { globalState, updatePropertyInState } from '../../../shared/foreground/models';
import type { DesktopPendingUpdate } from '../../../shared/types';
import { getDesktopAppInfo } from '../../../shared/utils/appInfo.desktop';
import exceptionHandler from '../../../shared/utils/exceptionHandler.platform';
import makeLogger from '../../../shared/utils/makeLogger';

const logger = makeLogger(__filename);

function handleUpdateError(error: unknown, update: Update | null, context: string) {
  const { desktopPendingUpdate } = globalState.getState();
  exceptionHandler.captureException(error, {
    extra: {
      update,
      desktopPendingUpdate,
      context,
    },
  });
  if (update) {
    setPendingUpdate({ update, status: 'failed' });
  }
}

export async function downloadAndInstallDesktopUpdate(update: Update) {
  logger.debug('Downloading update', { version: update.version });
  setPendingUpdate({ update, status: 'downloading' });
  try {
    await update.downloadAndInstall();
  } catch (error) {
    handleUpdateError(error, update, 'downloading and installing update');
  }
  logger.debug('Downloaded and installed update');
}

function setPendingUpdate(pendingUpdate: DesktopPendingUpdate) {
  updatePropertyInState('desktopPendingUpdate', pendingUpdate, {
    userInteraction: null,
    eventName: 'desktop-app-update-pending-set',
    isUndoable: false,
  });
}

export async function checkForDesktopUpdates() {
  logger.debug('Checking for updates...');
  let update;
  try {
    update = await checkUpdates();
  } catch (error) {
    handleUpdateError(error, null, 'checking update');
    return;
  }

  logger.debug('Queried update from server', { update });
  if (update) {
    const { osType } = await getDesktopAppInfo();
    if (osType === 'macos') {
      // on macOS, downloading and installing the app does not automatically relaunch the app.
      await downloadAndInstallDesktopUpdate(update);
      setPendingUpdate({ update, status: 'readyForInstall' });
    } else {
      // on windows downloading and installing the app forces an app relaunch, so we prompt the user for download instead.
      setPendingUpdate({ update, status: 'readyForDownload' });
    }
  }
}
