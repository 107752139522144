// These are updated on app start, without requiring re-population of the DB.
export const SEARCH_FIELD_WEIGHT = {
  title: 9,

  url: 3,
  author: 3,
  tags: 3,
  notes: 3,
  // a search term might occur many more times in content & summary than in title / author, so we have to
  // adjust the relative weight down quite a bit. see https://www.sqlite.org/fts5.html#the_bm25_function
  content: 0.1,
  summary: 0.05,
};

export const SEARCH_DB_NAME = 'search15';

export const ABSOLUTE_BEST_RANK = Number.MIN_SAFE_INTEGER;
