import type { AnyDocument, PartialDocument } from '../types';
import { isDocumentWithLanguage } from '../typeValidators';
import getDocumentOverrideOrReal from './getDocumentOverrideOrReal';

export default (
  doc?: AnyDocument | PartialDocument<AnyDocument, 'category' | 'language' | 'overrides'>,
): string | undefined => {
  if (!doc) {
    return;
  }
  if (isDocumentWithLanguage(doc)) {
    return getDocumentOverrideOrReal(doc, 'language');
  }
  return 'Unknown';
};
