import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';

/*
  A memo hook that deeply compares equality before updating value
*/
export function useDeepEqualMemo<T = unknown>(input: T): T {
  const [value, setValue] = useState<T>(input);

  useEffect(() => {
    if (isEqual(value, input)) {
      return;
    }
    setValue(input);
  }, [input, value]);
  return value;
}
