import isFunction from 'lodash/isFunction';
import { useCallback, useRef, useState } from 'react';

/*
  useState + ./useLiveValueRef.ts
*/
export default function useStatePlusLiveValueRef<T = unknown>(
  input: T,
): [T, React.Dispatch<React.SetStateAction<T>>, React.MutableRefObject<T>] {
  const [value, setValue] = useState<T>(input);
  const ref = useRef<T>(input);

  const set: typeof setValue = useCallback(
    (arg) => {
      let newValue: T;
      if (isFunction(arg)) {
        newValue = arg(ref.current);
      } else {
        newValue = arg;
      }
      const result = setValue(newValue);
      ref.current = newValue;
      return result;
    },
    [setValue],
  );

  // eslint-disable-next-line @shopify/react-hooks-strict-return
  return [value, set, ref];
}
