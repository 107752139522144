import pick from 'lodash/pick';

import type { DocumentTag, GlobalTag } from '../types/tags';
import { cleanAndValidateTagName } from './cleanAndValidateTagName';
import nowTimestamp from './dates/nowTimestamp';
import makeLogger from './makeLogger';

const logger = makeLogger(__filename);

// NOTE: the counts will just be set to 0
export function convertDocumentTagToGlobalTag(
  documentTag: DocumentTag,
  validationErrorOutcome: 'log' | 'throw' = 'throw',
): GlobalTag {
  const globalTag = makeGlobalTagData(documentTag.name, validationErrorOutcome);
  if (documentTag.created) {
    globalTag.lastAssignedAt = documentTag.created;
  }
  return globalTag;
}

export function convertGlobalTagToDocumentTag(
  globalTag: GlobalTag,
  type: DocumentTag['type'],
): DocumentTag {
  return {
    ...pick(globalTag, ['name']),
    created: globalTag.lastAssignedAt,
    type,
  };
}

export function makeGlobalTagData(
  name: GlobalTag['name'],
  validationErrorOutcome: 'log' | 'throw' = 'throw',
): GlobalTag {
  const { cleanTagName, validationError } = cleanAndValidateTagName(name);
  if (validationError) {
    if (validationErrorOutcome === 'throw') {
      throw validationError;
    }
    logger.warn(validationError.message, { name, validationError });
  }

  const tagNameToUse = cleanTagName ?? name;

  return {
    firstClassDocumentsCount: 0,
    highlightsCount: 0,
    id: tagNameToUse.toLowerCase(),
    lastAssignedAt: nowTimestamp(),
    name: tagNameToUse,
    totalCount: 0,
  };
}
