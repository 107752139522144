import { type TopLevelProperty } from 'rxdb/dist/types/types';

import * as commonDefinitions from './commonDefinitions';

export default function getLastRxDBUpdateInfoSchema() {
  const objectSchema: TopLevelProperty = {
    type: 'object',
    additionalProperties: false,
    properties: {
      id: commonDefinitions.ulid,
    },
    required: ['id'],
  };

  return {
    lastRxDBUpdate: objectSchema,
  };
}
