export type Gradient = {
  angle?: number;
  start: { offset: number; color: string };
  end: { offset: number; color: string };
};
export enum ClassicColor {
  Yellow = 'rgba(234, 195, 0, 0.25)',
  Purple = '#ECDDF8',
  Blue = '#CFE7FD',
  Red = '#FADBDA',
  Green = '#CCF4E6',
}

export enum QuoteshotThemeName {
  Classic = 'Classic',
  Fresh = 'Fresh',
  Modern = 'Modern',
  Unstyled = 'Unstyled',
  Scribble = 'Scribble',
  Gradient = 'Gradient',
}

export enum QuoteshotAspectRatio {
  Square = 1,
  Landscape = 16 / 9,
  Portrait = 1080 / 1350,
}

export enum QuoteshotFont {
  Serif = 'Source Serif VF',
  SansSerif = 'Public Sans VF, helvetica',
}

export enum ClassicThemeVariantId {
  Yellow = 'yellow',
  Purple = 'purple',
  Blue = 'blue',
  Red = 'red',
  Green = 'green',
}

export enum FreshVariantId {
  BlueRed = 'blue-red',
  RedBlue = 'red-blue',
  GreyGreen = 'grey-green',
  GreenGrey = 'green-grey',
  BeigePurple = 'beige-purple',
  PurpleBeige = 'purple-beige',
  TealGreen = 'teal-green',
  GreenTeal = 'green-teal',
}

export enum GradientVariantId {
  Pink = 'pink',
  Blue = 'blue',
  Yellow = 'yellow',
  Peach = 'peach',
  BlueDark = 'blue-dark',
  OrangeDark = 'orange-dark',
  PurpleDark = 'purple-dark',
  RedDark = 'red-dark',
}

export enum ModernVariantId {
  OrangePink = 'modern-orange-pink',
  BlueRed = 'modern-blue-red',
  TealBlue = 'modern-teal-blue',
  OrangePurple = 'modern-orange-purple',
}
export enum UnstyledVariantId {
  White = 'White',
  Black = 'Black',
}

export enum ScribbleVariantId {
  Brush = 'brush',
  Circles = 'circles',
  Arrows = 'arrows',
  Waves = 'waves',
}

export type ThemeVariant = {
  id:
    | ClassicThemeVariantId
    | FreshVariantId
    | UnstyledVariantId
    | ScribbleVariantId
    | GradientVariantId
    | ModernVariantId;
  textColor: Gradient | string;
  textBackgroundColor?: ClassicColor;
  backgroundColor: Gradient | string;
  secondaryTextColor: Gradient | string;
  // if this image URL exists we prioritize the image over the background
  backgroundImageUrl?: string | number;
  previewColor?: string;
  previewImageFilter?: string;
  previewImageUrl?: string;

  // colors for dark mode variants, if a color is set, dark mode will take that value
  darkTextColor?: Gradient | string;
  darkTextBackgroundColor?: string;
  darkBackgroundColor?: Gradient | string;
  darkSecondaryTextColor?: Gradient | string;
  // if this image URL exists we prioritize the image over the background
  darkBackgroundImageUrl?: string;
  darkPreviewColor?: string;
  darkPreviewImageUrl?: string;
  // This allows us to overwrite the default fonts
  fontOverrides?: {
    [font in QuoteshotFont]?: string;
  };
};

export type QuoteshotTheme = {
  id: QuoteshotThemeName;
  variants: ThemeVariant[];
  hasDarkMode?: boolean;
};
