import * as jsonpatch from 'readwise-fast-json-patch';

import type { IdToDocumentMap } from '../types';

export default (patch: jsonpatch.Operation[], documents: IdToDocumentMap | null) => {
  return patch.filter((p) => {
    const splitPath = p.path.split('/');
    if (splitPath.length < 4 || splitPath[1] !== 'documents') {
      // Include anything that's not a document property update
      return true;
    }
    const docId = splitPath[2];
    // Return false (filtering out) documents that aren't in state
    return Boolean(documents?.[docId]);
  });
};
