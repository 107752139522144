import lodashCloneDeep from 'lodash/cloneDeep';

import { isInReactNativeWebView, isMobile } from './environment';

export default function cloneDeep<T>(value: T): T {
  // Hermes (react-native) doesn't support it yet but let's disable it in case they suddenly support it and it's slow
  if (typeof structuredClone === 'function' && (!isMobile || isInReactNativeWebView)) {
    return structuredClone(value);
  }

  if (typeof value === 'object') {
    if (isMobile || isInReactNativeWebView) {
      // On mobile, we use JSON.parse(JSON.stringify(value)) to clone objects
      // because it's faster than lodash's cloneDeep.
      // Faster than ES5 clone - http://jsperf.com/deep-cloning-of-objects/5
      // FYI that JSON.parse(JSON.stringify(value)) doesn't preserve non JSONable objects, which may cause bugs.
      return JSON.parse(JSON.stringify(value));
    }

    return lodashCloneDeep(value);
  }
  return value;
}
