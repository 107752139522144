import get from 'lodash/get';
import type {
  AddOperation,
  Operation,
  RemoveOperation,
  ReplaceOperation,
} from 'readwise-fast-json-patch';

import type { FullZustandState } from '../types';

/*
  This particularly useful for arrays that rely on item order, but be careful that this causes larger patches.
  Background: https://linear.app/readwise/issue/RW-15213/library-locations-setting-gets-set-to-incorrect-value

  Include a trailing slash on each.
*/
const statePathsToAlwaysUpdateAsAWhole = ['/settings/documentLocations/'];

export default function handleStatePathsThatNeedToBeUpdatedAsAWhole({
  lastState,
  nextState,
  operations,
}: {
  lastState: FullZustandState;
  nextState: FullZustandState;
  operations: Operation[];
}) {
  let results = operations;
  for (const pathToUpdateAsAWhole of statePathsToAlwaysUpdateAsAWhole) {
    const firstIndex = results.findIndex((patch) => patch.path.startsWith(pathToUpdateAsAWhole));
    if (firstIndex === -1) {
      continue;
    }

    // Remove all related patches
    results = results.filter((patch) => !patch.path.startsWith(pathToUpdateAsAWhole));

    // Insert one new patch in their place updating the entire value
    const pathPieces = pathToUpdateAsAWhole.split('/').filter(Boolean);
    const pathForGetCalls = ['persistent', ...pathPieces].join('.');
    const nextValue = get(nextState, pathForGetCalls);

    const newPath = `/${pathPieces.join('/')}`;
    let newPatchToInsert:
      | AddOperation<typeof nextValue>
      | RemoveOperation
      | ReplaceOperation<typeof nextValue>;
    if (typeof nextValue === 'undefined') {
      newPatchToInsert = {
        op: 'remove',
        path: newPath,
      } as RemoveOperation;
    } else {
      const lastValue = get(lastState, pathForGetCalls);
      newPatchToInsert = {
        op: typeof lastValue === 'undefined' ? 'add' : 'replace',
        path: newPath,
        value: nextValue,
      } as AddOperation<typeof nextValue> | ReplaceOperation<typeof nextValue>;
    }

    results.splice(firstIndex, 0, newPatchToInsert);
  }
  return results;
}
