// eslint-disable-next-line import/no-cycle
import type Database from '../../database/Database';
import logger from '../../database/internals/logger';

export default async function migrateStateSyncingToNewPersistentStateBlob(database: Database) {
  const resultCount = await database.collections__force.state_syncing_key_value_pairs.count({
    selector: {
      id: {
        $eq: 'persistentStateBlob',
      },
    },
  });
  if (!resultCount) {
    logger.debug('Skipping migration of persistentStateBlob; nothing to migrate');
    return;
  }

  const persistentStateBlob =
    await database.collections__force.state_syncing_key_value_pairs.findOne('persistentStateBlob');
  if (!persistentStateBlob) {
    // For TypeScript
    logger.debug('Skipping migration of persistentStateBlob; nothing to migrate');
    return;
  }
  // TODO: we just delete persistentStateBlob and have it reload from the server to make the ux smoother
  //  is there any problem with this?

  // logger.info('Starting migration from persistentStateBlob to new rxdb structure');
  //
  // const persistentState = JSON.parse(persistentStateBlob.value) as PersistentStateWithDocuments;
  //
  // await database.collections__force.state_syncing_key_value_pairs.incrementalUpsert({
  //   id: 'persistentStateBlob2',
  //   value: stringifySafe(omit(persistentState, ['documents'])),
  // }, {});
  await database.collections__force.state_syncing_key_value_pairs.deleteByIds(
    ['persistentStateBlob'],
    {},
  );

  // Delete any updates that have already been sent to the server, we only want to preserve un-sent ones:
  await database.collections__force.state_syncing_updates.delete(
    {
      selector: {
        sentToServer: 1,
      },
    },
    {},
  );

  logger.info('Finished migration from persistentStateBlob');
}
