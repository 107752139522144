import { isDesktopApp } from '../utils/environment';
import getServerBaseUrl from '../utils/getServerBaseUrl.platform';
import makeLogger from '../utils/makeLogger';
import { deleteDesktopSessionId, getAuthedPathForDesktop } from './auth.desktop';
import clearAllLocalData from './clearAllLocalData';

const logger = makeLogger(__filename);

export default async (shouldRedirect = true) => {
  logger.warn('Logged out. Redirecting to login...');
  await clearAllLocalData(false);

  if (isDesktopApp) {
    await deleteDesktopSessionId();
  }

  if (shouldRedirect) {
    let authPath: string;
    if (isDesktopApp) {
      authPath = await getAuthedPathForDesktop();
    } else {
      authPath = '/to_reader';
    }
    window.location.href = `${getServerBaseUrl()}${authPath}`;
  } else {
    window.location.reload();
  }
};
