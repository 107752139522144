import type { RxDocument } from 'rxdb';
import type { DeepReadonly, RxCollection } from 'rxdb/dist/types/types';

import convertRxDocumentToOurJson from '../../../database/internals/convertRxDocumentToOurJson';
import type { DatabaseCollectionNamesToDocType } from '../../../types/database';
import { notEmpty } from '../../../typeValidators';

export default function convertQueryResultToArray<
  TDocType extends DatabaseCollectionNamesToDocType[keyof DatabaseCollectionNamesToDocType],
>(
  input:
    | RxDocument<TDocType>[]
    | RxDocument<TDocType>
    | Map<string, RxDocument<TDocType>>
    | number
    | null,
  rxCollection: RxCollection<TDocType>,
  shouldSkipRxConversionFromRxDocuments?: boolean,
): DeepReadonly<TDocType>[] | TDocType[] | number[] {
  if (typeof input === 'undefined') {
    return [];
  }

  if (typeof input === 'number') {
    return [input];
  }

  let items: RxDocument<TDocType>[];

  if (input instanceof Map) {
    items = Array.from(input, ([, rxDocument]) => rxDocument);
  } else {
    items = Array.isArray(input) ? input : [input].filter(notEmpty);
  }

  if (shouldSkipRxConversionFromRxDocuments) {
    return items;
  }
  return items.map(
    (rxDocument) => convertRxDocumentToOurJson(rxCollection, rxDocument) as DeepReadonly<TDocType>,
  );
}
