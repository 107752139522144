import { Spacing } from '../css/baseStyles';

export default function getWebviewHorizontalMarginCss(
  horizontalMargin: number,
  isTablet: boolean,
  extraMarginModifier = 0,
): string {
  const tabletMargin = Spacing.baseHorizontalMargin + horizontalMargin + extraMarginModifier;
  const phoneMargin = Math.max(Spacing.baseHorizontalMargin + extraMarginModifier, 0);
  if (isTablet) {
    return `.document-content { padding-left: ${tabletMargin}px; padding-right: ${tabletMargin}px} .header-content { padding-left: ${tabletMargin}px; padding-right: ${tabletMargin}px} `;
  } else {
    return `.document-content { padding-left: ${phoneMargin}px; padding-right: ${phoneMargin}px} .header-content { padding-left: ${phoneMargin}px; padding-right: ${phoneMargin}px} `;
  }
}
