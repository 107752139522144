import { isDeployPreview, isDevOrTest } from './environment';

export default () => {
  if (isDevOrTest) {
    return 'https://local.readwise.io:3001';
  }
  if (isDeployPreview) {
    return window.location.origin;
  }
  return 'https://read.readwise.io';
};
